@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-card-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $ruf-card-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  $ruf-card-title-color: ruf-theming.ruf-map-get($config, title-color, ruf-theming.ruf-color($primary)) !default;
  $ruf-card-subtitle-color: ruf-theming.ruf-map-get($config, subtitle-color, ruf-theming.ruf-color($foreground, secondary-text));
  $ruf-card-toggle-color: ruf-theming.ruf-map-get($config, toggle-color, ruf-theming.ruf-color($foreground, border));
  $ruf-card-canvas-color: ruf-theming.ruf-color($background, card-canvas) !default;
  $ruf-card-border-color: ruf-theming.ruf-color($foreground, border);
  .mat-card {
     box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $ruf-card-box-shadow);
  }
  .ruf-card-panel {

    &:not([class*=mat-elevation-z]) {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    .ruf-card-title {
      color: $ruf-card-title-color;
    }

    .ruf-card-subtitle, .ruf-card-metric-description {
      color: $ruf-card-subtitle-color;
    }

    .ruf-card-expand {
      color: $ruf-card-toggle-color;
    }

    &.mat-card {
      border-color: $ruf-card-border-color;
    }
  }

  .ruf-card-canvas {
    background-color: $ruf-card-canvas-color;
  }
}

@mixin ruf-card-typography($config, $override-config: ()) {
  $ruf-title-config: ruf-theming.ruf-map-get($override-config, title, ());
  .ruf-card-panel {
    font-family:  ruf-theming.ruf-font-family($config);
  }
  .ruf-card-panel.mat-card {
    .ruf-card-title, .ruf-card-metric {
      font-family: ruf-theming.ruf-font-family($config, caption-2);
      font-size: ruf-theming.ruf-map-get($ruf-title-config, font-size, ruf-theming.ruf-font-size($config, title));
      font-weight: ruf-theming.ruf-font-weight($config, caption-2);
    }

    .ruf-card-subtitle, .ruf-card-metric-description {
      font-size: ruf-theming.ruf-font-size($config, caption);
      line-height: ruf-theming.ruf-line-height($config, caption);
    }
  }
}

/* Structural config override*/
@mixin ruf-card-override($config) {
 @if( $config ) {
    $ruf-card-header: ruf-theming.ruf-map-get($config, card-header, ());
    $ruf-card: ruf-theming.ruf-map-get($config, card, ());
    $ruf-card-header-height: ruf-theming.ruf-map-get($ruf-card-header, height, auto);
    $ruf-card-header-min-height: ruf-theming.ruf-map-get($ruf-card-header, min-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));
    $ruf-card-header-left-padding: ruf-theming.ruf-map-get($ruf-card-header, padding-left, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 12px));
    $ruf-card-header-right-padding: ruf-theming.ruf-map-get($ruf-card-header, padding-right, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 12px));
    $ruf-card-border-width: ruf-theming.ruf-map-get($ruf-card, border-width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 0px));
    $ruf-card-border-style: ruf-theming.ruf-map-get($ruf-card, border-style, solid);
    $ruf-card-border-radius: ruf-theming.ruf-map-get($ruf-card, border-radius, ruf-theming.ruf-to-rem(2px));
    .ruf-card.fis-override {
      .ruf-card-panel {
        &.mat-card {
          border-radius: $ruf-card-border-radius;
          border-style: $ruf-card-border-style;
          border-width: $ruf-card-border-width;
        }
      }

      .ruf-card-header {
        height: $ruf-card-header-height;
        min-height: $ruf-card-header-min-height;
      }

      &:not(.ruf-card-rtl) {
        .ruf-card-header {
          padding-left: $ruf-card-header-left-padding;
          padding-right: $ruf-card-header-right-padding;
        }
      }

      &.ruf-card-rtl {
        .ruf-card-header {
          padding-left: $ruf-card-header-right-padding;
          padding-right: $ruf-card-header-left-padding;
        }
      }

      &[class*=ruf-elevation-z] {
        .ruf-card-panel {
          &.mat-card {
            border-style: none;
            border-width: none;
          }
       }
    }
  }
 }
}
