@use '@ruf/theming' as ruf-theming;

@use 'sass:math';

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-checkbox($cls: '[fisStyle]', $config: ()) {

  $ruf-checkbox-size: ruf-theming.ruf-map-get($config, size, ());
  $ruf-checkbox-size-small: ruf-theming.ruf-map-get($ruf-checkbox-size, small, 13px);
  $ruf-checkbox-size-medium: ruf-theming.ruf-map-get($ruf-checkbox-size, medium, 15px);
  $ruf-checkbox-size-default: ruf-theming.ruf-map-get($ruf-checkbox-size, default, 18px);

  $frame-config: ruf-theming.ruf-map-get($config, frame, ());
  $border-style: ruf-theming.ruf-map-get($frame-config, border-style, solid);
  $border-width: ruf-theming.ruf-map-get($frame-config, border-width, ruf-theming.ruf-to-rem(2px));

  .mat-checkbox#{$cls} {
    @include ruf-mat-checkbox-size($ruf-checkbox-size-default);
    &.ruf-checkbox-small {
      @include ruf-mat-checkbox-size($ruf-checkbox-size-small);
    }
    &.ruf-checkbox-medium {
      @include ruf-mat-checkbox-size($ruf-checkbox-size-medium);
    }
    &.ruf-checkbox-default {
      @include ruf-mat-checkbox-size($ruf-checkbox-size-default);
    }
    &.mat-checkbox-disabled {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }
    &.mat-checkbox-disabled:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-style: $border-style;
        border-width: $border-width;
      }
    }
    &:not(.mat-checkbox-disabled):not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-style: $border-style;
        border-width: $border-width;
      }
    }

    &.ruf-checkbox-readonly {
      pointer-events: none;

      .mat-checkbox-inner-container {
        display: none;
      }
    }
  }
}

// Define a mixin that outputs the size and position calculations of hover overlay
@mixin ruf-mat-checkbox-size($size) {
  $ripple-size: ($size + 2px) * 2;
 .mat-checkbox-inner-container {
   height: ruf-theming.ruf-to-rem($size);
   width: ruf-theming.ruf-to-rem($size);
   .mat-checkbox-ripple {
     height: $ripple-size;
     $ripple-position: math.div($ripple-size, 2);
     left: calc(50% - #{$ripple-position});
     top: calc(50% - #{$ripple-position});
     width: $ripple-size;
   }
 }
}
