@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it
// stylelint-disable block-no-empty
// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ruf-mat-bottom-sheet-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $foreground: map.get($theme, foreground);
    .mat-bottom-sheet-container#{$cls}, .cdk-overlay-pane#{$cls} .mat-bottom-sheet-container {
      border-color: ruf-theming.ruf-color($foreground, border);
      box-shadow: none;
    }
  }
@mixin ruf-mat-bottom-sheet-panel-typography($config, $cls: '.fis-style') {
}
