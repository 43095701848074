@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-divider-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $foreground: map.get($theme, foreground);
  $color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, subtle-divider));

  .mat-divider#{$cls} {
    border-top-color: $color;
  }
}
