// Define a mixin that outputs the structural styles for the component.
@use '@ruf/theming' as ruf-theming;
@mixin ruf-mat-sidenav($cls: '[fisStyle]', $config: ()) {
  $sidenav-config: ruf-theming.ruf-map-get($config, mat-sidenav, ());
  $sidenav-border-style: ruf-theming.ruf-map-get($config, border-style, none);
  $sidenav-border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(0px));

  .mat-drawer-container#{$cls} {
    .mat-drawer-side, .mat-drawer-opened {
      border-left: none;
      border-right-style: $sidenav-border-style;
      border-right-width: $sidenav-border-width;
    }
  }

  [dir='rtl'] .mat-drawer-container#{$cls} {
    .mat-drawer-side, .mat-drawer-opened {
      border-left-style: $sidenav-border-style;
      border-left-width: $sidenav-border-width;
      border-right: none;
    }
  }
}
