@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-menubar-bidirectionality($theme, $default-active, $dir1: 'left', $dir2: 'right') {
  $colors: primary, accent, emphasis;

  @each $color in $colors {
    &.ruf-#{$color} .ruf-menubar-item-active {
      &:not(.ruf-active) {
        border-bottom-color: ruf-theming.ruf-color(map.get($theme, $color));
        border-#{$dir2}-color: ruf-theming.ruf-color(map.get($theme, $color));
      }
    }
  }

  .ruf-menubar-item-active {
    &:not(.ruf-active) {
      border-bottom-color: $default-active;
      border-#{$dir2}-color: $default-active;
    }
  }

  // styles for inkbarPosition = 'start'
  &.ruf-menubar-inkbar-start {
    @each $color in $colors {
      &.ruf-#{$color} .ruf-menubar-item-active {
        &:not(.ruf-active) {
          border-bottom-color: transparent;
          border-top-color: ruf-theming.ruf-color(map.get($theme, $color));
          border-#{$dir1}-color: ruf-theming.ruf-color(map.get($theme, $color));
          border-#{$dir2}-color: transparent;
        }
      }
    }

    .ruf-menubar-item-active {
      &:not(.ruf-active) {
        border-bottom-color: transparent;
        border-top-color: $default-active;
        border-#{$dir1}-color: $default-active;
        border-#{$dir2}-color: transparent;
      }
    }
  }
}

@mixin ruf-menubar-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-menubar-highlight-color: ruf-theming.ruf-color($background, hover) !default;
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $light: ruf-theming.ruf-map-get($config, light, ());
  $light-menu-item: ruf-theming.ruf-map-get($light, menu-item, ());
  $light-menu-item-hover: ruf-theming.ruf-map-get($light-menu-item, hover, ());

  $default-item-active-color: ruf-theming.ruf-map-get($menu-item, default-active-inkbar-color, ruf-theming.ruf-color($accent));

   .ruf-menubar {
    &:not(.ruf-menubar-rtl) {
      @include ruf-menubar-bidirectionality($theme, $default-item-active-color, 'left', 'right');
    }

    &.ruf-menubar-rtl {
      @include ruf-menubar-bidirectionality($theme, $default-item-active-color, 'right', 'left');
    }
  }

  .ruf-menubar-item, [rufMenubarItem] {
    color: inherit;
  }

  .ruf-menubar-item:hover, [rufMenubarItem]:hover {
    background-color: $ruf-menubar-highlight-color;
  }

  .ruf-menubar-item:focus, [rufMenubarItem]:focus {
    background-color: $ruf-menubar-highlight-color;
  }

  [light] {
    &.ruf-menubar-item:hover, [rufMenubarItem]:hover,
    &.ruf-menubar-item:focus, [rufMenubarItem]:focus {
      background-color: ruf-theming.ruf-map-get($light-menu-item-hover, background-color, ruf-theming.ruf-color($background, contrast));
    }
  }
}


@mixin ruf-menubar-typography($config) {
  .ruf-menubar-layout {
    font-family: ruf-theming.ruf-font-family($config);
    .ruf-menu-item {
      font: {
        family: ruf-theming.ruf-font-family($config, dynamic-menubar);
        size: ruf-theming.ruf-font-size($config, dynamic-menubar);
        weight: ruf-theming.ruf-font-weight($config, dynamic-menubar);
      }
      line-height: inherit;
      &.ruf-menubar-item-active {
        font-weight: ruf-theming.ruf-font-weight($config, bold);
      }
    }
  }
}


/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-menubar-override($config) {
  @if($config) {

    $row-layout: ruf-theming.ruf-map-get($config, row-layout, ());
    $column-layout: ruf-theming.ruf-map-get($config, column-layout, ());
    $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());

    $row-layout-inkbar-width: ruf-theming.ruf-map-get($row-layout, inkbar-width, ruf-theming.ruf-to-rem(2px));
    $column-layout-inkbar-width: ruf-theming.ruf-map-get($column-layout, inkbar-width, ruf-theming.ruf-to-rem(4px));

    $menu-item-padding-left: ruf-theming.ruf-map-get($menu-item, padding-left, ruf-theming.ruf-to-rem(15px));
    $menu-item-padding-right: ruf-theming.ruf-map-get($menu-item, padding-right, ruf-theming.ruf-to-rem(15px));

    .ruf-menubar.fis-override {

    }

    // when the menubar is displayed horizontally
    .ruf-layout-row.fis-override {
      .ruf-menu-item {
        // make space for top or bottom inkbar (border)
        border-bottom-width: $row-layout-inkbar-width;
        border-top-width: $row-layout-inkbar-width;
      }
    }

    // when the menubar is displayed vertically
    .ruf-menubar-layout.ruf-layout-column.fis-override {
      .ruf-menu-item {
        // make space for left or right inkbar (border)
        border-left-width: $column-layout-inkbar-width;
        border-right-width: $column-layout-inkbar-width;
        padding-left: $menu-item-padding-left;
        padding-right: $menu-item-padding-right;
      }
    }
  }
}
