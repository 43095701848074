@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-paginator-density($cls: '[fisStyle]', $config: (), $density: 0) {
  .mat-paginator#{$cls} {
    .mat-icon-button {
      &.mat-paginator-navigation-first,
      &.mat-paginator-navigation-previous,
      &.mat-paginator-navigation-next,
      &.mat-paginator-navigation-last {
        @if($density == -2) {
          &::before {
            display: flex;
            justify-content: center;
            line-height: ruf-theming.ruf-to-rem(0px);
          }
        }
      }
    }
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-paginator($cls: '[fisStyle]', $config: ()) {
  .mat-paginator#{$cls} {
    .mat-paginator-navigation-first {
      @include _ruf-mat-paginator-first-last-icon($config);
      @include _ruf-mat-paginator-icon-transform(90deg);
    }

    .mat-paginator-navigation-last {
      @include _ruf-mat-paginator-first-last-icon($config);
      @include _ruf-mat-paginator-icon-transform(-90deg);
    }

    .mat-paginator-navigation-previous {
      @include _ruf-mat-paginator-previous-next-icon($config);
      @include _ruf-mat-paginator-icon-transform(90deg);
    }

    .mat-paginator-navigation-next {
      @include _ruf-mat-paginator-previous-next-icon($config);
      @include _ruf-mat-paginator-icon-transform(-90deg);
    }

    .mat-paginator-page-size {
      align-items: center;
      &-select {
        margin: ruf-theming.ruf-to-rem(6px) ruf-theming.ruf-to-rem(4px);
      }

      .mat-paginator-page-size-select.mat-form-field-disabled {
        .mat-form-field-wrapper * {
          cursor: ruf-theming.$ruf-disabled-cursor;
        }
      }

      .mat-paginator-page-size-value {
        position: relative;
        top: ruf-theming.ruf-to-rem(1px);
      }
    }

    .mat-paginator-container {
      flex-wrap: nowrap;

      // Disable styles
      .mat-button-disabled {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }

    [dir='rtl'] & {
      .mat-paginator-navigation-first {
        @include _ruf-mat-paginator-icon-transform(-90deg);
      }

      .mat-paginator-navigation-last {
        @include _ruf-mat-paginator-icon-transform(90deg);
      }

      .mat-paginator-navigation-previous {
        @include _ruf-mat-paginator-icon-transform(-90deg);
      }

      .mat-paginator-navigation-next {
        @include _ruf-mat-paginator-icon-transform(90deg);
      }

      .mat-paginator-range-label {
        direction: ltr;
      }
    }
  }
}

@mixin  _ruf-mat-paginator-icon-transform($value) {
  -moz-transform: rotate($value);
  -ms-transform: rotate($value);
  -webkit-transform: rotate($value);
  transform: rotate($value);
}

@mixin  _ruf-mat-paginator-first-last-icon($config) {
  font-family: ruf-theming.ruf-map-get($config, font-family, 'fisfont');
  font-size: ruf-theming.ruf-map-get($config, font-size, ruf-theming.ruf-to-rem(24px));

  &.mat-focus-indicator::before {
    border: none;
    display: inline;
    position: relative;
  }

  &::before {
    content: '\e9a6';
  }
}

@mixin  _ruf-mat-paginator-previous-next-icon($config) {
  font-family: ruf-theming.ruf-map-get($config, font-family, 'fisfont');
  font-size: ruf-theming.ruf-map-get($config, font-size, ruf-theming.ruf-to-rem(24px));

  &.mat-focus-indicator::before {
    border: none;
    display: inline;
    position: relative;
  }

  &::before {
    content: '\e910';
  }
}
