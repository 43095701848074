@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-icon-density($cls: '[fisStyle]', $config: (), $density: 0) {
  .mat-icon#{$cls} {
    font-size: 24px +ruf-theming.$density-interval * $density;
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-icon($cls: '[fisStyle]', $config: ()) {
  // stylelint-disable block-no-empty
  .mat-icon#{$cls} {
    height: auto;
    width: auto;
  }
}
