@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-optgroup-theme($theme, $cls: '[fisStyle]') {
  $foreground: map.get($theme, foreground);

  mat-optgroup#{$cls} {
    .mat-optgroup-label {
        color: ruf-theming.ruf-color($foreground, secondary-text);
    }

    .mat-optgroup-disabled .mat-optgroup-label {
        color: ruf-theming.ruf-color($foreground, hint-text);
    }
  }
}

@mixin ruf-mat-optgroup-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
}
