@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;


@mixin _ruf-snackbar-inkbar($color-map, $color) {
  $color-value: ruf-theming.ruf-color(map.get($color-map, $color), 500);
  &.ruf-inkbar-top {
    border-top-color: $color-value;
  }
  &.ruf-inkbar-bottom {
    border-bottom-color: $color-value;
  }
}

@mixin _ruf-snackbar-border($color-map, $color) {
  $color-value: ruf-theming.ruf-color(map.get($color-map, $color), 500);
  border-color: $color-value;
}

@function get-background-color($color-map, $color, $hue) {
  @return ruf-theming.ruf-color(map.get($color-map, $color), $hue);
}

@mixin _ruf-snackbar-actions($color) {
   &.mat-button:hover,
   &.mat-button.cdk-keyboard-focused,
   .mat-button[fisStyle]:hover,
   .mat-button[fisStyle].cdk-keyboard-focused {
     background-color: $color;
   }
}

@mixin snackbar-background-configuration($color-map, $color, $is-dark-theme) {
  @if($is-dark-theme) {
    @include _ruf-snackbar-actions(get-background-color($color-map, $color, 800));
    background-color: get-background-color($color-map, $color, 900);
  } @else {
    @include _ruf-snackbar-actions(get-background-color($color-map, $color, 200));
    background-color: get-background-color($color-map, $color, 50);
  }
}

@mixin _ruf-snackbar-background-color($color-map, $colors, $is-dark-theme) {
  @each $color in $colors {
    &.ruf-#{$color} {
      @if(meta.type-of(map.get($color-map, $color)) == 'map') {
        @include _ruf-snackbar-inkbar($color-map, $color);
        @include _ruf-snackbar-border($color-map, $color);
        @include snackbar-background-configuration($color-map, $color, $is-dark-theme);
      }
    }
  }
}

@mixin ruf-mat-snackbar-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);
  $main-colors: primary, accent, warn, emphasis, error, success, info;

    .mat-snack-bar-container#{$cls} {
      // Default configuration if not provided by user
      @include _ruf-snackbar-inkbar($theme, info);
      @include _ruf-snackbar-border($theme, info);
      @include snackbar-background-configuration($theme, info, $is-dark-theme);

      @include  _ruf-snackbar-background-color($theme, $main-colors, $is-dark-theme);
      box-shadow: none;
      color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, darker-text));
      .mat-button, .mat-button[fisStyle] {
        background-color: transparent;
        border: none;
        color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, darker-text));
        min-width: ruf-theming.ruf-to-rem(82px);
      }
    }
  }

@mixin ruf-mat-snackbar-panel-typography($config, $cls: '.fis-style') {
    .mat-snack-bar-container#{$cls} {
      font-family: ruf-theming.ruf-font-family($config, body-1);
      font-size: ruf-theming.ruf-font-size($config, body-2);
      font-weight: ruf-theming.ruf-font-weight($config, bold);
      line-height: ruf-theming.ruf-line-height($config, body-1);
    }
}

