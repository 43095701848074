@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it
// Define a mixin that accepts a theme and outputs the color styles for the component.

@mixin ruf-mat-button-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark: map.get($theme, is-dark);

   $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    emphasis: $emphasis,
    success: $success,
    error: $error,
    info: $info
  );

  // button keyboard focused configurations
   $button-keyboard-focused: ruf-theming.ruf-map-get($config, keyboard-focused, ());
   $button-focused-border-color: ruf-theming.ruf-map-get(
                                  $button-keyboard-focused,
                                  border-color,
                                  ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
                                );
   $button-focused-box-shadow: ruf-theming.ruf-map-get(
     $button-keyboard-focused,
     box-shadow,
     0 0 5px 0 ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
    );

  // button disabled
  $disabled: ruf-theming.ruf-map-get($config, disabled, ());
  $button-disabled-color: ruf-theming.ruf-map-get($disabled, color, null);
  $button-disabled-opacity: ruf-theming.ruf-map-get($disabled, opacity, 0.5);
  $button-disabled-box-shadow: ruf-theming.ruf-map-get($disabled, box-shadow, null);

  // amorphous button configurations
  $amorphous-button: ruf-theming.ruf-map-get($config, amorphous, ());
  $amorphous-active-button: ruf-theming.ruf-map-get($amorphous-button, hover-color, ());
  $amorphous-button-disabled: ruf-theming.ruf-map-get($amorphous-button, disabled, ());
  $amorphous-disbled-color: ruf-theming.ruf-map-get($amorphous-button-disabled, color, $button-disabled-color);
  $amorphous-button-box-shadow: ruf-theming.ruf-map-get(
                              $amorphous-active-button, box-shadow,
                              0 0 5px 0 ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
                            );

  // mat-stroked-button (outline) configurations
  $stroked-button: ruf-theming.ruf-map-get($config, stroked, ());
  $stroked-active-button: ruf-theming.ruf-map-get($stroked-button, hover-color, ());
  $stroked-button-disabled: ruf-theming.ruf-map-get($stroked-button, disabled, ());
  $stroked-button-box-shadow: ruf-theming.ruf-map-get(
                              $stroked-active-button, box-shadow,
                              0 0 5px 0 ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
                            );
   // anchor button
  $anchor-button: ruf-theming.ruf-map-get($config, anchor, ());
  $anchor-active-button: ruf-theming.ruf-map-get($anchor-button, hover-color, ());

  // mat-raised-button (filled) & .mat-flat-button configurations
  $raised-button: ruf-theming.ruf-map-get($config, raised, ());
  $raised-active-button: ruf-theming.ruf-map-get($raised-button, hover-color, ());
  $raised-button-box-shadow: ruf-theming.ruf-map-get(
                              $raised-active-button,
                              box-shadow,
                              0 0 5px 0 ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
                            );
  $raised-button-disabled: ruf-theming.ruf-map-get($raised-button, disabled, ());

  // ghost button
  $ghost-button: ruf-theming.ruf-map-get($config, ghost, ());
  $focused-ghost-button: ruf-theming.ruf-map-get($ghost-button, focus, ());
  $ghost-button-box-shadow: ruf-theming.ruf-map-get(
    $focused-ghost-button,
    box-shadow,
    0 0 5px 0 ruf-theming.ruf-color(ruf-theming.ruf-palette(ruf-theming.$ruf-gold))
  );

  // light configuration
  $light: ruf-theming.ruf-map-get($config, light, ());
  $light-icon: ruf-theming.ruf-map-get($light, icon, ());
  $light-icon-hover: ruf-theming.ruf-map-get($light-icon, hover, ());
  $light-icon-hover-background-color-config: ruf-theming.ruf-map-get($light-icon-hover, background-color-config, ());
  $light-icon-disabled: ruf-theming.ruf-map-get($light-icon, disabled, ());


  // amorphous button
  .mat-button#{$cls} {
    background-color: transparent;
    box-shadow: none;
    color: ruf-theming.ruf-map-get($amorphous-button, primary-text-color, ruf-theming.ruf-color($primary));

    &.cdk-mouse-focused {
      outline: 0;
    }

    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused,
    &:not([disabled]):hover, &:not([disabled]):active {
      background-color: ruf-theming.ruf-map-get($amorphous-active-button, primary-background-color, ruf-theming.ruf-color($primary, 100));
      color: ruf-theming.ruf-map-get($amorphous-button, primary-text-color, ruf-theming.ruf-color($primary));
    }

    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
      @include _ruf-mat-button-focused-state(transparent, $amorphous-button-box-shadow);
    }

    &[disabled] {
      box-shadow: $button-disabled-box-shadow;
      color: $amorphous-disbled-color;
      opacity: $button-disabled-opacity;
    }

    .mat-button-focus-overlay {
      display: none;
    }

    @each $name, $color in $theme-colors {
      &.mat-#{$name} {
        box-shadow: none;
        color: ruf-theming.ruf-map-get($amorphous-button, #{$name}-text-color, ruf-theming.ruf-color($color));

        &[disabled] {
          box-shadow: $button-disabled-box-shadow;
          color: $amorphous-disbled-color;
          opacity: $button-disabled-opacity;
        }

        &:not([disabled]).cdk-keyboard-focused,  &:not([disabled]).cdk-program-focused,
        &:not([disabled]):hover, &:not([disabled]):active {
          background-color: ruf-theming.ruf-map-get($amorphous-active-button, #{$name}-background-color, ruf-theming.ruf-color($color, 100));
          color: ruf-theming.ruf-map-get($amorphous-button, #{$name}-text-color, ruf-theming.ruf-color($color));
        }
      }
    }

    &.mat-secondary {
      background-color: transparent;
      box-shadow: none;
      color: ruf-theming.ruf-color($foreground, text);

      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused,
      &:not([disabled]):hover, &:not([disabled]):active {
        background-color: ruf-theming.ruf-map-get($amorphous-active-button, secondary-background-color, ruf-theming.ruf-color($primary, 100));
        color: ruf-theming.ruf-color($foreground, text);
      }

      &[disabled] {
        box-shadow: $button-disabled-box-shadow;
        color: $amorphous-disbled-color;
        opacity: $button-disabled-opacity;
      }
    }
  }
//mat-button ends

  // Raised button or Solid filled button
  .mat-raised-button#{$cls}, .mat-flat-button#{$cls}, .mat-fab#{$cls}, .mat-mini-fab#{$cls} {
    background-color: ruf-theming.ruf-color($background, raised-button);
    border-color: ruf-theming.ruf-map-get($raised-button, default-border-color, ruf-theming.ruf-color($primary));
    box-shadow: none;
    color: ruf-theming.ruf-map-get($raised-button, default-text-color, ruf-theming.ruf-color($primary));

    &.cdk-mouse-focused {
      outline: 0;
    }

    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
      @include _ruf-mat-button-focused-state($button-focused-border-color, $raised-button-box-shadow);
    }

    &:not([disabled]):hover, &:not([disabled]):active,
    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
      background-color: ruf-theming.ruf-map-get($raised-active-button, default-background-color, ruf-theming.ruf-color($primary));
      border-color: ruf-theming.ruf-map-get($raised-active-button, default-border-color, ruf-theming.ruf-color($primary));
      color: ruf-theming.ruf-map-get($raised-active-button, default-text-color, ruf-theming.ruf-color($primary, default-contrast));
    }

    &:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    &[disabled],
    &[disabled].mat-primary,
    &[disabled].mat-accent,
    &[disabled].mat-warn,
    &[disabled].mat-success,
    &[disabled].mat-error,
    &[disabled].mat-info,
    &[disabled].mat-emphasis,
    &[disabled].mat-secondary {
      background-color: ruf-theming.ruf-map-get($raised-button-disabled, background-color, null);
      border-color: ruf-theming.ruf-map-get($raised-button-disabled, border-color, null);
      box-shadow: $button-disabled-box-shadow;
      color: ruf-theming.ruf-map-get($raised-button-disabled, color, null);
      opacity: $button-disabled-opacity;
    }

    .mat-button-focus-overlay {
      display: none;
    }

    @each $name, $color in $theme-colors {
      @if $name == 'primary' or $name == 'emphasis' or $name == 'warn' or $name == 'success' or $name == 'error' or $name == 'info' {
        &.mat-#{$name} {
          background-color: ruf-theming.ruf-color($color);
          border-color: ruf-theming.ruf-color($color);
          box-shadow: none;
          color: ruf-theming.ruf-map-get($raised-button, #{$name}-text-color, ruf-theming.ruf-color($color, default-contrast));

          &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
            @include _ruf-mat-button-focused-state($button-focused-border-color, $raised-button-box-shadow);
          }

          &:not([disabled]):hover, &:not([disabled]):active,
          &:not([disabled]).cdk-keyboard-focused,  &:not([disabled]).cdk-program-focused {
            background-color: ruf-theming.ruf-map-get($raised-active-button, #{$name}-background-color, ruf-theming.ruf-color($color, 700));
            border-color: ruf-theming.ruf-map-get($raised-active-button, #{$name}-border-color, ruf-theming.ruf-color($color, 700));
            color: ruf-theming.ruf-map-get($raised-active-button, #{$name}-text-color, ruf-theming.ruf-color($color, default-contrast));
          }
        }
      }
    }

    &.mat-accent {
      background-color: ruf-theming.ruf-color($accent);
      border-color: ruf-theming.ruf-color($accent);
      box-shadow: none;
      color: ruf-theming.ruf-map-get($raised-button, accent-text-color, ruf-theming.ruf-color($accent, default-contrast));

      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        @include _ruf-mat-button-focused-state($button-focused-border-color, $raised-button-box-shadow);
      }

      &:not([disabled]):hover, &:not([disabled]):active,
      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        background-color: ruf-theming.ruf-map-get($raised-active-button, accent-background-color, ruf-theming.ruf-color($accent, 300));
        border-color: ruf-theming.ruf-map-get($raised-active-button, accent-border-color, ruf-theming.ruf-color($accent, 300));
        color: ruf-theming.ruf-map-get($raised-active-button, accent-text-color, ruf-theming.ruf-color($accent, default-contrast));
      }
    }

    &.mat-secondary {
      background-color: ruf-theming.ruf-map-get($raised-active-button, secondary-border-color, transparent);
      border-color: ruf-theming.ruf-map-get($raised-active-button, secondary-border-color, ruf-theming.ruf-color($foreground, border));
      box-shadow: none;
      color: ruf-theming.ruf-map-get($raised-button, secondary-text-color, ruf-theming.ruf-color($foreground, text));


      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        @include _ruf-mat-button-focused-state($button-focused-border-color, $raised-button-box-shadow);
      }

      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused,
      &:not([disabled]):hover, &:not([disabled]):active {
        background-color: ruf-theming.ruf-map-get(
                            $raised-active-button,
                            secondary-background-color,
                            ruf-theming.ruf-color($background, selected-button)
                          );
        border-color: ruf-theming.ruf-map-get($raised-active-button, secondary-border-color, ruf-theming.ruf-color($foreground, border));
        color: ruf-theming.ruf-map-get($raised-active-button, secondary-text-color, ruf-theming.ruf-color($foreground, lighter-text));
      }
    }
  }

  a.mat-button#{$cls}, a.mat-raised-button#{$cls}, a.mat-flat-button#{$cls} {
    background-color: transparent;
    border-color: transparent;
    color: ruf-theming.ruf-color($foreground, link);

    &.mat-primary:not([disabled]):hover,
    &.mat-emphasis:not([disabled]):hover,
    &.mat-secondary:not([disabled]):hover,
    &:not([disabled]):hover {
      background-color: ruf-theming.ruf-map-get($anchor-active-button, background-color, transparent);
      border-color: transparent;
      color: ruf-theming.ruf-color($foreground, link);
      text-decoration: ruf-theming.ruf-map-get($anchor-active-button, text-decoration, underline);
    }

    &.mat-primary, &.mat-emphasis, &.mat-secondary, &[disabled] {
      background-color: transparent;
      border-color: transparent;
      color: ruf-theming.ruf-color($foreground, link);

      &[disabled] {
        background-color: transparent;
        border-color: transparent;
        box-shadow: $button-disabled-box-shadow;
        color: ruf-theming.ruf-color($foreground, link, 0.5);
        opacity: $button-disabled-opacity;
      }
    }

    &.cdk-keyboard-focused {
      outline: 0;
    }

    &:not([disabled]).cdk-focused.cdk-focused {
      @include _ruf-mat-button-focused-state($button-focused-border-color, $raised-button-box-shadow);
      background-color: ruf-theming.ruf-map-get($anchor-active-button, background-color, transparent);
      border-color: transparent;
      color: ruf-theming.ruf-color($foreground, link);
      text-decoration: ruf-theming.ruf-map-get($anchor-active-button, text-decoration, underline);
    }
  }

  .mat-icon-button#{$cls},
  #{$cls} .mat-icon-button {

    .mat-button-focus-overlay {
      background-color: transparent;
      opacity: 0;
    }

     &[disabled] {
      color: $button-disabled-color;
      opacity: $button-disabled-opacity;
     }

     &:not([disabled]).cdk-keyboard-focused {
      @include _ruf-mat-button-focused-state($button-focused-border-color, $button-focused-box-shadow);
    }

     @include _ruf-mat-icon-button-theme($theme-colors, $button-disabled-opacity, $button-disabled-color,
      ruf-theming.ruf-map-get($config, icon, ()), $theme);
  }

  [light] {
    &.mat-icon-button#{$cls},
    #{$cls} &.mat-icon-button {
      &:not([disabled]):hover, &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        background-color: ruf-theming.ruf-map-get($light-icon-hover-background-color-config,
                            default-background-color, ruf-theming.ruf-color($background, halo-dark));
      }

      @each $name, $color in $theme-colors {
        &.mat-#{$name} {
          &:not([disabled]):hover, &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
            background-color: ruf-theming.ruf-map-get($light-icon-hover-background-color-config,
                                #{$name}-background-color, ruf-theming.ruf-color($color, 100));
          }
        }

        &[disabled] {
          color: ruf-theming.ruf-map-get($light-icon-disabled, color, ruf-theming.ruf-color($foreground, disabled-button));
        }
      }
    }
  }

  // Stroked button or Outline button
  .mat-stroked-button#{$cls} {
    $default-border-color: ruf-theming.ruf-map-get($stroked-button, default-border-color, ruf-theming.ruf-color($foreground, text));
    $stroked-disbled-border-color: ruf-theming.ruf-map-get($stroked-button-disabled, border-color, $button-disabled-color);
    $stroked-disbled-color: ruf-theming.ruf-map-get($stroked-button-disabled, color, $button-disabled-color);

    background-color: ruf-theming.ruf-map-get($stroked-button, default-background-color, transparent);
    border-color: $default-border-color;
    box-shadow: none;
    color: ruf-theming.ruf-map-get($stroked-button, default-text-color, ruf-theming.ruf-color($foreground, text));

    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused,
    &:not([disabled]):hover, &:not([disabled]):active {
      background-color: ruf-theming.ruf-map-get($stroked-active-button, default-background-color, transparent);
      border-color: ruf-theming.ruf-map-get($stroked-active-button, default-border-color, ruf-theming.ruf-color($foreground, text));
      color: ruf-theming.ruf-map-get($stroked-active-button, default-text-color, ruf-theming.ruf-color($foreground, text));
    }

    &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
      @include _ruf-mat-button-focused-state($default-border-color, $stroked-button-box-shadow);
    }

    &[disabled] {
      border-color: $stroked-disbled-border-color;
      box-shadow: $button-disabled-box-shadow;
      color: $stroked-disbled-color;
      opacity: $button-disabled-opacity;
    }

    @each $name, $color in $theme-colors {
      @if $name == 'primary' or $name == 'emphasis' or $name == 'accent' or $name == 'warn' or $name == 'success'
       or $name == 'error' or $name == 'info' {
        &.mat-#{$name} {
          background-color: transparent;
          border-color: ruf-theming.ruf-color($color);
          color: ruf-theming.ruf-map-get($stroked-button, #{$name}-text-color, ruf-theming.ruf-color($color));
          &[disabled] {
            border-color: $stroked-disbled-border-color;
            box-shadow: $button-disabled-box-shadow;
            color: $stroked-disbled-color;
            opacity: $button-disabled-opacity;
          }
          &:not([disabled]):hover,
          &:not([disabled]):active,
          &:not([disabled]).cdk-keyboard-focused,
          &:not([disabled]).cdk-program-focused {
            background-color: ruf-theming.ruf-color($color);
            border-color: ruf-theming.ruf-color($color);
            color: ruf-theming.ruf-map-get($stroked-active-button, #{$name}-text-color, ruf-theming.ruf-color($foreground, lighter-text));
          }
        }
      }
    }

    &.mat-secondary:not([disabled]):hover,
    &.mat-secondary:not([disabled]).cdk-keyboard-focused,
    &.mat-secondary:not([disabled]).cdk-program-focused {
      background-color: transparent;
    }
  }

  .mat-fab#{$cls}, .mat-mini-fab#{$cls} {
    box-shadow: none;
    @each $name, $color in $theme-colors {
      @if $name == 'primary' or $name == 'accent' or $name == 'warn' {
        &.mat-#{$name} {
          &[disabled] {
            background-color: ruf-theming.ruf-map-get($raised-button-disabled, background-color, null);
            border-color: $button-disabled-color;
            box-shadow: $button-disabled-box-shadow;
            color: $button-disabled-color;
            opacity: $button-disabled-opacity;
          }
        }
      }
    }
  }

  // Amorphous button
  .mat-button:not(.mat-stroked-button):not(.mat-raised-button):not(.mat-flat-button)#{$cls},
  // icon button
  .mat-icon-button.mat-button-base#{$cls} {
    $disabled-color: if($is-dark, ruf-theming.ruf-color($foreground, disabled-button), ruf-theming.ruf-color($foreground, disabled-text));

    &.ruf-ghost-button, &[ruf-ghost-button] {
      background-color: if($is-dark, ruf-theming.ruf-color($background, alternate-row),
                         ruf-theming.ruf-color($background, card-canvas));
      border-color: transparent;
      box-shadow: none;
      color: if($is-dark, ruf-theming.ruf-color($foreground, lighter-text), ruf-theming.ruf-color($foreground, text));

      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        border-style: none;
        box-shadow: $ghost-button-box-shadow;
      }

      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused,
      &:not([disabled]):hover, &:not([disabled]):active {
        background-color: if($is-dark, ruf-theming.ruf-color(ruf-theming.$ruf-fis-black-dark, 200),
                            ruf-theming.ruf-color(ruf-theming.$ruf-fis-black, 100));
        color: if($is-dark, ruf-theming.ruf-color($foreground, lighter-text), ruf-theming.ruf-color($foreground, text));
      }

      &[disabled] {
        background-color: if($is-dark, ruf-theming.ruf-color(ruf-theming.$ruf-fis-black-dark, 200),
          ruf-theming.ruf-color($background, halo));
        box-shadow: $button-disabled-box-shadow;
        color: $disabled-color;
        opacity: ruf-theming.ruf-map-get($disabled, opacity, 1);
        .mat-icon {
          color: $disabled-color;
        }
      }

      @each $name, $color in $theme-colors {
        &.mat-#{$name} {
          background-color: ruf-theming.ruf-color($color, if($is-dark, 800, 100));
          box-shadow: none;

          &:not([disabled]).cdk-keyboard-focused,  &:not([disabled]).cdk-program-focused,
          &:not([disabled]):hover, &:not([disabled]):active {
            background-color: ruf-theming.ruf-color($color, if($is-dark, 700, 200));
          }

          &[disabled] {
            background-color: if($is-dark,ruf-theming.ruf-color(ruf-theming.$ruf-fis-black-dark, 200),
            ruf-theming.ruf-color($background, halo));
            box-shadow: $button-disabled-box-shadow;
            color: $disabled-color;
            opacity: ruf-theming.ruf-map-get($disabled, opacity, 1);
            .mat-icon {
              color: $disabled-color;
            }
          }
        }
      }

      &[dark] {
        @each $name, $color in $theme-colors {
          &.mat-#{$name} {
            background-color: ruf-theming.ruf-color($background, hover);
            color: ruf-theming.ruf-color($color, 500);

            &:not([disabled]).cdk-keyboard-focused,  &:not([disabled]).cdk-program-focused, &:not([disabled]):hover {
              background-color: ruf-theming.ruf-color($color, 900);
              color: ruf-theming.ruf-color($color, 500);
            }

            &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
              border-style: none;
              box-shadow: $ghost-button-box-shadow;
            }

            &[disabled] {
              background-color: ruf-theming.ruf-color($background, halo);
              box-shadow: $button-disabled-box-shadow;
              color: $disabled-color;
              opacity: ruf-theming.ruf-map-get($disabled, opacity, 1);
              .mat-icon {
                color: $disabled-color;
              }
            }
          }
        }
      }
    }
  }

  @include _ruf-split-button_theme('right', 'left', $theme, $config, $cls, $theme-colors);

  [dir='rtl'] {
    @include _ruf-split-button_theme('left', 'right', $theme, $config, $cls, $theme-colors);
  }
}

@mixin _ruf-split-button_theme($dir1: 'right', $dir2: 'left', $theme: (), $config: (), $cls: '[fisStyle]', $theme-colors: ()) {
  $foreground: map.get($theme, foreground);
  $split-config: ruf-theming.ruf-map-get($config, split, ());

  // raised button
  $raised-config: ruf-theming.ruf-map-get($split-config, raised, ());
  $raised-separator: ruf-theming.ruf-map-get($raised-config, separator, ());
  $raised-separator-disabled: ruf-theming.ruf-map-get($raised-separator, disabled, ());
  $raised-separator-color-disabled: ruf-theming.ruf-map-get($raised-separator-disabled, color, ruf-theming.ruf-color($foreground, disabled-button));

  // amorphous button
  $amorphous-config: ruf-theming.ruf-map-get($split-config, amorphous, ());
  $amorphous-separator: ruf-theming.ruf-map-get($amorphous-config, separator, ());
  $amorphous-separator-disabled: ruf-theming.ruf-map-get($amorphous-separator, disabled, ());
  $amorphous-separator-color: ruf-theming.ruf-map-get($amorphous-separator, color, ruf-theming.ruf-color($foreground, disabled-button));
  $amorphous-separator-disabled-color: ruf-theming.ruf-map-get($amorphous-separator-disabled, color,
    ruf-theming.ruf-color($foreground, disabled-button));

  .ruf-split-buttons {
    @each $name, $color in $theme-colors {
      $separator-border-color: ruf-theming.ruf-color($color, 600);
      $separator-border-color-hover: ruf-theming.ruf-color($color, 500);
      .mat-button-base#{$cls} {
        &.mat-#{$name}:not(.mat-button-disabled) {
          &.mat-raised-button#{$cls} {
            &:nth-child(1) {
              border-#{$dir1}-color: $separator-border-color;
              border-#{$dir2}-color: $separator-border-color-hover;
            }
            &:nth-child(2) {
              border-#{$dir2}-color: $separator-border-color;
              border-#{$dir1}-color: $separator-border-color-hover;
            }
            &.cdk-focused.cdk-keyboard-focused {
              &:nth-child(1) {
                border-#{$dir1}-color: transparent;
                border-#{$dir2}-color: $separator-border-color-hover;
              }
              &:nth-child(2) {
                border-#{$dir2}-color: transparent;
                border-#{$dir1}-color: $separator-border-color-hover;
              }
            }
          }
        }
      }

      &:hover {
        .mat-button-base#{$cls} {
          &.mat-#{$name}:not(.mat-button-disabled) {
            &.mat-raised-button#{$cls} {
              &:nth-child(1) {
                border-#{$dir1}-color: $separator-border-color-hover;
              }
              &:nth-child(2) {
                border-#{$dir2}-color: $separator-border-color-hover;
              }
            }
          }
        }
      }
    }

    .mat-button-base#{$cls} {
      // Amorphous button
      &:not(.mat-stroked-button):not(.mat-raised-button) {
        border-color: transparent;
        border-style: solid;

        &:nth-child(1) {
          border-#{$dir1}-color: $amorphous-separator-color;
          border-#{$dir2}-color: transparent;
        }
        &:nth-child(2) {
          border-#{$dir2}-color: $amorphous-separator-color;
          border-#{$dir1}-color: transparent;
        }

        &.mat-button-disabled {
          &:nth-child(1) {
            border-#{$dir1}-color: $amorphous-separator-disabled-color;
          }
          &:nth-child(2) {
            border-#{$dir2}-color: $amorphous-separator-disabled-color;
          }
        }
      }

      // Amorphous button
      &.cdk-focused.cdk-keyboard-focused:not(.mat-button-disabled) {
        &.mat-button:not(.mat-raised-button):not(.mat-stroked-button) {
          &:nth-child(1) {
            border-#{$dir1}-color: transparent;
          }
          &:nth-child(2) {
            border-#{$dir2}-color: transparent;
          }
        }
      }

      &.mat-button-disabled#{$cls} {
        &.mat-raised-button {
          &:nth-child(1) {
            border-#{$dir1}-color: $raised-separator-color-disabled;
            border-#{$dir2}-color: transparent;
          }
          &:nth-child(2) {
            border-#{$dir2}-color: $raised-separator-color-disabled;
            border-#{$dir1}-color: transparent;
          }
        }
      }
    }
  }
}

// stylelint-disable block-no-empty
@mixin ruf-mat-button-typography($config, $cls: '[fisStyle]') {

}


@mixin _ruf-mat-button-focused-state($border-color, $box-shadow) {
  border-color: $border-color;
  box-shadow: $box-shadow;
}

@mixin _ruf-mat-icon-button-theme($theme-colors, $button-disabled-opacity, $button-disabled-color, $mat-icon-config, $theme) {
  $hover-config: ruf-theming.ruf-map-get($mat-icon-config, hover, ());
  $icon-button-disabled: ruf-theming.ruf-map-get($mat-icon-config, disabled, ());
  $icon-disabled-color: ruf-theming.ruf-map-get($icon-button-disabled, color, $button-disabled-color);
  $hover-background-color-config: ruf-theming.ruf-map-get($hover-config, background-color-config, ());
  $hover-overlay: ruf-theming.ruf-map-get($hover-config, overlay, ());
  $background: map.get($theme, background);

  // default color
  &:not([disabled]):hover, &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
    background-color: ruf-theming.ruf-map-get($hover-background-color-config, default-background-color, ruf-theming.ruf-color($background, halo));
  }

  &[disabled] {
    .mat-icon {
      color: $icon-disabled-color;
    }
  }

  @each $name, $color in $theme-colors {
    &:not([disabled]).mat-#{$name}:hover,
    &:not([disabled]).mat-#{$name}:active,
    &:not([disabled]).mat-#{$name}.cdk-keyboard-focused,
    &:not([disabled]).mat-#{$name}.cdk-program-focused {
      background-color: ruf-theming.ruf-map-get($hover-background-color-config, #{$name}-background-color, transparent);
      .mat-button-focus-overlay {
        background-color: ruf-theming.ruf-map-get($hover-overlay, background-color, ruf-theming.ruf-color($color, 500));
        opacity: ruf-theming.ruf-map-get($hover-overlay, opacity, 0.21);
      }
    }
    @if $name == 'primary' or $name == 'accent' or $name == 'warn' or $name == 'success' or $name == 'error' or $name == 'info' {
      &.mat-#{$name} {
        &[disabled] {
          .mat-#{$name}.mat-icon {
            opacity: $button-disabled-opacity;
          }
        }
      }
    }
  }
}
