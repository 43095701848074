@use '@ruf/theming' as ruf-theming;

// Mixin styles for labels that are contained within the tab header.
@mixin ruf-tab-label($config) {
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());

  $height: ruf-theming.ruf-map-get($config, height, ruf-theming.ruf-to-rem(44px));
  $line-height: ruf-theming.ruf-map-get($config, line-height, ruf-theming.ruf-to-rem(44px));
  $min-width: ruf-theming.ruf-map-get($menu-item, min-width, ruf-theming.ruf-to-rem(48px));
  $item-padding-horizontal: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(15px));
  $item-padding-vertical: ruf-theming.ruf-map-get($menu-item, padding-vertical, ruf-theming.ruf-to-rem(0px));

  height: $height;
  line-height: $line-height;
  min-width: $min-width;
  padding: $item-padding-vertical $item-padding-horizontal;
  text-transform: capitalize;
}

@mixin _ruf-mat-tabs-pagination-structure($config, $dir1: 'left', $dir2: 'right') {
  $header-pagination: ruf-theming.ruf-map-get($config, header-pagination, ());

  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled) {
    &.mat-tab-header-pagination-after {
      border-#{$dir1}-width: ruf-theming.ruf-map-get($header-pagination, border-left-width, ruf-theming.ruf-to-rem(0px));
      border-#{$dir1}-style: solid;
    }
    &.mat-tab-header-pagination-before {
      border-#{$dir2}-width: ruf-theming.ruf-map-get($header-pagination, border-left-width, ruf-theming.ruf-to-rem(0px));
      border-#{$dir2}-style: solid;
    }
  }

  .mat-tab-header-pagination-disabled {
    cursor: ruf-theming.$ruf-disabled-cursor;
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-tabs($cls: '[fisStyle]', $config: ()) {

  $ink-bar: ruf-theming.ruf-map-get($config, inkbar, ());
  $ink-bar-height: ruf-theming.ruf-map-get($ink-bar, height, ruf-theming.ruf-to-rem(3px));

  .mat-tab-group#{$cls}, .mat-tab-nav-bar#{$cls} {
   .mat-tab-link {
      @include ruf-tab-label($config);

      &[disabled] {
        cursor: ruf-theming.$ruf-disabled-cursor;
        pointer-events: auto;
      }
    }

    .mat-tab-label {
      @include ruf-tab-label($config);

      &[aria-disabled='true'] {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }

    .mat-ink-bar {
      height: $ink-bar-height;
    }

    .mat-tab-header.mat-tab-header-rtl {
      @include _ruf-mat-tabs-pagination-structure($config, 'right', 'left');
    }
    .mat-tab-header:not(.mat-tab-header-rtl) {
      @include _ruf-mat-tabs-pagination-structure($config);
    }
  }
}
