@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-button-toggle-density($cls: '[fisStyle]', $config: (), $density: 0) {
  $height: ruf-theming.ruf-map-get($config, height, 38px);
  $density-interval: 4px;
  $height: if($density == 0, $height, $height + $density-interval * ($density));
  $line-height: $height - $density-interval;
  $button-config: ruf-theming.ruf-map-get($config, button, ());
  $button-height: ruf-theming.ruf-map-get($button-config, height, 36px);
  $button-height: if($density == 0, $button-height, $button-height + $density-interval * ($density));
  $button-selected-config: ruf-theming.ruf-map-get($button-config, selected, ());
  $button-selected-height: ruf-theming.ruf-map-get($button-selected-config, height, 36px);
  $button-selected-height: if($density == 0, $button-selected-height, $button-selected-height + $density-interval * ($density));

  .mat-button-toggle-group#{$cls} {
    .mat-button-toggle,
    .mat-button-toggle#{$cls} {
      height: $height;
      line-height: $line-height;

      &.mat-button-toggle-checked {
        .mat-button-toggle-button {
          height: $button-selected-height;
        }
      }

      .mat-button-toggle-button {
        height: $button-height;
      }
    }
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-button-toggle($cls: '[fisStyle]', $config: ()) {

  $group-config: ruf-theming.ruf-map-get($config, group, ());
  $padding-horizontal: ruf-theming.ruf-map-get($group-config, padding-horizontal, ruf-theming.ruf-to-rem(0px));
  $padding-vertical: ruf-theming.ruf-map-get($group-config, padding-vertical, ruf-theming.ruf-to-rem(0px));

  .mat-button-toggle-group#{$cls} {
    border-radius: ruf-theming.ruf-map-get($group-config, border-radius, ruf-theming.ruf-to-rem(4px));
    padding: $padding-vertical $padding-horizontal;
    .mat-button-toggle-group-appearance-standard {
      border-style: ruf-theming.ruf-map-get($group-config, border-style, solid);
      border-width: ruf-theming.ruf-map-get($group-config, border-radius, ruf-theming.ruf-to-rem(1px));
    }

    .mat-button-toggle,
    .mat-button-toggle#{$cls} {
      @include _ruf-button-toggle-structure($config);
    }
  }

}

@mixin _ruf-button-toggle-structure($config) {

  $button-config: ruf-theming.ruf-map-get($config, button, ());

  $button-border-radius: ruf-theming.ruf-map-get($button-config, border-radius, ruf-theming.ruf-to-rem(3px));
  $button-padding-horizontal: ruf-theming.ruf-map-get($button-config, padding-horizontal, ruf-theming.ruf-to-rem(15px));
  $button-padding-vertical: ruf-theming.ruf-map-get($button-config, padding-vertical, ruf-theming.ruf-to-rem(8px));
  $button-margin-horizontal: ruf-theming.ruf-map-get($button-config, margin-horizontal, ruf-theming.ruf-to-rem(0px));
  $button-margin-vertical: ruf-theming.ruf-map-get($button-config, margin-vertical, ruf-theming.ruf-to-rem(0px));
  $text-transform: ruf-theming.ruf-map-get($button-config, text-transform, none);
  $button-height: ruf-theming.ruf-map-get($button-config, height, inherit);

  $button-selected-config: ruf-theming.ruf-map-get($button-config, selected, ());
  $button-hover-config: ruf-theming.ruf-map-get($button-config, hover, ());

  border-radius: $button-border-radius;
  border-style: solid;
  border-width: ruf-theming.ruf-map-get($button-config, border-width, ruf-theming.ruf-to-rem(1px));
  box-sizing: border-box;

  margin: $button-margin-vertical $button-margin-horizontal;

  &.mat-button-toggle-checked {
    border-radius: ruf-theming.ruf-map-get($button-selected-config, border-radius, ruf-theming.ruf-to-rem(0px));
    border-style: solid;
    border-width: ruf-theming.ruf-map-get($button-selected-config, border-width, ruf-theming.ruf-to-rem(1px));
    text-align: ruf-theming.ruf-map-get($button-selected-config, text-align, center);

    .mat-button-toggle-button {
      height: ruf-theming.ruf-map-get($button-selected-config, height, auto);
    }
  }

  &:not(.mat-button-toggle-disabled):hover {
    border-radius: ruf-theming.ruf-map-get($button-hover-config, border-radius, ruf-theming.ruf-to-rem(0px));
    border-style: solid;
    border-width: ruf-theming.ruf-map-get($button-selected-config, border-width, ruf-theming.ruf-to-rem(1px));
  }

  &:not(.mat-button-toggle-disabled).cdk-keyboard-focused,
  &:not(.mat-button-toggle-disabled).cdk-program-focused {
    border-radius: ruf-theming.ruf-map-get($button-hover-config, border-radius, ruf-theming.ruf-to-rem(0px));
    border-style: solid;
    border-width: ruf-theming.ruf-map-get($button-selected-config, border-width, ruf-theming.ruf-to-rem(1px));
  }

  // default theme
  .mat-button-toggle-button {
    height: $button-height;

    &[disabled] {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }
  }

  .mat-button-toggle-label-content {
    padding: ruf-theming.ruf-to-rem(0px) $button-padding-horizontal $button-padding-vertical $button-padding-horizontal;
    text-transform: $text-transform;
  }
}
