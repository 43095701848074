@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-slider($cls: '[fisStyle]', $config: ()) {

  $slider-config: ruf-theming.ruf-map-get($config, slider, ());
  $slider-thumb-config: ruf-theming.ruf-map-get($config, thumb, ());
  $slider-disabled-config: ruf-theming.ruf-map-get($config, disabled, ());

  $thumb-height: ruf-theming.ruf-map-get($slider-thumb-config, height, ruf-theming.ruf-to-rem(20px));
  $thumb-width: ruf-theming.ruf-map-get($slider-thumb-config, width, ruf-theming.ruf-to-rem(20px));
  $thumb-bottom: ruf-theming.ruf-map-get($slider-thumb-config, bottom, ruf-theming.ruf-to-rem(-10px));
  $thumb-right: ruf-theming.ruf-map-get($slider-thumb-config, right, ruf-theming.ruf-to-rem(-10px));

  $thumb-disabled-height: ruf-theming.ruf-map-get($slider-disabled-config, height, ruf-theming.ruf-to-rem(20px));
  $thumb-disabled-width: ruf-theming.ruf-map-get($slider-disabled-config, width, ruf-theming.ruf-to-rem(20px));
  $thumb-disabled-bottom: ruf-theming.ruf-map-get($slider-disabled-config, bottom, ruf-theming.ruf-to-rem(-10px));
  $thumb-disabled-right: ruf-theming.ruf-map-get($slider-disabled-config, right, ruf-theming.ruf-to-rem(-10px));

  .mat-slider#{$cls} {
    .mat-slider-thumb {
      bottom: $thumb-bottom;
      height: $thumb-height;
      right: $thumb-right;
      width: $thumb-width;
    }
    &:not(.mat-slider-min-value).mat-slider-disabled {
      .mat-slider-thumb {
        bottom: $thumb-disabled-bottom;
        height: $thumb-disabled-height;
        right: $thumb-disabled-right;
        width: $thumb-disabled-width;
      }
    }
  }
}
