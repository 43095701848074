@use 'sass:map';
@use '@ruf/theming' as ruf-theming;


@mixin ruf-mat-input-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $error: map.get($theme, error);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $input-placeholder: ruf-theming.ruf-map-get($config, placeholder, ());
  $input-placeholder-color: ruf-theming.ruf-map-get($input-placeholder, color, ruf-theming.ruf-color($foreground, disabled-text));
  $ruf-form-field-label-color: ruf-theming.ruf-color($foreground, text);
  $ruf-form-field-underline-color:  ruf-theming.ruf-color($foreground, border);
  $ruf-input-error-color: ruf-theming.ruf-color($error, default);
  $ruf-input-text-color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, darker-text));

  .mat-form-field#{$cls},
  *#{$cls} .mat-form-field {
    &:not(.mat-form-field-invalid):not(.mat-focused) {
      .mat-form-field-label {
        color: $ruf-form-field-label-color;
      }

      .mat-hint,
      .mat-icon,
      &.ruf-form-field-no-hint + .mat-hint {
        color: $ruf-form-field-label-color;
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
         color: $ruf-input-text-color;
      }

      .mat-form-field-underline {
        background-color: $ruf-form-field-underline-color;
      }
    }

    &.mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: ruf-theming.ruf-color($foreground, base, 0.04);
    }
    .mat-input-element, .mat-date-range-input .mat-date-range-input-inner {
      @include ruf-theming.ruf-input-placeholder {
        color: $input-placeholder-color;
      }
    }

    &.mat-form-field-invalid {
      .mat-form-field-label,
      .mat-form-field-label .mat-form-field-required-marker,
      .mat-form-field-underline
      .mat-form-field-outline-thick,
      .mat-icon[matSuffix], .mat-icon[matPrefix],
      .mat-error {
        color: $ruf-input-error-color;
      }

      .mat-form-field-ripple {
        background-color: $ruf-input-error-color;
      }
    }

    &.mat-form-field-disabled {
      opacity: 0.5;
    }

    &[light] {
      .mat-form-field-wrapper .mat-input-element {
        caret-color: inherit;
        color: inherit;
      }
      &:not(.mat-form-field-invalid) {
        &:not(.mat-focused) .mat-form-field-wrapper {
          .mat-form-field-underline {
            background-color: currentColor;
           }
          .mat-form-field-label {
            color: inherit;
           }
        }
        &.mat-focused .mat-form-field-wrapper {
          .mat-form-field-ripple {
            background-color: currentColor;
           }
           .mat-form-field-label {
            color: inherit;
           }
        }
      }
    }
 }

 .mat-form-field.mat-form-field-appearance-legacy#{$cls} {
    &.mat-form-field-invalid {
      .mat-input-element {
          caret-color: $ruf-input-error-color;
      }
    }

    .mat-input-element {
      color: $ruf-input-text-color;
    }
  }
}


// The code below was copied from the original material2 code. Changes are marked with "RUF"
@mixin ruf-mat-input-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  $label-config: ruf-theming.ruf-map-get($override-config, label, ());
  $floating-label-config: ruf-theming.ruf-map-get($label-config, floating, ());

    .mat-form-field#{$cls},
    *#{$cls} .mat-form-field {
      &.mat-form-field-should-float {
        .mat-form-field-label {
          font-size: ruf-theming.ruf-map-get($floating-label-config, font-size, ruf-theming.ruf-font-size($config, caption-2));
          font-weight: ruf-theming.ruf-map-get($floating-label-config, font-weight, ruf-theming.ruf-font-size($config, normal-weight));
        }
      }

       .mat-hint {
        font-size: ruf-theming.ruf-font-size($config, caption);
      }
    }

    .mat-form-field.mat-form-field-appearance-legacy#{$cls},
    *#{$cls} .mat-form-field.mat-form-field-appearance-legacy {
      .mat-input-element {
        font-size: ruf-theming.ruf-font-size($config, input);
      }
  }
}
