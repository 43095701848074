@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-mat-progress-bar-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $progress-bar-color-config: ruf-theming.ruf-map-get($config, color-config, ());
  $progress-bar-fill-color-config: ruf-theming.ruf-map-get($config, fill-color-config, ());

  $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    emphasis: $emphasis,
    success: $success,
    error: $error,
    info: $info
  );

  .mat-progress-bar#{$cls} {
    @each $name, $color in $theme-colors {
      &.mat-#{$name} {
        .mat-progress-bar-fill::after {
          background-color: ruf-theming.ruf-map-get($progress-bar-fill-color-config, #{$name}-color, ruf-theming.ruf-color($color, 500));
        }

        .mat-progress-bar-buffer {
          background-color: ruf-theming.ruf-map-get($progress-bar-color-config, #{$name}-color, ruf-theming.ruf-color($color, 100));
        }

        .mat-progress-bar-background {
          fill: ruf-theming.ruf-map-get($progress-bar-color-config, #{$name}-color, ruf-theming.ruf-color($color, 100));
        }
      }
    }
  }
}

@mixin ruf-mat-progress-bar-typography($config, $cls: '[fisStyle]') {
}
