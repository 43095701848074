@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-mat-icon-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $emphasis: map.get($theme, emphasis);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-icon#{$cls} {
    // intentionally empty
  }

  // adding support for emphasis color theme
  .mat-icon {
    &.mat-emphasis {
      color: ruf-theming.ruf-color($emphasis);
    }
    &.mat-error {
      color: ruf-theming.ruf-color($error);
    }
    &.mat-success {
      color: ruf-theming.ruf-color($success);
    }
    &.mat-info {
      color: ruf-theming.ruf-color($info);
    }
  }
}


@mixin ruf-mat-icon-typography($config, $cls: '[fisStyle]') {

}
