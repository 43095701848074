@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;

@function ruf-logo($color) {
  // Adding extra check for color if $color is a valid color or not.
  // It can be a css variable and in that case it will return white logo.
  @if meta.type-of($color) == color {
    @if (color.lightness($color) > 53) {
      //light backgound, use green logo
      @return url(ruf-theming.$fis-logo-green);
    } @else {
      //dark backgound, use white logo
      @return url(ruf-theming.$fis-logo-white);
    }
  }
  // if $color is not a valid color then return green logo
  // e.g. $color is a css variable like var(--primary)
  @return var(--ruf-banner-logo, url(ruf-theming.$fis-logo-green));
}

@mixin _ruf-banner-brand-title-color($color-map, $colors) {
  @each $color in $colors {
    .ruf-banner {
      .ruf-banner-toolbar.ruf-#{$color} {
        .ruf-banner-brand  .ruf-banner-brand-title {
          color: ruf-theming.ruf-color(map.get($color-map, $color), default-contrast);
        }
      }
    }
  }
}

@mixin ruf-banner-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);

  $main-colors: primary, accent, warn, emphasis;
  $ruf-banner-background: ruf-theming.ruf-map-get($config, background-color, ruf-theming.ruf-color($background, app-bar)) !default;
  $ruf-banner-color: ruf-theming.ruf-color($foreground, text) !default;
  $brand-title-color: ruf-theming.ruf-color($foreground, text);
  $box-shadow: ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(3px) 0px ruf-theming.ruf-color($foreground, shadow);

  .ruf-banner {
    background: $ruf-banner-background;
    border-bottom-color: ruf-theming.ruf-map-get($config, border-bottom-color, null);
    box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $box-shadow);
    color: $ruf-banner-color;
  }

  .ruf-banner-toolbar {
    &.ruf-default {
       background-color: inherit;
       color: inherit;
    }
  }

  .ruf-banner-brand {
    .ruf-toolbar-separator {
        border-left-color: inherit; // takes the foreground color of the parent element
        opacity: 0.3;
     }

     [dir='rtl'] & {
      .ruf-toolbar-separator {
        border-right-color: inherit; // takes the foreground color of the parent element
      }
     }

    .ruf-banner-brand-title {
       color: inherit;
    }
  }

  .ruf-banner {
    .ruf-banner-toolbar {
      .ruf-banner-brand {
        .ruf-toolbar-separator {
          border-left-color: inherit; // takes the foreground color of the parent element
        }

        [dir='rtl'] & {
          .ruf-toolbar-separator {
            border-right-color: inherit; // takes the foreground color of the parent element
          }
        }
      }
    }
  }

  .ruf-banner-brand-logo.fis-logo {
    background-repeat: no-repeat;
  }

  .fis-logo-white .ruf-banner-brand-logo.fis-logo {
    // force white fis logo
    background-image: url(ruf-theming.$fis-logo-white) !important;
  }

  .fis-logo-green .ruf-banner-brand-logo.fis-logo {
    // force green fis logo
    background-image: url(ruf-theming.$fis-logo-green) !important;
  }

  .ruf-banner-toolbar .ruf-banner-brand-logo.fis-logo {
    background-image: ruf-theming.ruf-map-get($config, fis-logo-url, ruf-logo($ruf-banner-background));
  }

  .ruf-banner-toolbar.ruf-primary .ruf-banner-brand-logo.fis-logo {
    background-image: ruf-logo(ruf-theming.ruf-color($primary));
  }

  .ruf-banner-toolbar.ruf-accent .ruf-banner-brand-logo.fis-logo {
    background-image: ruf-logo(ruf-theming.ruf-color($accent));
  }

  .ruf-banner-toolbar.ruf-emphasis .ruf-banner-brand-logo.fis-logo {
    background-image: ruf-logo(ruf-theming.ruf-color($emphasis));
  }

  .ruf-banner-toolbar.ruf-warn .ruf-banner-brand-logo.fis-logo {
    background-image: ruf-logo(ruf-theming.ruf-color($warn));
  }

  @include _ruf-banner-brand-title-color($theme, $main-colors);
}



@mixin ruf-banner-typography($config) {
  .ruf-banner-header {
    font-family: ruf-theming.ruf-font-family($config);
  }
  .ruf-banner-brand-title {
    font-family: ruf-theming.ruf-font-family($config, banner-brand-title);
    font-size: ruf-theming.ruf-font-size($config, banner-brand-title);
    font-weight: ruf-theming.ruf-font-weight($config, banner-brand-title);
    line-height: ruf-theming.ruf-line-height($config, banner-brand-title);
  }

  .ruf-banner-brand.ruf-banner-brand-title-bold {
    .ruf-banner-brand-title {
      font-family: ruf-theming.ruf-font-family($config, banner-brand-title-2);
      font-size: ruf-theming.ruf-font-size($config, banner-brand-title-2);
      font-weight: ruf-theming.ruf-font-weight($config, banner-brand-title-2);
      letter-spacing: ruf-theming.ruf-to-rem(3.52px);
      line-height: ruf-theming.ruf-line-height($config, banner-brand-title-2);
    }
  }
}

@mixin ruf-banner-override($config) {
  @if ($config) {
    $min-height: ruf-theming.ruf-map-get($config, min-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 50px));
    $title-mq: ruf-theming.ruf-map-get($config, title-mq, 'gt-sm');
    $brand-logo-width: ruf-theming.ruf-map-get($config, brand-logo-width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 70px));
    $brand-logo-max-width: ruf-theming.ruf-map-get($config, brand-logo-max-width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 70px));
    $brand-logo-background-size: ruf-theming.ruf-map-get(
                                  $config,
                                  brand-logo-background-size,
                                  ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 70px) ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 30px)
                                );
    $brand-logo-height: ruf-theming.ruf-map-get($config, brand-logo-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 38px));
    $brand-title-height: ruf-theming.ruf-map-get($config, brand-title-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 38px));
    $title-padding-top: ruf-theming.ruf-map-get($config, brand-title-padding-top, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 0px));
    $first-row-padding-horizontal: ruf-theming.ruf-map-get(
                                    $config,
                                    first-row-padding-horizontal,
                                    ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px)
                                  );
    .ruf-banner.fis-override {
      border-bottom-style: ruf-theming.ruf-map-get($config, border-bottom-style, none);
      border-bottom-width: ruf-theming.ruf-map-get($config, border-bottom-width, 0px);

      min-height: $min-height;
      header.ruf-banner-header {
        min-height: $min-height;
      }

      ruf-toolbar-row, ruf-banner-row {
        .ruf-banner-brand {
          .ruf-banner-brand-title {
            height: $brand-title-height;
            padding-top: $title-padding-top;
          }
        }
      }

      .ruf-banner-brand {
         height: $min-height;
        .ruf-banner-brand-logo.fis-logo {
          background-size: $brand-logo-background-size;
          height: $brand-logo-height;
          max-width: $brand-logo-max-width;
          width: $brand-logo-width; // use $ruf-vunit instead of $ruf-hunit because we want the width to adjust width if less vertical space.
        }
      }

      .ruf-banner-toolbar {
        @include ruf-toolbar-bidirectionality($first-row-padding-horizontal, 'left', 'right');
      }

      .ruf-banner-toolbar.ruf-toolbar-rtl {
        @include ruf-toolbar-bidirectionality($first-row-padding-horizontal, 'right', 'left');
      }
    }
  }
}

@mixin ruf-toolbar-bidirectionality($first-row-padding-horizontal, $dir1: 'left', $dir2: 'right') {
  > .ruf-toolbar-rows {
    > .ruf-toolbar-first-row {
      > *:first-child:not(.ruf-square) {
        margin-#{$dir1}: $first-row-padding-horizontal;
     }

     > *:last-child:not(.ruf-square) {
        margin-#{$dir2}: $first-row-padding-horizontal;
     }
    }
    // Explicitly add 0px horizontal margin for all banner rows formed by ruf-banner-row.
    > * .ruf-toolbar-first-row {
      > *:first-child:not(.ruf-square) {
        margin-#{$dir1}: 0px;
     }

     > *:last-child:not(.ruf-square) {
        margin-#{$dir2}: 0px;
     }
    }
  }
}
