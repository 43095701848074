@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin _ruf-footer-color($palette) {
  background: ruf-theming.ruf-color($palette);
  color: ruf-theming.ruf-color($palette, default-contrast);
}

@mixin ruf-footer-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-footer-background: ruf-theming.ruf-color($background, footer) !default;
  $ruf-footer-color: ruf-theming.ruf-color($foreground, text) !default;

  .ruf-footer {
    background: $ruf-footer-background;
    border-top-color: ruf-theming.ruf-color($foreground, border);
    color: $ruf-footer-color;
  }
}


@mixin ruf-footer-typography($config) {
  .ruf-footer {
    font-family: ruf-theming.ruf-font-family($config);
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-footer-override($config) {
  @if( $config ) {
    $height: ruf-theming.ruf-map-get( $config, height, null);

    .ruf-footer.fis-override {
      height: $height;
      min-height: $height;
    }
  }
}

