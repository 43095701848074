@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-segment-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $segment-clip: ruf-theming.ruf-map-get($config, segment-clip, ());

  $segment-clip-selected: ruf-theming.ruf-map-get($segment-clip, selected, ());
  $segment-clip-hover: ruf-theming.ruf-map-get($segment-clip, hover, ());
  $segment-clip-focus: ruf-theming.ruf-map-get($segment-clip, focus, ());
  .ruf-dynamic-segment {
    .mat-button-toggle-selected {
      &.ruf-segment-clip {
        &::after {
          background-color: ruf-theming.ruf-map-get($segment-clip-selected, background-color, ruf-theming.ruf-color($primary));
          border-bottom-color: ruf-theming.ruf-map-get($segment-clip-selected, border-color, transparent);
          border-right-color: ruf-theming.ruf-map-get($segment-clip-selected, border-color, transparent);
        }
        &:not(.mat-button-toggle-disabled):hover {
          &::after {
             background-color: ruf-theming.ruf-map-get($segment-clip-hover, background-color, ruf-theming.ruf-color($primary));
          }
        }

        &:not(.mat-button-toggle-disabled).cdk-keyboard-focused {
          &::after {
            background: ruf-theming.ruf-map-get($segment-clip-focus, background-color, ruf-theming.ruf-color($primary));
            border-bottom-color: ruf-theming.ruf-map-get($segment-clip-focus, border-color, ruf-theming.ruf-color($accent));
            border-right-color: ruf-theming.ruf-map-get($segment-clip-focus, border-color, ruf-theming.ruf-color($accent));
            box-shadow: none;
          }
        }
      }
    }
  }
}
@mixin ruf-segment-typography($config) {
  .ruf-dynamic-segment {
    font-family: ruf-theming.ruf-font-family($config);
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-segment-override($config) {
  @if ($config) {
    $overflow-button-config: ruf-theming.ruf-map-get($config, overflow-button, ());
    .ruf-dynamic-segment.fis-override {
      .ruf-segment-layout {
        .mat-button-toggle-group {
          .mat-icon-button.ruf-segment-overflow-button {
            border-radius: ruf-theming.ruf-map-get($overflow-button-config, border-radius, null);
          }
        }
      }
    }
  }
}

@mixin ruf-dynamic-segment-density($config: (), $density: 0) {
  // calculations for overflow button
  $overflow-button-config: ruf-theming.ruf-map-get($config, overflow-button, ());
  $height: ruf-theming.ruf-map-get($overflow-button-config, height, 38px);
  $density-interval: 4px;
  $height: if($density == 0, $height, $height + $density-interval * ($density));

  // calculations for segment arrow
  $segment-clip-arrow-config: ruf-theming.ruf-map-get($config, segment-clip-arrow, ());
  $density-interval: 2px;
  $left: ruf-theming.ruf-map-get($segment-clip-arrow-config, left, 6px);
  $left: if($density == 0, $left, $left + $density-interval * ($density));
  $padding: ruf-theming.ruf-map-get($segment-clip-arrow-config, padding, 7px);
  $padding: if($density == 0, $padding, $padding + $density-interval * ($density));
  $top: ruf-theming.ruf-map-get($segment-clip-arrow-config, top, 7px);
  $top: if($density == 0, $top, $top + $density-interval * ($density));
  .ruf-dynamic-segment {
    .ruf-segment-layout {
      .mat-button-toggle-group {
        .mat-icon-button.ruf-segment-overflow-button {
          height: $height;
          width: $height;
        }

        > .ruf-segment-clip.mat-button-toggle-selected {
          &::after {
            left: calc(50% - $left);
            padding: $padding;
            top: calc(100% - $top);
          }
        }
      }
    }
  }
}
