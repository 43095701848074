@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-dialog-panel-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  // Gold specific
  $panel-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  $dialog-header: ruf-theming.ruf-map-get($config, header, ());

  $ruf-dialog-panel-background-color: ruf-theming.ruf-color($background, dialog) !default;
  $ruf-dialog-title-color: ruf-theming.ruf-map-get($dialog-header, title-color, ruf-theming.ruf-color($primary)) !default;
  $ruf-dialog-close-color: ruf-theming.ruf-map-get($dialog-header, close-button-color, ruf-theming.ruf-color($foreground, icon)) !default;

  .cdk-overlay-pane mat-dialog-container.mat-dialog-container {
    box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $panel-box-shadow);
  }

  .ruf-dialog-container {
    background: $ruf-dialog-panel-background-color;
  }

  .ruf-dialog-header {
      color: $ruf-dialog-title-color;
    .mat-icon-button {
      color: $ruf-dialog-close-color;
    }
  }

  .ruf-dialog-separator {
    border-color: ruf-theming.ruf-color($foreground, subtle-divider);
  }
  .ruf-dialog-panel {
    &:focus {
      outline: none;
    }
  }
}


@mixin ruf-dialog-panel-typography($config, $override-config: ()) {
  $ruf-dialog-container: ruf-theming.ruf-map-get($override-config, ruf-dialog-container, ());
  .ruf-dialog-container {
    font-family:  ruf-theming.ruf-font-family($config);

    .ruf-dialog-header.mat-dialog-title {
        @include ruf-theming.ruf-typography-level-to-styles($config, dialog-title);
        .ruf-dialog-header-bar {
          .mat-icon-button .mat-icon {
            line-height: ruf-theming.ruf-line-height($config, dialog-title);
          }
        }
    }
  }


  mat-dialog-container.mat-dialog-container .ruf-dialog-content {
    font-family: ruf-theming.ruf-font-family($config, subheading-1);
    font-size: ruf-theming.ruf-font-size($config, subheading-1);
    line-height: ruf-theming.ruf-map-get($ruf-dialog-container, line-height, inherit);
  }
}


/* Structural config override*/
@mixin ruf-dialog-panel-override($config) {
  @if ($config) {

    $header: ruf-theming.ruf-map-get($config, header, ());
    $container: ruf-theming.ruf-map-get($config, container, ());
    $footer: ruf-theming.ruf-map-get($config, footer, ());
    $separator: ruf-theming.ruf-map-get($config, separator, ());

    $panel-border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
    $header-margin-bottom: ruf-theming.ruf-map-get($header, margin-bottom, ruf-theming.ruf-to-rem(0px));

    $separator-margin-top: ruf-theming.ruf-map-get($separator, margin-top, ruf-theming.ruf-to-rem(22px));
    $separator-margin-left: ruf-theming.ruf-map-get($separator, margin-left, ruf-theming.ruf-to-rem(-20px));
    $separator-margin-bottom: ruf-theming.ruf-map-get($separator, margin-bottom, ruf-theming.ruf-to-rem(0px));
    $separator-margin-right: ruf-theming.ruf-map-get($separator, margin-right, ruf-theming.ruf-to-rem(-20px));
    $separator-border-bottom-width: ruf-theming.ruf-map-get($separator, border-bottom-width, ruf-theming.ruf-to-rem(1px));

    $container-padding-top: ruf-theming.ruf-map-get($container, padding-top, ruf-theming.ruf-to-rem(8px));
    $container-padding-left: ruf-theming.ruf-map-get($container, padding-left, ruf-theming.ruf-to-rem(0px));
    $container-padding-right: ruf-theming.ruf-map-get($container, padding-right, ruf-theming.ruf-to-rem(0px));
    $container-padding-bottom: ruf-theming.ruf-map-get($container, padding-bottom, ruf-theming.ruf-to-rem(0px));
    $container-max-height: ruf-theming.ruf-map-get($container, max-height, 65vh);

    $footer-padding-top: ruf-theming.ruf-map-get($footer, padding-top, ruf-theming.ruf-to-rem(8px));
    $footer-padding-left: ruf-theming.ruf-map-get($footer, padding-left, ruf-theming.ruf-to-rem(0px));
    $footer-padding-right: ruf-theming.ruf-map-get($footer, padding-right, ruf-theming.ruf-to-rem(0px));
    $footer-padding-bottom: ruf-theming.ruf-map-get($footer, padding-bottom, ruf-theming.ruf-to-rem(0px));
    $footer-margin-bottom: ruf-theming.ruf-map-get($footer, margin-bottom, ruf-theming.ruf-to-rem(10px));



    .cdk-overlay-pane mat-dialog-container.mat-dialog-container {
      border-radius: $panel-border-radius;
      .ruf-dialog-panel.fis-override {
        .ruf-dialog-container {
          padding: $container-padding-top $container-padding-right $container-padding-bottom $container-padding-left;
          .ruf-dialog-footer {
            margin-bottom: $footer-margin-bottom;
            padding: $footer-padding-top $footer-padding-right $footer-padding-bottom $footer-padding-left;
           ruf-dialog-footer {
             padding-top: $footer-padding-top;
           }
        }

          .ruf-dialog-content {
            margin: ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(-20px);
            max-height: $container-max-height;
            padding: ruf-theming.ruf-to-rem(ruf-theming.$ruf-hunit * 0px) ruf-theming.ruf-to-rem(ruf-theming.$ruf-hunit * 20px);
          }

          .ruf-dialog-header.mat-dialog-title {
             margin-bottom: $header-margin-bottom;
          }

          &.ruf-dialog-separator-enabled {
            .ruf-dialog-header.mat-dialog-title {
              margin-bottom: $separator-margin-bottom;
           }

          }

          .ruf-dialog-separator {
            border-bottom-width: $separator-border-bottom-width;
            margin: $separator-margin-top $separator-margin-right $separator-margin-bottom $separator-margin-left;
          }
        }
      }
    }
  }
}


