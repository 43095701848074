@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-pseudo-checkbox($cls: '[fisStyle]', $config: ()) {
  $frame-config: ruf-theming.ruf-map-get($config, frame, ());
  $halo-offset: ruf-theming.ruf-to-rem(20px);
  $border-style: ruf-theming.ruf-map-get($frame-config, border-style, solid);
  $border-width: ruf-theming.ruf-map-get($frame-config, border-width, ruf-theming.ruf-to-rem(2px));

  .mat-pseudo-checkbox#{$cls} {
    &:not(.mat-pseudo-checkbox-checked) {
      border-style: $border-style;
      border-width: $border-width;
    }
  }

  mat-pseudo-checkbox.mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
    border-style: $border-style;
    border-width: $border-width;
  }

  .mat-pseudo-checkbox#{$cls} {
    &.mat-pseudo-checkbox-disabled:not(.mat-pseudo-checkbox-checked) {
      .mat-pseudo-checkbox-frame {
        border-style: $border-style;
        border-width: $border-width;
      }
    }
    &:not(.mat-pseudo-checkbox-disabled):not(.mat-pseudo-checkbox-checked) {
      .mat-pseudo-checkbox-frame {
        border-style: $border-style;
        border-width: $border-width;
      }
    }
  }

  .mat-selection-list.ruf-row-halo#{$cls} {
    .mat-list-option {
      background: none;
      &:hover, &:focus {
        .mat-pseudo-checkbox {
          transform-style: preserve-3d;
          &::before {
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: ruf-theming.ruf-to-rem(40px);
            left: calc(50% - $halo-offset);
            opacity: 1;
            position: absolute;
            top: calc(50% - $halo-offset);
            transform: translateZ(ruf-theming.ruf-to-rem(-1px));
            width: ruf-theming.ruf-to-rem(40px);
          }
        }
      }
    }
  }
}
