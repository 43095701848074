@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-radio($cls: '[fisStyle]', $config: ()) {

  $radio-size: ruf-theming.ruf-map-get($config, radio-size, ruf-theming.ruf-to-rem(20px)) !default;
  $radio-ripple-radius: ruf-theming.ruf-map-get($config, radio-ripple-radius, ruf-theming.ruf-to-rem(20px));
  $label-padding: ruf-theming.ruf-map-get($config, label-padding, ruf-theming.ruf-to-rem(12px));

  $border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(2px));
  $transform: ruf-theming.ruf-map-get($config, transform, scale(0.5));

  .mat-radio-button#{$cls} {
    .mat-radio-label {
      white-space: normal;
    }

    // Container for radio circles and ripple.
    .mat-radio-container {
      height: $radio-size;
      width: $radio-size;
    }

    // The outer circle for the radio, always present.
    .mat-radio-outer-circle {
      border-width: $border-width;
      height: $radio-size;
      width: $radio-size;
    }

    // The inner circle for the radio, shown when checked.
    .mat-radio-inner-circle {
      height: $radio-size;
      width: $radio-size;

    }

    &.mat-radio-checked {
      .mat-radio-inner-circle {
        transform: $transform;
      }
    }

    // Text label next to radio.
    .mat-radio-label-content {
      padding-left: $label-padding;
      [dir='rtl'] & {
        padding-right: $label-padding;
      }
    }

    // Alignment.
    .mat-radio-label-content.mat-radio-label-before {
      padding-right: $label-padding;

      [dir='rtl'] & {
        padding-left: $label-padding;
      }
    }
    .mat-radio-button .mat-radio-ripple {
      height: calc(#{$radio-ripple-radius} * 2);
      left: calc(50% - #{$radio-ripple-radius});
      top: calc(50% - #{$radio-ripple-radius});
      width: calc(#{$radio-ripple-radius} * 2);
    }

    // Disable style
    &.mat-radio-disabled {
      .mat-radio-label {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }
  }

  .mat-radio-group.ruf-radio-readonly#{$cls} {
    pointer-events: none;

    .mat-radio-button:not(.mat-radio-checked) {
      display: none;
    }

    .mat-radio-button.mat-radio-checked {
      .mat-radio-container {
        display: none;
      }

      .mat-radio-label-content {
        padding: 0;
      }
    }
  }
}
