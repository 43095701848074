@use 'sass:map';
@use '@ruf/theming' as ruf-theming;


@mixin ruf-mat-progress-spinner-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-progress-spinner#{$cls} {

    &.mat-primary {
      stroke: ruf-theming.ruf-color($primary);
    }
    &.mat-accent {
      stroke: ruf-theming.ruf-color($accent);
    }
    &.mat-warn {
      stroke: ruf-theming.ruf-color($warn);
    }
    &.mat-emphasis {
      stroke: ruf-theming.ruf-color($emphasis);
    }
  }
}

@mixin ruf-mat-progress-spinner-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
}
