@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-menu($cls: '[fisStyle]', $config: ()) {
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $menu-item-height: ruf-theming.ruf-map-get($menu-item, height, ruf-theming.ruf-to-rem(44px));
  .mat-menu-item#{$cls} {
    height: $menu-item-height;
    line-height: $menu-item-height;
    padding-left: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(16px));
    padding-right: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(16px));
    .mat-ripple {
      display: none;
    }
    &[disabled] {
      cursor: ruf-theming.$ruf-disabled-cursor;
      pointer-events: auto;
    }
  }
}


@mixin ruf-mat-menu-panel($cls: '.fis-style', $config: ()) {
  $menu-panel: ruf-theming.ruf-map-get($config, menu-panel, ());
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $menu-item-icon: ruf-theming.ruf-map-get($menu-item, icon, ());
  $menu-item-height: ruf-theming.ruf-map-get($menu-item, height, ruf-theming.ruf-to-rem(44px));
  // Gold specific
  $menu-panel-border-width: 0 ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(1px);
  .mat-menu-panel#{$cls} {
    border-radius: ruf-theming.ruf-map-get($menu-panel, border-radius, ruf-theming.ruf-to-rem(4px));
    border-style: ruf-theming.ruf-map-get($menu-panel, border-style, solid);
    border-width: ruf-theming.ruf-map-get($menu-panel, border-width, $menu-panel-border-width);
    min-height: ruf-theming.ruf-map-get($menu-panel, min-height, ruf-theming.ruf-to-rem(32px));
    .mat-menu-content {
      padding-bottom: ruf-theming.ruf-map-get($menu-panel, padding-vertical, ruf-theming.ruf-to-rem(8px));
      padding-top: ruf-theming.ruf-map-get($menu-panel, padding-vertical, ruf-theming.ruf-to-rem(8px));

      .mat-menu-item {
        height: $menu-item-height;
        line-height: $menu-item-height;
        padding-left: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(16px));
        padding-right: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(16px));
        text-transform: capitalize;
        .mat-icon {
          margin-right: ruf-theming.ruf-map-get($menu-item-icon, padding-right, ruf-theming.ruf-to-rem(16px));
        }
      }
    }
  }
}
