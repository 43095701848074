@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin _ruf-date-range($border-radius) {
  .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
  .mat-calendar-body-range-start::after,
  .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
  .mat-calendar-body-comparison-start::after,
  .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    // Since the range background isn't a perfect circle, we need to size
    // and offset the start so that it aligns with the main circle.
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;

    [dir='rtl'] & {
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
  .mat-calendar-body-range-end::after,
  .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
  .mat-calendar-body-comparison-end::after,
  .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;

    [dir='rtl'] & {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
  }
}

@mixin _ruf-mat-button-icon-style($margin-left: 0px, $config: ()) {
  height: ruf-theming.ruf-to-rem(32px);
  left: ruf-theming.ruf-to-rem($margin-left);
  position: relative;
  width: ruf-theming.ruf-to-rem(32px);

  &::after {
    position: relative;
    top: ruf-theming.ruf-to-rem(0px);
    z-index: 1;
  }

  [dir='rtl'] & {
    left: auto;
    right: ruf-theming.ruf-to-rem($margin-left);
  }
}

@mixin ruf-mat-datepicker-density($cls: '[fisStyle]', $config: (), $density: 0) {

  .mat-form-field-appearance-outline.mat-form-field#{$cls} .mat-datepicker-toggle#{$cls},
  .mat-datepicker-toggle#{$cls} {
    .mat-icon-button {
      font-size: 18px + 2px * $density !important;
    }
  }

}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-datepicker($cls: '[fisStyle]', $config: ()) {
  $datepicker-icon-button-font-family: 'fisfont';

  .mat-datepicker-toggle#{$cls} {
    .mat-icon-button.mat-button-disabled {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }
  }
  .mat-form-field#{$cls} {
    .mat-datepicker-toggle .mat-icon-button {
      @include _ruf-mat-button-icon-style(7px, $config);

      &::after {
        font-family: $datepicker-icon-button-font-family;
      }
    }

    &.mat-form-field-disabled .mat-button-disabled {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }
  }

  .mat-form-field-appearance-outline.mat-form-field#{$cls} .mat-datepicker-toggle#{$cls},
  .mat-datepicker-toggle#{$cls} {
    .mat-icon-button {
      @include _ruf-mat-button-icon-style($config: $config);

      &::after {
        font-family: $datepicker-icon-button-font-family;
      }
    }

    .mat-button-focus-overlay,
    .mat-button-ripple {
      left: ruf-theming.ruf-to-rem(-1px);
      padding: ruf-theming.ruf-to-rem(16px);
      top: ruf-theming.ruf-to-rem(6px);

      [dir='rtl'] & {
        left: auto;
        right: ruf-theming.ruf-to-rem(-1px);
      }
    }
  }
}

@mixin ruf-mat-datepicker-panel($cls: '.fis-style', $config: ()) {
  $body-config: ruf-theming.ruf-map-get($config, body, ());
  $today-cell: ruf-theming.ruf-map-get($body-config, today-cell, ());
  $today-cell-border-width: ruf-theming.ruf-map-get($today-cell, border-width, ruf-theming.ruf-to-rem(2px));
  $today-cell-border-style: ruf-theming.ruf-map-get($today-cell, border-style, solid);
  $calendar-range-body-cell-radius: 0px !default;
  $content: ruf-theming.ruf-map-get($config, content, ());
  $content-border-width: ruf-theming.ruf-map-get($content, border-width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 0px));
  $content-border-style: ruf-theming.ruf-map-get($content, border-style, none);

  .mat-datepicker-popup .mat-datepicker-content {
    border-radius: ruf-theming.ruf-map-get($content, border-radius, ruf-theming.ruf-to-rem(4px));
    border-style: $content-border-style;
    border-width: $content-border-width;
  }
  .mat-calendar#{$cls}, .mat-calendar {
    .mat-calendar-body-cell-content {
      border-radius: 0 !important;
      border-style: none;
      &.mat-calendar-body-today {
        border-style: $today-cell-border-style;
        border-width: $today-cell-border-width;
      }
    }
    @include _ruf-date-range( $calendar-range-body-cell-radius);
  }
}
