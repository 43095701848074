@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-labeled-icon-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .ruf-labeled-icon {

  }
}

@mixin ruf-labeled-icon-typography($config) {
  .ruf-labeled-icon {

  }
}

/* Structural config override*/
@mixin ruf-labeled-icon-override($config) {
  @if($config) {
    $label: ruf-theming.ruf-map-get($config, label, ());
    $label-margin-left: ruf-theming.ruf-map-get($label, margin-left, ruf-theming.ruf-to-rem(10px));

    .ruf-labeled-icon.fis-override {

      &:not(.ruf-labeled-icon-rtl) {
        >span.ruf-icon-before {
          margin-left: $label-margin-left;
        }

        >span.ruf-icon-after {
          margin-right: $label-margin-left;
        }
      }

      &.ruf-labeled-icon-rtl {
        >span.ruf-icon-before {
          margin-right: $label-margin-left;
        }

        >span.ruf-icon-after {
          margin-left: $label-margin-left;
        }
      }
    }
  }
}
