@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-tabs-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $inkbar: ruf-theming.ruf-map-get($config, inkbar, ());

  $ruf-tab-highlight-color: ruf-theming.ruf-color($background, hover) !default;
  $ruf-tab-item-active-color: ruf-theming.ruf-map-get($inkbar, default-active-color, ruf-theming.ruf-color($accent)) !default;

  $tab-disabled: ruf-theming.ruf-map-get($config, disabled, ());
  $tab-disabled-color: ruf-theming.ruf-map-get($tab-disabled, color, null);
  $tab-disabled-opacity: ruf-theming.ruf-map-get($tab-disabled, opacity, 0.5);
  $header-pagination: ruf-theming.ruf-map-get($config, header-pagination, ());
  $header-pagination-chevron: ruf-theming.ruf-map-get($header-pagination, chevron-icon, ());
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());

  // Gold specific
  $box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .mat-tab-group#{$cls}, .mat-tab-nav-bar#{$cls} {
    .mat-tab-labels, .mat-tab-links {
      > .mat-tab-label, .mat-tab-link {
        /* Make sure labels have just a little less opacity to further differenciate activation */
        opacity: ruf-theming.ruf-map-get($menu-item, opacity, 0.85);
      }

      .mat-tab-label, .mat-tab-link {
        &:not(.mat-tab-disabled) {
          &:hover, &.cdk-keyboard-focused {
            background-color: $ruf-tab-highlight-color;
         }
        }
      }

      >.mat-tab-label-active {
        opacity: ruf-theming.ruf-map-get($menu-item, active-opacity, 1); /* Make sure active labels have have full opcity */
      }

       .mat-tab-disabled {
        color: $tab-disabled-color;
        opacity: $tab-disabled-opacity;
      }
    }

    .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled) {
      &:hover {
        background-color: ruf-theming.ruf-map-get($header-pagination, hover-background-color, none);
      }

      &.mat-elevation-z4 {
        &.mat-tab-header-pagination-after, &.mat-tab-header-pagination-before {
          border-color: ruf-theming.ruf-map-get($header-pagination, border-color, inherit);
          box-shadow: ruf-theming.ruf-map-get($header-pagination, box-shadow, $box-shadow);
        }
      }
    }
  }

  .mat-tab-header-pagination-chevron {
    border-color: ruf-theming.ruf-map-get($header-pagination-chevron, border-color, inherit);
  }

  .mat-tab-header-pagination-disabled {
    .mat-tab-header-pagination-chevron {
      border-color: ruf-theming.ruf-map-get($header-pagination-chevron, disabled-border-color, inherit);
      opacity: ruf-theming.ruf-map-get($header-pagination-chevron, opacity, 0.12);
    }
  }
}

@mixin ruf-mat-tabs-typography($config, $cls: '[fisStyle]') {
    .mat-tab-group#{$cls}, .mat-tab-nav-bar#{$cls} {
      .mat-tab-link, .mat-tab-label {
        font: {
          family: ruf-theming.ruf-font-family($config, dynamic-menubar);
          size: ruf-theming.ruf-font-size($config, dynamic-menubar);
          weight: ruf-theming.ruf-font-weight($config, dynamic-menubar);
        }
      }

      .mat-tab-label-active {
        font-weight: ruf-theming.ruf-font-weight($config, bold);
      }
   }
}
