@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';
@use 'sass:string';
@use './palette';
@use './theming' as theming-theming;
@use '../core.variables';
@use 'default.config';


$foreground-with-opacity: ( base, text, link );
$background-with-opacity: ( success, error, info, backdrop-overlay, contrast );

// Function to generate a css variables palette with real colors as default.
@function ruf-palette-from-css-var-with-defaults($var, $def-palette) {
  $def-contrast: core.ruf-map-get($def-palette, contrast);
  @return (
    50: var(#{'--' + $var + '-50'}, core.ruf-map-get($def-palette, 50)),
    100: var(#{'--' + $var + '-100'}, core.ruf-map-get($def-palette, 100)),
    200: var(#{'--' + $var + '-200'}, core.ruf-map-get($def-palette, 200)),
    300: var(#{'--' + $var + '-300'}, core.ruf-map-get($def-palette, 300)),
    400: var(#{'--' + $var + '-400'}, core.ruf-map-get($def-palette, 400)),
    500: var(#{'--' + $var}, core.ruf-map-get($def-palette, 500)),
    600: var(#{'--' + $var + '-600'}, core.ruf-map-get($def-palette, 600)),
    700: var(#{'--' + $var + '-700'}, core.ruf-map-get($def-palette, 700)),
    800: var(#{'--' + $var + '-800'}, core.ruf-map-get($def-palette, 800)),
    900: var(#{'--' + $var + '-900'}, core.ruf-map-get($def-palette, 900)),
    A100: var(#{'--' + $var + '-A100'}, core.ruf-map-get($def-palette, A100)),
    A200: var(#{'--' + $var + '-A200'}, core.ruf-map-get($def-palette, A200)),
    A400: var(#{'--' + $var + '-A400'}, core.ruf-map-get($def-palette, A400)),
    A700: var(#{'--' + $var + '-A700'}, core.ruf-map-get($def-palette, A700)),
    contrast: (
      50: var(#{'--' + $var + '-50-contrast'}, core.ruf-map-get($def-contrast, 50)),
      100: var(#{'--' + $var + '-100-contrast'}, core.ruf-map-get($def-contrast, 100)),
      200: var(#{'--' + $var + '-200-contrast'}, core.ruf-map-get($def-contrast, 200)),
      300: var(#{'--' + $var + '-300-contrast'}, core.ruf-map-get($def-contrast, 300)),
      400: var(#{'--' + $var + '-400-contrast'}, core.ruf-map-get($def-contrast, 400)),
      500: var(#{'--' + $var + '-contrast'}, core.ruf-map-get($def-contrast, 500)),
      600: var(#{'--' + $var + '-600-contrast'}, core.ruf-map-get($def-contrast, 600)),
      700: var(#{'--' + $var + '-700-contrast'}, core.ruf-map-get($def-contrast, 700)),
      800: var(#{'--' + $var + '-800-contrast'}, core.ruf-map-get($def-contrast, 800)),
      900: var(#{'--' + $var + '-900-contrast'}, core.ruf-map-get($def-contrast, 900)),
      A100: var(#{'--' + $var + '-A100-contrast'}, core.ruf-map-get($def-contrast, A100)),
      A200: var(#{'--' + $var + '-A200-contrast'}, core.ruf-map-get($def-contrast, A200)),
      A400: var(#{'--' + $var + '-A400-contrast'}, core.ruf-map-get($def-contrast, A400)),
      A700: var(#{'--' + $var + '-A700-contrast'}, core.ruf-map-get($def-contrast, A700))
    ),
    rgba: (
      500: rgba(var(#{'--' + $var + '-rgb'}, core.color-to-rgb-list(core.ruf-map-get($def-palette, 500))), 1),
      default: rgba(var(#{'--' + $var + '-rgb'}, core.color-to-rgb-list(core.ruf-map-get($def-palette, 500))), 1),
    )
  );
}

// Function to generate a css variables palette with main css variable as default.
@function ruf-palette-from-css-var($var) {

  $a100: var('--' + $var);
  $a200: var('--' + $var);
  $a400: var('--' + $var);
  $a700: var('--' + $var);

  @return (
      50: var(#{'--' + $var + '-50'}, var(#{'--' + $var})),
      100: var(#{'--' + $var + '-100'}, var(#{'--' + $var})),
      200: var(#{'--' + $var + '-200'}, var(#{'--' + $var})),
      300: var(#{'--' + $var + '-300'}, var(#{'--' + $var})),
      400: var(#{'--' + $var + '-400'}, var(#{'--' + $var})),
      500: var(#{'--' + $var}),
      600: var(#{'--' + $var + '-600'}, var(#{'--' + $var})),
      700: var(#{'--' + $var + '-700'}, var(#{'--' + $var})),
      800: var(#{'--' + $var + '-800'}, var(#{'--' + $var})),
      900: var(#{'--' + $var + '-900'}, var(#{'--' + $var})),
      A100: $a100,
      A200: $a200,
      A400: $a400,
      A700: $a700,

      contrast: (
      50: var(#{'--' + $var + '-50-contrast'}, var(#{'--' + $var + '-contrast'})),
      100: var(#{'--' + $var + '-100-contrast'}, var(#{'--' + $var + '-contrast'})),
      200: var(#{'--' + $var + '-200-contrast'}, var(#{'--' + $var + '-contrast'})),
      300: var(#{'--' + $var + '-300-contrast'}, var(#{'--' + $var + '-contrast'})),
      400: var(#{'--' + $var + '-400-contrast'}, var(#{'--' + $var + '-contrast'})),
      500: var(#{'--' + $var + '-contrast'}),
      600: var(#{'--' + $var + '-600-contrast'}, var(#{'--' + $var + '-contrast'})),
      700: var(#{'--' + $var + '-700-contrast'}, var(#{'--' + $var + '-contrast'})),
      800: var(#{'--' + $var + '-800-contrast'}, var(#{'--' + $var + '-contrast'})),
      900: var(#{'--' + $var + '-900-contrast'}, var(#{'--' + $var + '-contrast'})),
      A100: $a100,
      A200: $a200,
      A400: $a400,
      A700: $a700,
      )
  );
}

@function ruf-typography-from-css-var-with-defaults($typography-config: default.ruf-get-default-typography-config(), $prefix: '--ruf') {
  // Get input config from existing typography config.
  $input-config: core.ruf-map-get($typography-config, input, null);
  // keep this to replace it with base font family variable --ruf-font-family
  $font-family-without-css-var: core.ruf-map-get($typography-config, font-family, null);

  // Replace value with css vars() function.
  $typography-config: ruf-update-palette-with-css-variables($typography-config, $prefix);
  // get font family in form of css variable -> font-family: var(--ruf-font-family, "Source Sans Pro, Arial")
  $font-family: core.ruf-map-get($typography-config, font-family);

  // Remove font family so we can replace font-family value with base css variable(--ruf-font-family)
  $typography-config: map.remove($typography-config, font-family);

  $typography-config: ruf-replace-prop-value($typography-config, $font-family-without-css-var, font-family,
  var(--ruf-font-family, $font-family-without-css-var));

  // Use line-height as it is because materal does some calculations based on line height
  // for input and resulted value is being used in a variable so we can not override that calulation.
  $typography-config: core.recursive-map-merge($typography-config, (
    font-family: $font-family,
    input: (
      line-height: core.ruf-map-get($input-config, line-height, 1)
    )
  ));

  @return $typography-config;
}


@function ruf-foreground-from-css-var-with-defaults(
  $foreground: default.ruf-get-default-light-theme-foreground-config(),
  $prefix: '--ruf-foreground') {
  $foreground-css-vars: ruf-update-palette-with-css-variables($foreground, $prefix);
  @return map.merge(
    $foreground-css-vars, ( rgba: ruf-convert-color-to-rgb-values($foreground, $prefix, $foreground-with-opacity))
  );
}


@function ruf-background-from-css-var-with-defaults(
  $background: default.ruf-get-default-light-theme-background-config(),
  $prefix: '--ruf-background') {
  $background-css-vars: ruf-update-palette-with-css-variables($background, $prefix);
  @return map.merge(
    $background-css-vars, ( rgba: ruf-convert-color-to-rgb-values($background, $prefix, $background-with-opacity))
  );
}

@function ruf-replace-prop-value($config, $search, $search-key, $replaced-value) {
  $result: ();
  @each $key, $value in $config {
    @if (meta.type-of($value) == 'map') {
      $result: map.merge(($key: ruf-replace-prop-value($value, $search, $search-key, $replaced-value)), $result);
    } @else {
         $index: string.index($key, $search-key);
        @if(index) {
          $result: map.merge(($key: core.str-replace($value, $search, $replaced-value)), $result);
        }

    }
  }
  @return $result;
}

@function ruf-convert-color-to-rgb-values($config, $prefix: '--ruf', $selective-config: null) {
  $result: ();
  $result-config: ();

  // Add only selective properties in final result
  @if($selective-config != null) {
    @each $key in $selective-config {
      $result-config: map.merge(($key: map.get($config, $key)), $result-config);
    }
  } @else {
    $result-config: $config;
  }

  @each $key, $value in $result-config {
    @if (meta.type-of($value) == 'map') {
      // chain the prefix
      $result: map.merge(($key: ruf-convert-color-to-rgb-values($value)), $result);
    } @else {
      // get r,g,b value from color
      $rgb: color.red($value), color.green($value), color.blue($value);
      // merge resulted map
      $result: map.merge(($key: rgba(var(#{$prefix + '-' + $key + '-rgb'}, $rgb), opacity($value))), $result);
    }
  }
  @return $result;
}

@function ruf-update-palette-with-css-variables($config, $prefix: '--ruf') {
  $result: ();

  @each $key, $value in $config {
    @if (meta.type-of($value) == 'map') {
      // chain the prefix
      $result: map.merge(($key: ruf-update-palette-with-css-variables($value, #{$prefix + '-' + $key})), $result);
    } @else {
      $result: map.merge(($key: var(#{$prefix + '-' + $key}, $value)), $result);
    }
  }
  @return $result;
}

/// @deprecated
// update $foreground & $background palette with css variables
@function ruf-update-secondary-palette-with-css-variables($config, $prefix: '--ruf') {
  $result: ();

  @each $key, $value in $config {
    @if (meta.type-of($value)=='map') {
      // If nested map then chain the prefix.
      $result: map.merge(($key: ruf-update-secondary-palette-with-css-variables($value, #{$prefix + '-' + $key})), $result);
    } @else {
        // If map key contains '-alpha', then the value is an opacity value not a color.
        @if(core.ends-with(#{$prefix + '-' + $key}, '-alpha')) {
          // Update opacity with css vars
          $result: map.merge(($key: var(#{$prefix + '-' + $key}, $value)), $result);
        } @else {
          /* If a property with #{$key}-alpha present in map then it must have an opacity value less than 1.
             Add that opacity to rgba() to add expected opacity for color.
             $background-map: (
                ...,
                hover: rgba(0, 0, 0, 0.1),
                canvas: #FFFFFF
                ...
             )

             $result-background-map: (
                 ...,
                hover: rgba(var(--ruf-background-hover, 0, 0, 0), 0.1),
                canvas: rgba(var(--ruf-background-canvas, 255, 255, 255), 1)
                ...
             )
          */
          $opacity: core.ruf-map-get($config, #{$key + '-alpha'}, 1);
          $result: map.merge(($key: rgba(var(#{$prefix + '-' + $key}, $value), $opacity)), $result);
        }
      }
  }

  @return $result;
}

/* mixin to print css vars and values for typography
  The output will be something like this. It can be included in the :root selector.
  --ruf-font-family: "Source Sans Pro", Arial, sans-serif;
  --ruf-display-4-font-family: "Source Sans Pro", Arial, sans-serif;
  --ruf-display-4-font-size: 112px;
  --ruf-display-4-font-weight: 300;
  --ruf-display-4-line-height: 112px;
  ...
*/
@mixin ruf-typography-config-css-var($typography-config: default.ruf-get-default-typography-config()) {
  @each $name, $value in $typography-config {
    @if meta.type-of($value) == 'map' {
      @each $prop-name, $prop-value in $value {
        --ruf-#{$name}-#{$prop-name}: #{$prop-value};
      }
    } @else {
      --ruf-#{$name}: #{$value};
    }
  }
}

/* mixin to print css vars and values for foreground palette
  The output will be something like this. It can be included in the :root selector.
  --ruf-foreground-base: black;
  --ruf-foreground-border: #dfdfdf;
  --ruf-foreground-divider: #c1c1c1;
  --ruf-foreground-dividers: #b4b4b4;

  --ruf-foreground-base: 0, 0, 0
  ...
*/
@mixin ruf-foreground-palette-css-var(
  $foreground-config: default.ruf-get-default-light-theme-foreground-config(),
  $selective-prop: ()) {
  @each $name, $value in $foreground-config {
    --ruf-foreground-#{$name}: #{$value};
  }

  // Add rgb value for selective properties
  @each $name in list.join($foreground-with-opacity, $selective-prop) {
    --ruf-foreground-#{$name}-rgb: #{core.color-to-rgb-list(map.get($foreground-config, $name))};
  }
}

/* mixin to print css vars and values for background palette
  The output will be something like this. It can be included in the :root selector.
  --ruf-background-base: white;
  --ruf-background-panel: white;
  --ruf-background-side: lightgray;
  ...
*/
@mixin ruf-background-palette-css-var(
  $background-config: default.ruf-get-default-light-theme-background-config(),
  $selective-prop: ()) {
  @each $name, $value in $background-config {
    --ruf-background-#{$name}: #{$value};
  }

  // Add rgb value for selective properties
  @each $name in list.join($background-with-opacity, $selective-prop) {
    --ruf-background-#{$name}-rgb: #{core.color-to-rgb-list(map.get($background-config, $name))};
  }
}

@function get-default-basic-css-vars-theme($is-dark: false) {
  $primary: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      primary,
      palette.$ruf-fis-primary
    )
  );
  $accent: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      accent,
      if($is-dark, palette.$ruf-fis-accent-dark, palette.$ruf-fis-accent1)
    )
  );
  $emphasis: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      emphasis,
      palette.$ruf-fis-emphasis1
    )
  );
  $warn: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      warn,
      palette.$ruf-fis-orange
    )
  );

  $success: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      success,
      palette.$ruf-fis-success
    )
  );
  $error: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(
      error,
      palette.$ruf-fis-error
    )
  );
  $info: theming-theming.ruf-palette(
    ruf-palette-from-css-var-with-defaults(info, palette.$ruf-fis-info)
  );

  $foreground: ruf-foreground-from-css-var-with-defaults(
    if(
      $is-dark,
      default.ruf-get-default-dark-theme-foreground-config(),
      default.ruf-get-default-light-theme-foreground-config()
    )
  );

  $background: ruf-background-from-css-var-with-defaults(
    if(
      $is-dark,
      default.ruf-get-default-dark-theme-background-config(),
      default.ruf-get-default-light-theme-background-config()
    )
  );

  $_default-density: 0;

  $theme: ();
  @if ($is-dark) {
    $theme: (
      theming-theming.ruf-dark-theme(
        (
          color: (
            primary: $primary,
            accent: $accent,
            emphasis: $emphasis,
            warn: $warn,
            foreground: $foreground,
            background: $background,
            success: $success,
            error: $error,
            info: $info,
          ),
          density: $_default-density,
        )
      )
    );
  } @else {
    $theme: (
      theming-theming.ruf-light-theme(
        (
          color: (
            primary: $primary,
            accent: $accent,
            emphasis: $emphasis,
            warn: $warn,
            foreground: $foreground,
            background: $background,
            success: $success,
            error: $error,
            info: $info,
          ),
          density: $_default-density,
        )
      )
    );
  }
  @return $theme;
}
