@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-sidenav-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $sidenav-border-color: ruf-theming.ruf-map-get($config, border-color, ruf-theming.ruf-color($foreground, divider));

  .mat-sidenav-container#{$cls} .mat-drawer-backdrop.mat-drawer-shown {
    background-color: ruf-theming.ruf-color($background, backdrop-overlay, 0.2);
    opacity: 1;
  }

  .mat-sidenav-container#{$cls} .mat-drawer:not(.mat-drawer-side) {
    box-shadow: none;
  }

  .mat-drawer-container#{$cls} {
    .mat-drawer-side, .mat-drawer-opened {
      border-right-color: $sidenav-border-color;
    }
  }

  [dir='rtl'] .mat-drawer-container#{$cls} {
    .mat-drawer-side, .mat-drawer-opened {
      border-left-color: $sidenav-border-color;
    }
  }
}
// stylelint-disable block-no-empty
@mixin ruf-mat-sidenav-typography($config, $cls: '[fisStyle]') {
}
