@use 'sass:map';
@use '@ruf/theming' as ruf-theming;
@mixin ruf-statusbar-theme-bidirectionality($theme, $dir: 'ltr', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $color-config: ruf-theming.ruf-map-get($config, color-config, ());
  $is-dark: map-get($theme, is-dark);
  $statusbar: ruf-theming.ruf-map-get($config, statusbar, ());
  $background-hue: ruf-theming.ruf-map-get($statusbar, background-color-hue, if($is-dark, 900, 50));
  $icon-button: ruf-theming.ruf-map-get($config, icon-button, ());
  $hover: ruf-theming.ruf-map-get($icon-button, hover, ());
  $icon-button-hover-background-hue: ruf-theming.ruf-map-get($hover, background-color-hue, if($is-dark, 800, 100));
  $amorphous-button: ruf-theming.ruf-map-get($config, amorphous-button, ());
  $amorphous-button-hover: ruf-theming.ruf-map-get($amorphous-button, hover, ());
  $amorphous-button-hover-background-hue: ruf-theming.ruf-map-get($amorphous-button-hover, background-color-hue, if($is-dark, 800, 100));

  $theme-colors: (
    primary: $primary,
    accent: $accent,
    emphasis: $emphasis,
    warn: $warn,
    success: $success,
    error: $error,
    info: $info
  );

  @each $name, $color in $theme-colors {
    $inkbar-color: ruf-theming.ruf-color($color, 500);
    $close-background-color: ruf-theming.ruf-color($color, $icon-button-hover-background-hue);
    $dir-value: 'left';
    @if ($dir == 'rtl') {
      $dir-value: 'right';
    }

    &.ruf-#{$name} {
      background-color: ruf-theming.ruf-color($color, $background-hue);
      border-color: $inkbar-color;

      color: ruf-theming.ruf-color($foreground, text);
      border-#{$dir-value}-color: $inkbar-color;

      .ruf-statusbar-detail {
        .ruf-statusbar-desc-content {
          background-color: ruf-theming.ruf-color($color, $background-hue);
        }
      }

      .ruf-statusbar-close:hover,
      .ruf-statusbar-expand:hover,
      .ruf-statusbar-close.cdk-keyboard-focused,
      .ruf-statusbar-expand.cdk-keyboard-focused {
        background-color: $close-background-color;
        .mat-button-focus-overlay {
          opacity: 0;
        }
      }

      &.ruf-statusbar-noborder {
        border-#{$dir-value}-color: $inkbar-color;
        border-right-color: $inkbar-color;
      }

      .ruf-statusbar-header-actions {
        .mat-icon-button, .mat-button, .mat-button:hover {
          color: inherit;
        }
      }

      .mat-button:not(.mat-button-disabled),
      .mat-icon-button:not(.mat-button-disabled) {
        &:hover,
        &.cdk-keyboard-focused {
          background-color: ruf-theming.ruf-color($color, $amorphous-button-hover-background-hue);
        }
        span.mat-button-focus-overlay {
          opacity: 0;
        }
      }
    }
  }
}

@mixin ruf-statusbar-theme($theme, $config: ()) {
  .ruf-statusbar {
    &:not(.ruf-statusbar-rtl) {
      @include ruf-statusbar-theme-bidirectionality($theme, 'ltr', $config);
    }

    &.ruf-statusbar-rtl {
      @include ruf-statusbar-theme-bidirectionality($theme, 'rtl', $config);
    }
  }
}


@mixin ruf-statusbar-typography($config) {
  .ruf-statusbar {
    font-family: ruf-theming.ruf-font-family($config);

    .ruf-statusbar-title {
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
      font-weight: ruf-theming.ruf-font-weight($config, bold);
    }

    .ruf-statusbar-subtitle {
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
    }

    .ruf-statusbar-detail {
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
    }
  }
}


/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-statusbar-override($config) {
  @if ($config) {
    $ruf-statusbar-title-config: ruf-theming.ruf-map-get($config, title, ());
    $ruf-statusbar-subtitle-config: ruf-theming.ruf-map-get($config, subtitle, ());
    $ruf-statusbar-close-icon-config: ruf-theming.ruf-map-get($config, close-icon, ());
    $ruf-statusbar-description-config: ruf-theming.ruf-map-get($config, description, ());
    $ruf-statusbar-expand-icon-config: ruf-theming.ruf-map-get($config, expand-icon, ());
    $ruf-statusbar-icon-config: ruf-theming.ruf-map-get($config, icon, ());
    $header-actions: ruf-theming.ruf-map-get($config, header-actions, ());

    $statusbar-border: ruf-theming.ruf-map-get($config, border, 0px);
    $min-height: ruf-theming.ruf-map-get($config, min-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));

    $title-line-height: ruf-theming.ruf-map-get($ruf-statusbar-title-config, line-height, 1.15);
    $subtitle-line-height: ruf-theming.ruf-map-get($ruf-statusbar-subtitle-config, line-height, 1.15);

    $close-icon-margin-top: ruf-theming.ruf-map-get($ruf-statusbar-close-icon-config, margin-top, normal);
    $close-icon-margin-right: ruf-theming.ruf-map-get($ruf-statusbar-close-icon-config, margin-right, normal);
    $icon-height: ruf-theming.ruf-map-get($ruf-statusbar-icon-config, height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 24px));
    $icon-width: ruf-theming.ruf-map-get($ruf-statusbar-icon-config, width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 24px));
    $icon-font-size: ruf-theming.ruf-map-get($ruf-statusbar-icon-config, font-size, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 20px));
    $icon-line-height: ruf-theming.ruf-map-get($ruf-statusbar-icon-config, line-height, 1);

    $expand-icon-line-height: ruf-theming.ruf-map-get($ruf-statusbar-expand-icon-config, line-height, normal);
    $expand-icon-height: ruf-theming.ruf-map-get($ruf-statusbar-expand-icon-config, height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 34px));
    $expand-icon-width: ruf-theming.ruf-map-get($ruf-statusbar-expand-icon-config, width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 38px));
    $expand-icon-padding-top: ruf-theming.ruf-map-get(
                                $ruf-statusbar-expand-icon-config,
                                padding,
                                ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 8px)
                              );
    $expand-icon-padding-right: ruf-theming.ruf-map-get(
                                  $ruf-statusbar-expand-icon-config,
                                  padding,
                                  ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px)
                                );
    $expand-icon-padding-bottom: ruf-theming.ruf-map-get(
                                  $ruf-statusbar-expand-icon-config,
                                  padding,
                                  ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 8px)
                                );
    $expand-icon-padding-left: ruf-theming.ruf-map-get(
                                $ruf-statusbar-expand-icon-config,
                                padding,
                                ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px)
                              );
    $expand-icon-margin-top: ruf-theming.ruf-map-get($ruf-statusbar-expand-icon-config, margin-top, 0px);

    $description-line-height: ruf-theming.ruf-map-get($ruf-statusbar-description-config, line-height, normal);

    .ruf-statusbar.fis-override {
      border: $statusbar-border solid;
      min-height: $min-height;

      .ruf-statusbar-title {
        line-height: $title-line-height;
      }

      .ruf-statusbar-subtitle {
        line-height: $subtitle-line-height;
      }

      .ruf-statusbar-close {
        margin-right: $close-icon-margin-right;
        margin-top: $close-icon-margin-top;
      }

      .ruf-statusbar-header {
        .ruf-statusbar-icon-content {
          mat-icon {
            font-size: $icon-font-size;
            height: $icon-height;
            line-height: $icon-line-height;
            width: $icon-width;
          }
        }
      }

      .ruf-statusbar-expand.ruf-toggle.ruf-toggle-chevron {
        height: $expand-icon-height;
        margin-top: $expand-icon-margin-top !important;
        padding-bottom: $expand-icon-padding-bottom;
        padding-top: $expand-icon-padding-top;
        width: $expand-icon-width;
      }

      .ruf-statusbar-header-actions {
        margin: ruf-theming.ruf-map-get($header-actions, margin);
      }

      .ruf-statusbar-desc-content {
        line-height: $description-line-height;
      }

      &:not(.ruf-statusbar-rtl) {
        @include ruf-statusbar-override-bidirectionality($config, 'ltr');
      }

      &.ruf-statusbar-rtl {
        @include ruf-statusbar-override-bidirectionality($config, 'rtl');
      }
    }
  }
}

@mixin ruf-statusbar-override-bidirectionality($config, $dir: 'ltr') {
  $dir-value: 'left';
  @if ($dir == 'rtl') {
    $dir-value: 'right';
  }
  @if($config) {
    $ruf-statusbar-no-border: ruf-theming.ruf-map-get($config, no-border, ());
    $border-left-width: ruf-theming.ruf-map-get($config, 'border-left-width', null);
    $border-radius: ruf-theming.ruf-map-get($config, 'border-radius', ruf-theming.ruf-to-rem(3px));
    $icon-height: ruf-theming.ruf-map-get($config, 'icon-height', null);
    $no-border-width: ruf-theming.ruf-map-get($ruf-statusbar-no-border, 'width', ruf-theming.ruf-to-rem(0px));

    @include ruf-theming.ruf-override-padding($config, $dir);

    border-#{$dir-value}-width: $border-left-width;

    &.ruf-statusbar-noborder {
      @include ruf-theming.ruf-override-border-style($config);

      border-#{$dir-value}-width: $no-border-width;
    }

    &:not(.ruf-statusbar-noborder) {
      @include ruf-theming.ruf-override-border-style($config);

        border-bottom-right-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
  }
}
