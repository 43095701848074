@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-dialog-panel-theme($theme, $cls: '.fis-style', $config: ()) {
 .cdk-overlay-pane#{$cls} {
   $primary: map.get($theme, primary);
   $accent: map.get($theme, accent);
   $emphasis: map.get($theme, emphasis);
   $background: map.get($theme, background);
   $foreground: map.get($theme, foreground);

   $header-config: ruf-theming.ruf-map-get($config, header, ());
   $ruf-dialog-title-color: ruf-theming.ruf-map-get($header-config, title-color, ruf-theming.ruf-color($primary));

   // Gold specific
   $panel-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

   mat-dialog-container.mat-dialog-container {
    background: ruf-theming.ruf-color($background, dialog);
    box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $panel-box-shadow);
    &:focus {
      outline: none;
    }
     .mat-dialog-title {
       color: $ruf-dialog-title-color;
     }
   }
 }
}

@mixin ruf-mat-dialog-panel-typography($config, $cls: '.fis-style', $override-config: ()) {
  .cdk-overlay-pane#{$cls} {
    $mat-dialog-container: ruf-theming.ruf-map-get($override-config, mat-dialog-container, ());

    .mat-dialog-title {
      @include ruf-theming.ruf-typography-level-to-styles($config, dialog-title);
      font-size: ruf-theming.ruf-font-size($config, dialog-title);
    }

    mat-dialog-container.mat-dialog-container .mat-dialog-content {
      font-family: ruf-theming.ruf-font-family($config, subheading-1);
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
      line-height: ruf-theming.ruf-map-get($mat-dialog-container, line-height, inherit);
   }
  }
}
