@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-app-canvas-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $ruf-app-canvas-background: ruf-theming.ruf-color($background, app-canvas) !default;
  $ruf-app-canvas-color: ruf-theming.ruf-color($foreground, text) !default;

  .ruf-app-canvas {
    background: $ruf-app-canvas-background;
    color: $ruf-app-canvas-color;
  }
}


@mixin ruf-app-canvas-typography($config) {
  .ruf-app-canvas {
    font-family: ruf-theming.ruf-font-family($config);
  }
}

@mixin ruf-app-canvas-override($config) {
  @if ($config) {
    .ruf-app-canvas {
      &.fis-override {
        @include ruf-theming.ruf-override-padding($config);
      }
    }
  }
}
