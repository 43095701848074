@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-page-header-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $header-icon: ruf-theming.ruf-map-get($config, header-icon, ());
  $default-background-icon-color: ruf-theming.ruf-map-get(
    $header-icon,
    default-background-color,
    ruf-theming.ruf-color($background, contrast-overlay-2)) !default;
  $default-icon-text-color: ruf-theming.ruf-map-get($header-icon, default-text-color, inherit);

  $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    emphasis: $emphasis
  );

  .ruf-page-header {
    .ruf-page-header-icon {
      background: $default-background-icon-color;
      color: $default-icon-text-color;
    }

    @each $name, $color in $theme-colors {
      .ruf-#{$name} {
        .ruf-page-header-icon {
          background-color: ruf-theming.ruf-color($background, icon);
          color: inherit;
        }
      }
    }
  }
}

@mixin ruf-page-header-typography($config) {
  .ruf-page-header {
    .ruf-pageheader-toolbar > .ruf-toolbar-rows {
      .ruf-page-header-title,
      .ruf-page-header-metric {
        font-family: ruf-theming.ruf-font-family($config, title);
        font-size: ruf-theming.ruf-font-size($config, title);
        font-weight: ruf-theming.ruf-font-weight($config, title);
        line-height:  ruf-theming.ruf-line-height($config, title);
      }
      .ruf-page-header-description,
      .ruf-page-header-metric-description {
        font-family: ruf-theming.ruf-font-family($config, caption);
        font-size: ruf-theming.ruf-font-size($config, caption);
        font-weight: ruf-theming.ruf-font-weight($config, caption);
      }

      .ruf-page-header-text-icon {
        > div {
          font-family: ruf-theming.ruf-font-family($config, title);
          font-weight: ruf-theming.ruf-font-weight($config, subheading-2);
        }
      }


    }
  }
}

/* Structural config override*/
@mixin ruf-page-header-override($config) {
  @if ($config) {
    $first-row-padding-horizontal: ruf-theming.ruf-map-get(
                                    $config,
                                    first-row-padding-horizontal,
                                    ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px)
                                  );
    $icon-config: ruf-theming.ruf-map-get($config, icon);
    $title-config: ruf-theming.ruf-map-get($config, title);
    $metric-config: ruf-theming.ruf-map-get($config, metric);
    $row: ruf-theming.ruf-map-get($config, row);
    .ruf-page-header.fis-override {
      .ruf-pageheader-toolbar > .ruf-toolbar-rows {
        > .ruf-toolbar-first-row {
          padding: ruf-theming.ruf-map-get(
            $config,
            padding-vertical,
            ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px)
          ) ruf-theming.ruf-map-get($config, padding-horizontal, 0);
        }

        .ruf-page-header-title-area {
          min-height: ruf-theming.ruf-map-get($title-config, min-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));
        }

        .ruf-page-header-icon.fis-override {
          border-radius: ruf-theming.ruf-map-get($icon-config, border-radius, 50%);
          height: ruf-theming.ruf-map-get($icon-config, height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));
          width: ruf-theming.ruf-map-get($icon-config, width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));


          > div {
            font-size: ruf-theming.ruf-map-get($icon-config, font-size, ruf-theming.ruf-to-rem(24px));
          }

          &.ruf-page-header-text-icon {
            > div {
              $text-icon: ruf-theming.ruf-map-get($icon-config, text);
              font-size: ruf-theming.ruf-map-get($text-icon, font-size, ruf-theming.ruf-to-rem(18px));
            }
          }

        }
        .ruf-page-header-description.fis-override {
        }

        .ruf-page-header-title.fis-override {
          margin: ruf-theming.ruf-map-get($title-config, margin, 0);
        }

        .ruf-page-header-metric {
          margin: ruf-theming.ruf-map-get($metric-config, margin, 0);
        }

        > ruf-toolbar-row, > ruf-page-header-row {
          margin: ruf-theming.ruf-map-get($row, margin, 0 auto);
          padding: ruf-theming.ruf-map-get($row, padding, 0);
          width: ruf-theming.ruf-map-get($row, width, 100%);
        }
      }
      .ruf-pageheader-toolbar .ruf-toolbar-rows {
        .ruf-toolbar-first-row {
          @include ruf-toolbar-first-row-bidirectionality($first-row-padding-horizontal, 'left', 'right');
        }
      }
      .ruf-pageheader-toolbar.ruf-toolbar-rtl {
        .ruf-toolbar-first-row {
          @include ruf-toolbar-first-row-bidirectionality($first-row-padding-horizontal, 'right', 'left');
        }
      }
    }
  }
}

@mixin ruf-toolbar-first-row-bidirectionality($ruf-page-header-first-row-padding-horizontal, $dir1: 'left', $dir2: 'right') {

  > *:first-child:not(.ruf-square) {
     margin-#{$dir1}: $ruf-page-header-first-row-padding-horizontal;
  }

  > *:last-child:not(.ruf-square) {
     margin-#{$dir2}: $ruf-page-header-first-row-padding-horizontal;
  }
}
