@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-menu-label-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

}


@mixin ruf-menu-label-typography($config) {
  .ruf-cust-menu {
    font-family: ruf-theming.ruf-font-family($config);
    .mat-menu-content {
     .mat-menu-item {
        font-size: ruf-theming.ruf-font-size($config, subheading-1);
      }
    }
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-menu-label-override($config) {
  @if ( $config ) {
    $chevron-icon: ruf-theming.ruf-map-get($config, chevron-icon, ());
    $chevron-icon-margin-horizontal: ruf-theming.ruf-map-get($chevron-icon, margin-horizontal, ruf-theming.ruf-to-rem(5px));
    $chevron-icon-content: ruf-theming.ruf-map-get($chevron-icon, pseudo-content, '\e904');
    $chevron-icon-font-size: ruf-theming.ruf-map-get($chevron-icon, font-size, ruf-theming.ruf-to-rem(24px));

    .ruf-menu-label.fis-override {

      .ruf-menu-label-chevron {
        &.fis-icon-arrow-down {
          font-size: $chevron-icon-font-size;
          &::before {
            content: $chevron-icon-content;
          }
        }
      }

      &:not(.ruf-menu-label-rtl) {
        .ruf-menu-label-chevron {
          margin-left: $chevron-icon-margin-horizontal;
        }
      }
      &.ruf-menu-label-rtl {
        .ruf-menu-label-chevron {
          margin-right: $chevron-icon-margin-horizontal;
        }
      }
    }
  }
}
