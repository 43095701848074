@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-stepper($cls: '[fisStyle]', $config: ()) {

  $stepper-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $stepper-hover-config: ruf-theming.ruf-map-get($config, hover, ());

  $icon-border-style: ruf-theming.ruf-map-get($stepper-icon-config, border-style, none);
  $icon-border-width: ruf-theming.ruf-map-get($stepper-icon-config, border-width, ruf-theming.ruf-to-rem(0px));
  $icon-height: ruf-theming.ruf-map-get($stepper-icon-config, height, ruf-theming.ruf-to-rem(24px));
  $icon-width: ruf-theming.ruf-map-get($stepper-icon-config, width, ruf-theming.ruf-to-rem(24px));

  $hover-border-radius: ruf-theming.ruf-map-get($stepper-hover-config, border-radius, 0px);

  .mat-stepper-vertical#{$cls}, .mat-stepper-horizontal#{$cls} {
    .mat-step-header {
      .mat-step-icon {
        border-style: $icon-border-style;
        border-width: $icon-border-width;
        height: $icon-height;
        width: $icon-width;
      }
      &:hover,
      &.cdk-focused,
      &.cdk-program-focused,
      &.cdk-keyboard-focused {
        border-radius: $hover-border-radius;
      }
    }
  }

}
