@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;
@mixin ruf-inkbar-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $main-colors: primary, accent, warn, emphasis, success, error, info;

  .ruf-inkbar {
    &:not(.ruf-inkbar-rtl) {
      @include  _ruf-inkbar-border-color($theme, $main-colors, 'ltr');
    }

    &.ruf-inkbar-rtl {
      @include  _ruf-inkbar-border-color($theme, $main-colors, 'rtl');
    }
  }
}

@mixin _ruf-inkbar-border-color($color-map, $colors, $dir: 'ltr') {
  $position-list: top, bottom, left, right;
  @each $position in $position-list {
    @each $color in $colors {
      $border-position: $position;
      @if($dir == 'rtl') {
        @if($position =='left') {
          $border-position: 'right';
        }
        @if($position =='right') {
          $border-position: 'left';
        }
      }
      &.ruf-inkbar-#{$color} {
        &.ruf-inkbar-#{$position} {
          .ruf-inkbar-item {
            border-#{$border-position}-color: transparent;
            &.ruf-inkbar-active {
              @if(meta.type-of(map.get($color-map, $color)) == 'map') {
                border-#{$border-position}-color: ruf-theming.ruf-color(map.get($color-map, $color));
              }
            }
          }
        }
      }
    }
  }
}
