@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-dialog-panel($cls: '.fis-style', $config: ()) {
  .cdk-overlay-pane#{$cls} {

    $footer: ruf-theming.ruf-map-get($config, footer, ());
    $dialog-border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
    $footer-padding-top: ruf-theming.ruf-map-get($footer, padding-top, ruf-theming.ruf-to-rem(8px));
    $footer-padding-left: ruf-theming.ruf-map-get($footer, padding-left, ruf-theming.ruf-to-rem(0px));
    $footer-padding-right: ruf-theming.ruf-map-get($footer, padding-right, ruf-theming.ruf-to-rem(0px));
    $footer-padding-bottom: ruf-theming.ruf-map-get($footer, padding-bottom, ruf-theming.ruf-to-rem(8px));


    mat-dialog-container.mat-dialog-container {
      border-radius: $dialog-border-radius;
      .mat-dialog-title {
        text-transform: capitalize;
      }
    }


    .mat-dialog-actions {
      padding: $footer-padding-top $footer-padding-right $footer-padding-bottom $footer-padding-left;
      .mat-button, .mat-raised-button {
        margin-right: ruf-theming.ruf-to-rem(8px);
        [dir='rtl'] & {
          margin-left: ruf-theming.ruf-to-rem(8px);
          margin-right: ruf-theming.ruf-to-rem(0px);
        }
      }
      .mat-button + .mat-button,
      .mat-raised-button + .mat-raised-button,
      .mat-button + .mat-raised-button,
      .mat-raised-button + .mat-button {
        margin-left: ruf-theming.ruf-to-rem(0px);
        [dir='rtl'] & {
          margin-right: ruf-theming.ruf-to-rem(0px);
        }
      }
    }
  }

  @include ruf-theming.if-mq(xs) {
    // Modal dailogs will take width of screen minus 10px on both side in mobile viewports.
    .cdk-overlay-pane#{$cls} {
      max-width: calc(100vw) !important;
      // To override inline style max-width: 80vw applied by mat-dialog.
      width: calc(100vw);
      mat-dialog-container.mat-dialog-container {
        margin-left: ruf-theming.ruf-to-rem(10px);
        margin-right: ruf-theming.ruf-to-rem(10px);
      }
    }
  }
}


