@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin _ruf-mat-pseudo-checkbox-inner-content-theme($theme, $pallete-name) {
  $pallete: map.get($theme, $pallete-name);
  $color: ruf-theming.ruf-color($pallete);

  .mat-#{$pallete-name} .mat-pseudo-checkbox-checked,
  .mat-#{$pallete-name} .mat-pseudo-checkbox-indeterminate {
    background: $color !important;
  }
}

@mixin ruf-mat-pseudo-checkbox-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $is-dark-theme: map.get($theme, is-dark);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $default-pseudo-background-color: ruf-theming.ruf-map-get($config, default-background-color, ruf-theming.ruf-color($accent));
  $default-pseudo-checkmark-color: ruf-theming.ruf-map-get($config, default-checkmark-color, ruf-theming.ruf-color($accent, default-contrast));
  $default-border-color: ruf-theming.ruf-map-get($config, default-border-color, ruf-theming.ruf-color($foreground, border));
  $unchecked-ripple-color: ruf-theming.ruf-map-get($config, unchecked-ripple-color, ruf-theming.ruf-color($foreground, base));
  $checked-ripple-color-config: ruf-theming.ruf-map-get($config, checked-ripple-color-config, ());

  $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  );
  *#{$cls} .mat-pseudo-checkbox, .mat-pseudo-checkbox#{$cls} {
    &:not(.mat-pseudo-checkbox-checked) {
      border-color: $default-border-color;
    }

    &.mat-pseudo-checkbox-disabled:not(.mat-pseudo-checkbox-checked) {
      border-color: ruf-theming.ruf-color($foreground, disabled-text);
    }
  }
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $default-pseudo-background-color;
  }
  .mat-pseudo-checkbox-checked::after,
  .mat-pseudo-checkbox-indeterminate::after {
    color: $default-pseudo-checkmark-color !important;
  }
  .mat-pseudo-checkbox-checkmark {
    fill:  $default-pseudo-checkmark-color;
  }
  .mat-pseudo-checkbox-checked {
    // !important is needed here because a stroke must be set as an
    // attribute on the SVG in order for line animation to work properly.
    stroke:  $default-pseudo-checkmark-color !important;
  }
  .mat-option-multiple#{$cls} {
    // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
    // this does not work well with elements layered on top of one another. To get around this we
    // blend the colors together based on the base color and the theme background.
    $white-30pct-opacity-on-dark: #686868;
    $black-26pct-opacity-on-light: #b0b0b0;
    $disabled-color: if(
      $is-dark-theme,
      $white-30pct-opacity-on-dark,
      $black-26pct-opacity-on-light
    );

    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      &.mat-pseudo-checkbox-disabled {
        background: $disabled-color;
      }
    }
  }

  .mat-pseudo-checkbox::before {
    background-color: $unchecked-ripple-color;
  }

  @each $name, $color in $theme-colors {
    *#{$cls} .mat-#{$name}, .mat-#{$name}#{$cls} {
      .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled {
        background-color: ruf-theming.ruf-color($color, 500);
        opacity: 0.5;
      }
      .mat-pseudo-checkbox-checked::after {
        color: ruf-theming.ruf-color($color, default-contrast) !important;
      }

      .mat-pseudo-checkbox-checkmark {
        fill: ruf-theming.ruf-color($color, default-contrast);
      }
      .mat-pseudo-checkbox-checked {
        // !important is needed here because a stroke must be set as an
        // attribute on the SVG in order for line animation to work properly.
        stroke: ruf-theming.ruf-color($color, default-contrast) !important;
      }

      .mat-pseudo-checkbox-checked::before {
        background: ruf-theming.ruf-map-get($checked-ripple-color-config, #{$name}-background-color, ruf-theming.ruf-color($color, 300));
      }
    }
  }
}

@mixin ruf-mat-pseudo-checkbox-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
  .mat-pseudo-checkbox#{$cls} {
    .mat-option-text {
      font-size: ruf-theming.ruf-font-size($config, caption-2);
    }
  }
}
