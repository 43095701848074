@use 'sass:map';
@use 'theming';
@use 'palette';
@use '../core.variables';

@mixin ruf-base-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);
  $warn: map.get($theme, warn);
  $is-dark: map.get($theme, is-dark);
  $ruf-link-color: theming.ruf-color($foreground, link) !default;

  a {
    color: $ruf-link-color;
    cursor: pointer;
    text-decoration: none;
  }

  .ruf-foreground-default {
    color: theming.ruf-color($foreground, text);
  }

  .ruf-foreground-primary, .ruf-icon-primary .mat-icon {
    color: theming.ruf-color($primary) !important;
  }

  .ruf-foreground-primary-dark, .ruf-icon-primary-dark .mat-icon {
    color: theming.ruf-color($primary, darker) !important;
  }

  .ruf-foreground-primary-light, .ruf-icon-primary-light .mat-icon {
    color: theming.ruf-color($primary, lighter) !important;
  }

  .ruf-foreground-accent, .ruf-icon-accent .mat-icon {
    color: theming.ruf-color($accent) !important;
  }

  .ruf-foreground-accent-dark, .ruf-icon-accent-dark .mat-icon {
    color: theming.ruf-color($accent, darker) !important;
  }

  .ruf-foreground-accent-light, .ruf-icon-accent-light .mat-icon {
    color: theming.ruf-color($accent, lighter) !important;
  }

  .ruf-foreground-emphasis, .ruf-icon-emphasis .mat-icon {
    color: theming.ruf-color($emphasis) !important;
  }

  .ruf-foreground-emphasis-dark, .ruf-icon-emphasis-dark .mat-icon {
    color: theming.ruf-color($emphasis, darker) !important;
  }

  .ruf-foreground-emphasis-light, .ruf-icon-emphasis-light .mat-icon {
    color: theming.ruf-color($emphasis, lighter) !important;
  }

  .ruf-foreground-warn, .ruf-icon-warn .mat-icon {
    color: theming.ruf-color($warn) !important;
  }

  .ruf-foreground-warn-800, .ruf-icon-warn-800 .mat-icon {
    color: theming.ruf-color($warn, 800) !important;
  }

  .ruf-foreground-warn-dark, .ruf-icon-warn-dark .mat-icon {
    color: theming.ruf-color($warn, darker) !important;
  }

  .ruf-foreground-warn-light, .ruf-icon-warn-light .mat-icon {
    color: theming.ruf-color($warn, lighter) !important;
  }

  .ruf-foreground-success, .ruf-icon-success .mat-icon {
    color: theming.ruf-color($success) !important;
  }

  .ruf-foreground-success-800, .ruf-icon-success-800 .mat-icon {
    color: theming.ruf-color($success, 800) !important;
  }

  .ruf-foreground-info, .ruf-icon-info .mat-icon {
    color: theming.ruf-color($info) !important;
  }

  .ruf-foreground-error, .ruf-icon-error .mat-icon {
    color: theming.ruf-color($error) !important;
  }

  .ruf-foreground-error-700, .ruf-icon-error-700 .mat-icon {
    color: theming.ruf-color($error, 700) !important;
  }

  .ruf-icon-default-accent2 .mat-icon {
    color: theming.ruf-color(theming.ruf-palette(palette.$ruf-fis-accent2));
  }


  .ruf-background-primary {
    background-color: theming.ruf-color($primary) !important;
    color: theming.ruf-color($primary, default-contrast) !important;
  }

  .ruf-background-primary-dark {
    background-color: theming.ruf-color($primary, darker) !important;
    color: theming.ruf-color($primary, darker-contrast) !important;
  }

  .ruf-background-primary-light {
    background-color: theming.ruf-color($primary, lighter) !important;
    color: theming.ruf-color($primary, lighter-contrast) !important;
  }

  .ruf-background-accent {
    background-color: theming.ruf-color($accent) !important;
    color: theming.ruf-color($accent, default-contrast) !important;
  }

  .ruf-background-accent-dark {
    background-color: theming.ruf-color($accent, darker) !important;
    color: theming.ruf-color($accent, darker-contrast) !important;
  }

  .ruf-background-accent-light {
    background-color: theming.ruf-color($accent, lighter) !important;
    color: theming.ruf-color($accent, lighter-contrast) !important;
  }

  .ruf-background-emphasis {
    background-color: theming.ruf-color($emphasis) !important;
    color: theming.ruf-color($emphasis, default-contrast) !important;
  }

  .ruf-background-emphasis-dark {
    background-color: theming.ruf-color($emphasis, darker) !important;
    color: theming.ruf-color($emphasis, darker-contrast) !important;
  }

  .ruf-background-emphasis-light {
    background-color: theming.ruf-color($emphasis, lighter) !important;
    color: theming.ruf-color($emphasis, lighter-contrast) !important;
  }

  .ruf-background-warn {
    background-color: theming.ruf-color($warn) !important;
    color: theming.ruf-color($warn, default-contrast) !important;
  }

  .ruf-background-warn-dark {
    background-color: theming.ruf-color($warn, darker) !important;
    color: theming.ruf-color($warn, darker-contrast) !important;
  }

  .ruf-background-warn-light {
    background-color: theming.ruf-color($warn, lighter) !important;
    color: theming.ruf-color($warn, lighter-contrast) !important;
  }

  .ruf-background-dark {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: rgba(black, 0.18) !important;
  }

  .ruf-background-darker {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: rgba(black, 0.4) !important;
  }

  .ruf-background-default-dark {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: theming.ruf-color(palette.$ruf-fis-black-dark, 700) !important;
    color: theming.ruf-color($foreground, text) !important;
  }

  .ruf-background-default-dark .mat-icon {
    color: theming.ruf-color($foreground, link);
  }


  .ruf-background-transparent {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: transparent !important;
  }

  .ruf-background-light {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: rgba(white, 0.18) !important;
  }

  .ruf-background-lighter {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: rgba(white, 0.4) !important;
  }

  .ruf-background-error {
    background-color: theming.ruf-color($error, 400) !important;
    color: theming.ruf-contrast($error, 400) !important;

    .mat-icon {
      color: theming.ruf-contrast($error, 400) !important;
    }
  }

  .ruf-background-white {
    /* Reason for !important: class added by user meant to override existing background.*/
    background-color: rgb(255, 255, 255) !important;
  }

  .ruf-background-success {
    background-color: theming.ruf-color($success) !important;
    color: theming.ruf-color($success, default-contrast) !important;

    .mat-icon {
      color: theming.ruf-color($success, default-contrast) !important;
    }
  }

  .ruf-background-info {
    background-color: theming.ruf-color($info) !important;
    color: theming.ruf-color($info, default-contrast) !important;

    .mat-icon {
      color: theming.ruf-color($info, default-contrast) !important;
    }
  }

  .ruf-background-canvas {
    background-color: theming.ruf-color($background, app-canvas);
  }

  .ruf-background-card {
    background-color: theming.ruf-color($background, card);
  }

  .ruf-border {
    border-color: theming.ruf-color($foreground, border);
  }

  .ruf-background-highlight {
    background-color: if($is-dark, theming.ruf-color($background, halo), theming.ruf-color($background, alternate-row)) !important;
  }

  .ruf-highlight {
    background-color: theming.ruf-color($background, highlight);
    color: theming.ruf-color($foreground, text);
  }

  .ruf-background-fis-black {
    background-color: theming.ruf-color(palette.$ruf-fis-black-dark, 900) !important;
    border-bottom-color: theming.ruf-color(palette.$ruf-fis-black-dark, 400) !important;
    color: theming.ruf-color($foreground, lighter-text) !important;
  }
  .ruf-elevation-z1 {
    border: core.ruf-to-rem(1px) solid theming.ruf-color($foreground, border) !important;
    box-shadow: core.ruf-to-rem(3px) core.ruf-to-rem(3px) core.ruf-to-rem(0px)
    core.ruf-to-rem(-1px) theming.ruf-color($foreground, border) !important;
   }
}
