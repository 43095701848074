@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin _ruf-dynamic-menubar-inkbar-color($color, $hue: default) {
  .mat-tab-group.mat-primary .mat-tab-list .mat-ink-bar {
    background-color: ruf-theming.ruf-color($color, $hue);
  }

  .ruf-menu-label {
    .ruf-close.mat-icon-button[fisStyle] {

      &:hover,
      &.cdk-keyboard-focused {
        background-color: ruf-theming.ruf-color($color);
        color: ruf-theming.ruf-color($color, default-contrast);
        transition: background-color 0.2s ease-in 0s, color 0.2s ease-in 0s;
      }
    }
  }
}


@mixin _ruf-dynamic-menubar-header-pagination-theme($theme, $config) {
  $header-pagination: ruf-theming.ruf-map-get($config, header-pagination, ());
  $header-pagination-chevron: ruf-theming.ruf-map-get($header-pagination, chevron-icon, ());
  // Gold specific
  $box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled) {
    &:hover {
      background-color: ruf-theming.ruf-map-get($header-pagination, hover-background-color, none);
    }

    &.mat-elevation-z4 {

      &.mat-tab-header-pagination-after,
      &.mat-tab-header-pagination-before {
        border-color: ruf-theming.ruf-map-get($header-pagination, border-color, inherit);
        box-shadow: ruf-theming.ruf-map-get($header-pagination, box-shadow, $box-shadow);
        color: inherit;
      }
    }
  }

  .mat-tab-header-pagination.mat-tab-header-pagination-disabled {
      color: inherit;
  }

.mat-tab-header-pagination-chevron {
    border-color: ruf-theming.ruf-map-get($header-pagination-chevron, border-color, inherit);
  }

  .mat-tab-header-pagination-disabled {
    .mat-tab-header-pagination-chevron {
      border-color: ruf-theming.ruf-map-get($header-pagination-chevron, disabled-border-color, inherit);
      opacity: ruf-theming.ruf-map-get($header-pagination-chevron, opacity, 0.12);
    }
  }
}

@mixin _ruf-dynamic-menubar-header-pagination-structure($config, $dir1: 'left', $dir2: 'right') {
  $header-pagination: ruf-theming.ruf-map-get($config, header-pagination, ());

  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled) {
    &.mat-tab-header-pagination-after {
      border-#{$dir1}-width: ruf-theming.ruf-map-get($header-pagination, border-left-width, ruf-theming.ruf-to-rem(0px));
      border-#{$dir1}-style: solid;
    }

    &.mat-tab-header-pagination-before {
      border-#{$dir2}-width: ruf-theming.ruf-map-get($header-pagination, border-left-width, ruf-theming.ruf-to-rem(0px));
      border-#{$dir2}-style: solid;
    }
  }
}

@mixin set-ruf-menu-label-styles($dir: ltr, $config: ()) {
  $close-icon-button: ruf-theming.ruf-map-get($config, close-icon-button, ());

  .ruf-menu-label {
    .ruf-close.mat-icon-button[fisStyle] {
      @if ($dir=='ltr') {
        margin-left: ruf-theming.ruf-map-get($close-icon-button, margin-horizontal, ruf-theming.ruf-to-rem(5px));
      } @else {
        margin-right: ruf-theming.ruf-map-get($close-icon-button, margin-horizontal, ruf-theming.ruf-to-rem(5px));
      }
    }
  }
}

@mixin ruf-dynamic-menubar-theme($theme, $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $inkbar: ruf-theming.ruf-map-get($config, inkbar, ());
  $close-icon: ruf-theming.ruf-map-get($config, close-icon, ());
  $header-pagination: ruf-theming.ruf-map-get($config, header-pagination, ());
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $nav-menu: ruf-theming.ruf-map-get($config, nav-menu, ());

  $ruf-dynamic-menubar-highlight-color: ruf-theming.ruf-color($background, hover) !default;
  $ruf-dynamic-menubar-item-active-color: ruf-theming.ruf-map-get($inkbar, default-active-color, ruf-theming.ruf-color($accent)) !default;
  $ruf-dynamic-menubar-border-bottom-color: ruf-theming.ruf-map-get($header-pagination, border-color, ruf-theming.ruf-color($foreground, divider));

  $theme-colors: (primary: $primary,
      accent: $accent,
      warn: $warn,
      emphasis: $emphasis,
      default: $accent);
  .ruf-dynamic-menubar {
    &:not(.ruf-dynamic-tabs) {
    .ruf-dynamic-menubar-container {
      border-bottom-color: $ruf-dynamic-menubar-border-bottom-color;
    }
  }
    .mat-tab-group {
      .mat-tab-labels {
        >.mat-tab-label {
          opacity: ruf-theming.ruf-map-get($menu-item, opacity, 0.85);
          /* Make sure labels have just a little less opacity to further differenciate activation */
        }

        .mat-tab-label {
          &:not(.mat-tab-disabled) {

            &:hover,
            &.cdk-keyboard-focused,
            &.cdk-program-focused {
              background-color: $ruf-dynamic-menubar-highlight-color;
            }
          }
        }

        >.mat-tab-label-active {
          opacity: ruf-theming.ruf-map-get($menu-item, active-opacity, 1);
          /* Make sure active labels have have full opcity */
        }
      }

      // mat-tab-group adds proper contrast color for primary, accent, warn but
      // emphasis is specifc to RUF and we have to add contrast color seperately
      // on emphasis background color.
        &.mat-background-emphasis {
          .mat-tab-label, .mat-tab-link {
            color: ruf-theming.ruf-color($emphasis, default-contrast);
          }
        }

      @include _ruf-dynamic-menubar-header-pagination-theme($theme, $config);
    }

    // default accent color when no background and active color.
    .mat-tab-group.mat-primary .mat-tab-list .mat-ink-bar {
      background-color: $ruf-dynamic-menubar-item-active-color;
    }

    @each $name, $color in $theme-colors {

      // Set the inkbar color.
      &.ruf-#{$name} {
        @include _ruf-dynamic-menubar-inkbar-color($color);
      }
    }

    // Override ink bar to default when background color &
    // active color are primary
    &.ruf-primary {
      .mat-tab-group.mat-primary.mat-background-primary .mat-tab-list .mat-ink-bar {
        background-color: $ruf-dynamic-menubar-item-active-color;
      }
    }

    .ruf-menu-label {
      .ruf-close.mat-icon-button[fisStyle] {

        &:hover,
        &.cdk-keyboard-focused {
          background-color: ruf-theming.ruf-map-get($close-icon, hover-color, ruf-theming.ruf-color($accent));
          color: ruf-theming.ruf-map-get($close-icon, color, ruf-theming.ruf-color($accent, default-contrast));
          transition: background-color 0.2s ease-in 0s, color 0.2s ease-in 0s;
        }
      }
    }

    &.ruf-dynamic-tabs {
      @include ruf-tabs-theme($theme, $config);
    }

    .ruf-overflow-menu-container {
      .ruf-overflow-menu {
        outline: none;

        &:hover,
        &.cdk-keyboard-focused {
          background-color: ruf-theming.ruf-color($background, hover);
        }
      }
    }

    &.ruf-dynamic-menubar-with-ruf-page-header:not(.ruf-dynamic-tabs) {
      .mat-tab-labels > .mat-tab-label-active {
        background: ruf-theming.ruf-map-get($nav-menu, active-background-color, transparent);
      }

       .mat-tab-labels {
        background: ruf-theming.ruf-map-get($nav-menu, background-color, transparent);
      }
    }
  }
  .ruf-drag-element {
    color: ruf-theming.ruf-color($foreground, text) !important;
  }
}

@mixin ruf-dynamic-menubar-typography($config) {
  .ruf-dynamic-menubar {
    font-family: ruf-theming.ruf-font-family($config);

    .mat-tab-label,
    .mat-tab-label .mat-tab-label-content > a,
    .mat-tab-link {
      font: {
        family: ruf-theming.ruf-font-family($config, dynamic-menubar);
        size: ruf-theming.ruf-font-size($config, dynamic-menubar);
        weight: ruf-theming.ruf-font-weight($config, dynamic-menubar);
      }
    }

    &.ruf-dynamic-menubar-flex-height .mat-tab-label {
      .mat-tab-label-content > a {
        line-height: inherit;
      }
    }
    &.ruf-dynamic-menubar-flex-height .mat-tab-labels > .mat-tab-label {
      line-height: inherit;
    }

    .mat-tab-labels > .mat-tab-label {
      line-height: ruf-theming.ruf-line-height($config, dynamic-menubar);
    }

    .mat-tab-label-active,
    .mat-tab-label-active .mat-tab-label-content > a {
      font-weight: ruf-theming.ruf-font-weight($config, bold);
    }
  }
}

@mixin ruf-tabs-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $hover: rgba(0, 0, 0, 0.345);
  $highlight: rgba(0, 0, 0, 0.12);
  $background: map.get($theme, background);

  $tabs-menu: ruf-theming.ruf-map-get($config, tabs-menu, ());
  $close-icon: ruf-theming.ruf-map-get($config, close-icon, ());

  .ruf-menu-label {
    .ruf-close.mat-icon-button[fisStyle] {
      &:hover,
      &.cdk-keyboard-focused {
        background-color: ruf-theming.ruf-map-get($close-icon, hover-color, $highlight);
        color: inherit;

      }
    }
  }

  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled):hover {
    background-color: $hover;
  }

  .mat-tab-list .mat-ink-bar {
    background: none !important;
  }

  // Active color when no background-color set for dynamic menubar
  .mat-tab-labels {
    >.mat-tab-label-active {
      background: ruf-theming.ruf-map-get($tabs-menu, active-background-color, ruf-theming.ruf-color($background, panel));
    }
  }

  .mat-tab-group {
    .mat-tab-labels {
      background-color: ruf-theming.ruf-map-get($tabs-menu, background-color, $highlight);
    }

    .mat-tab-labels {
      .mat-tab-label:not(.mat-tab-disabled) {

        &:hover,
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
          background-color: ruf-theming.ruf-map-get($tabs-menu, menu-item-hover-color, ruf-theming.ruf-color($background, hover));
        }
      }
    }

    // Set background color of the active tab
    &.mat-background-primary {
      .mat-tab-labels {
        >.mat-tab-label-active {
          background: ruf-theming.ruf-color($primary);
        }
      }
    }
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-dynamic-menubar-override($config) {
  @if ($config) {
    $close-icon: ruf-theming.ruf-map-get($config, close-icon, ());
    $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
    $inkbar: ruf-theming.ruf-map-get($config, inkbar, ());
    $item-padding-horizontal: ruf-theming.ruf-map-get($menu-item, padding-horizontal, ruf-theming.ruf-to-rem(15px));
    $item-padding-vertical: ruf-theming.ruf-map-get($menu-item, padding-vertical, ruf-theming.ruf-to-rem(0px));
    $menu-with-page-header: ruf-theming.ruf-map-get($config, menu-with-page-header, ());
    $menu-item-with-page-header: ruf-theming.ruf-map-get($menu-with-page-header, menu-item, ());
    $item-padding-horizontal-with-page-header: ruf-theming.ruf-map-get($menu-item-with-page-header, padding-horizontal, ruf-theming.ruf-to-rem(15px));
    $item-padding-vertical-with-page-header: ruf-theming.ruf-map-get($menu-item-with-page-header, padding-vertical, ruf-theming.ruf-to-rem(0px));
    $inkbar-with-page-header: ruf-theming.ruf-map-get($menu-with-page-header, inkbar, ());

    .ruf-dynamic-menubar.fis-override {
      &:not(.ruf-dynamic-menubar-rtl) {
        @include _ruf-dynamic-menubar-header-pagination-structure($config);
        @include set-ruf-menu-label-styles('ltr', $config);
      }

      &.ruf-dynamic-menubar-rtl {
        @include _ruf-dynamic-menubar-header-pagination-structure($config, 'right', left);
        @include set-ruf-menu-label-styles('rtl', $config);
      }

      .mat-tab-labels > .mat-tab-label .mat-tab-label-content > a {
        padding: $item-padding-vertical $item-padding-horizontal;
      }

      .mat-ink-bar {
        height: ruf-theming.ruf-map-get($inkbar, height, ruf-theming.ruf-to-rem(3px));
      }

      .ruf-menu-label {
        .ruf-close.mat-icon-button[fisStyle] {
          &:hover {
            text-shadow: ruf-theming.ruf-map-get($close-icon, text-shadow, 0 0 ruf-theming.ruf-to-rem(1px));
          }

          .mat-icon.fis-icon-xs {
            margin-left: ruf-theming.ruf-map-get($close-icon, margin-left, null);
            margin-right: ruf-theming.ruf-map-get($close-icon, margin-right, null);
          }
        }
      }


      &.ruf-dynamic-menubar-with-ruf-page-header {
        .mat-tab-labels > .mat-tab-label {
          height: auto;
          .mat-tab-label-content > a {
            height: ruf-theming.ruf-map-get($menu-item-with-page-header, height, inherit);
            line-height: ruf-theming.ruf-map-get($menu-item-with-page-header, height, inherit);
            padding: $item-padding-vertical-with-page-header $item-padding-horizontal-with-page-header;
          }
        }

        .mat-ink-bar {
          height: ruf-theming.ruf-map-get($inkbar-with-page-header, height, ruf-theming.ruf-to-rem(2px));
        }
      }

      .ruf-overflow-menu-container {
        .ruf-overflow-menu {
          padding: ruf-theming.ruf-to-rem(0px) $item-padding-horizontal;
        }
      }
    }
  }
}
