@use '@angular/material' as mat;
@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-paginator-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $mat-paginator-color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, secondary-text));

  .mat-paginator#{$cls} {
    color: $mat-paginator-color;

    .mat-paginator-navigation-first,
    .mat-paginator-navigation-last,
    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next {
      .mat-button-wrapper {
        svg {
          display: none;
        }
      }

      &:not([disabled]):hover, &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        background-color:  ruf-theming.ruf-color($background, halo);
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}
@mixin ruf-mat-paginator-typography($config, $cls: '[fisStyle]') {
  .mat-paginator#{$cls} {
    .mat-paginator-page-size-label, .mat-paginator-range-label, .mat-paginator-page-size-value {
      font-size: ruf-theming.ruf-font-size($config, caption-2);
    }
    .mat-paginator-page-size .mat-select-trigger {
      font: {
        family: ruf-theming.ruf-font-family($config, input);
        size: ruf-theming.ruf-font-size($config, input);
      }
    }
  }
}
