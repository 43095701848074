@use 'sass:map';
@use '@ruf/theming' as ruf-theming;
@use '../sidemenu/_sidemenu.theme';

@mixin ruf-dynamic-sidemenu-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark: map.get($theme, is-dark);

  // Gold specific
  $sidemenu-dark-bg-color: if($is-dark, ruf-theming.ruf-color($background, hover-dark), rgba(map.get(ruf-theming.$ruf-blue, 500), 0.12) );
  $sidemenu-light-bg-color: if($is-dark, rgba(0, 0, 0, 0.1), ruf-theming.ruf-color($background, hover-dark));

  // dynamic sidemenu item
  $sidemenu-item: ruf-theming.ruf-map-get($config, sidemenu-item, ());
  $sidemenu-item-dark: ruf-theming.ruf-map-get($sidemenu-item, dark-background, ());
  $sidemenu-item-light: ruf-theming.ruf-map-get($sidemenu-item, light-background, ());

   // dark sidemenu item properties
   $dark-parent-item-hover-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, parent-item-hover-bg-color, $sidemenu-dark-bg-color);
   $dark-parent-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, parent-item-active-bg-color, $sidemenu-dark-bg-color);
   $dark-children-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, children-bg-color, $sidemenu-dark-bg-color);
   $dark-child-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, child-item-hover-bg-color, transparent);

   // light sidemenu item properties
   $light-parent-item-hover-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, parent-item-hover-bg-color, $sidemenu-light-bg-color);
   $light-parent-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, parent-item-active-bg-color, $sidemenu-light-bg-color);
   $light-children-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, children-bg-color, $sidemenu-light-bg-color);
   $light-child-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, child-item-hover-bg-color, transparent);

  //toggle, active
  $toggle-chevron-color: ruf-theming.ruf-map-get($sidemenu-item, toggle-chevron-color, inherit);

  $ruf-dynamic-sidemenu-menu-content-background:
 ruf-theming.ruf-map-get($config, minimized-panel-background, ruf-theming.ruf-color($background, hover-dark)) !default;
  $children-bg-color: ruf-theming.ruf-map-get($config, children-background-color, rgba(255, 255, 255, 0.1));
  // This will be transparent once we add the styling of popup
  $item-active-color: ruf-theming.ruf-map-get($config, item-active-color, rgba(255, 255, 255, 0.1));
  $children-highlight-color: ruf-theming.ruf-map-get($config, children-highlight-color, transparent);

  $ruf-dynamic-sidemenu-light-background-color: ruf-theming.ruf-map-get(
                                                  $config,
                                                  light-background-color,
                                                  rgba(map.get(ruf-theming.$ruf-blue, 500), 0.12)
                                                );
  $ruf-dynamic-sidemenu-light-background-hover-color:
 ruf-theming.ruf-map-get($config, light-background-hover-color, rgba(map.get(ruf-theming.$ruf-blue, 500), 0.12));
  $ruf-dynamic-sidemenu-children-light-background-hover-color: ruf-theming.ruf-map-get($config, children-light-background-hover-color, transparent);

  $ruf-dynamic-sidemenu-minimized-section-header-border:
  if($is-dark, ruf-theming.ruf-color($background, halo), ruf-theming.ruf-color($background, hover-dark));

  .ruf-dynamic-sidemenu {
   &:not([light]) .ruf-sidemenu {
    @include sidemenu.ruf-sidemenu-item-color($dark-parent-item-hover-bg-color, $dark-parent-item-active-bg-color,
    $dark-children-bg-color, $dark-child-item-active-bg-color);
  }

  .ruf-inline-sticky-button-container {
    button.mat-button, button.mat-button:hover {
      color: inherit;
    }
  }

  &:not([light]) .ruf-inline-sticky-button-container,
  &:not([light]) .ruf-sidemenu .ruf-menubar-item.ruf-unminimized-mode,
  &:not([light]) .ruf-sidemenu .ruf-menubar-item.ruf-minimized-mode {
    button.mat-button:hover,
    button.mat-button.cdk-focused.cdk-keyboard-focused,
    button.mat-button.cdk-focused.cdk-program-focused,
    button.mat-icon-button:hover,
    button.mat-icon-button.cdk-focused.cdk-keyboard-focused,
    button.mat-icon-button.cdk-focused.cdk-program-focused {
      background-color: $dark-parent-item-hover-bg-color;
    }
  }

   &[light] .ruf-sidemenu {
      @include sidemenu.ruf-sidemenu-item-color($light-parent-item-hover-bg-color, $light-parent-item-active-bg-color,
       $light-children-bg-color, $light-child-item-active-bg-color);
    }

    &[light] .ruf-inline-sticky-button-container,
    &[light] .ruf-sidemenu .ruf-menubar-item.ruf-unminimized-mode,
    &[light] .ruf-sidemenu .ruf-menubar-item.ruf-minimized-mode {
      button.mat-button:hover,
      button.mat-button.cdk-focused.cdk-keyboard-focused,
      button.mat-button.cdk-focused.cdk-program-focused,
      button.mat-icon-button:hover,
      button.mat-icon-button.cdk-focused.cdk-keyboard-focused,
      button.mat-icon-button.cdk-focused.cdk-program-focused {
        background-color: $light-parent-item-hover-bg-color;
      }
    }

    .ruf-expand-sidemenu-popup-icon.mat-icon,
    .ruf-slide-menu-list .ruf-slide-menu-item .ruf-slide-menu-item-link > mat-icon,
    .ruf-slide-menu-header mat-icon.ruf-back-icon,
    .mat-icon-button mat-icon[fisicon='chevron-double'] {
      color: $toggle-chevron-color;
    }

    .ruf-sidemenu {
      &.ruf-dynamic-sidemenu-minimized {
        a.ruf-menu-item.ruf-sidemenu-item-section-header-minimized {
          border-bottom-color: $ruf-dynamic-sidemenu-minimized-section-header-border;
          border-bottom-style: solid;
  }
      }
    }
  }

  // disable sliding animation on [@.disabled]="true"
  // Note that the `NoopAnimationsModule` is being handled inside of the component code.
  .ruf-dynamic-sidemenu.ng-animate-disabled, *.ng-animate-disabled {
    .ruf-slide-menu-root-list {
      transition: none !important;
    }
  }

  .ruf-sidemenu-popup-backdrop + * .mat-menu-content {
    @include sidemenu.ruf-sidemenu-item-color($dark-parent-item-hover-bg-color, $dark-parent-item-active-bg-color,
     $dark-children-bg-color, $dark-child-item-active-bg-color);
    background: $ruf-dynamic-sidemenu-menu-content-background;
  }

  .ruf-sidemenu-popup-backdrop + * .mat-menu-panel {
    border-color: ruf-theming.ruf-map-get($config, minimized-panel-border-color, transparent);
  }
}

@mixin ruf-dynamic-sidemenu-section-header() {
  .ruf-dynamic-sidemenu-section-header-italic {
    font-style: italic;
  }
  .ruf-dynamic-sidemenu-section-header-uppercase {
    text-transform: uppercase;
  }
}

@mixin ruf-dynamic-sidemenu-typography($config) {
  .ruf-dynamic-sidemenu {
    font-family: ruf-theming.ruf-font-family($config);
    @include ruf-dynamic-sidemenu-section-header();

    .ruf-inline-sticky-button-container {
      ruf-labeled-icon span {
        font-size: ruf-theming.ruf-font-size($config, menu-item);
      }
    }
  }

  .ruf-sidemenu-children {
    .ruf-side-menu-popup-title {
      font-family: ruf-theming.ruf-font-family($config, menu-item);
      font-size: ruf-theming.ruf-font-size($config, menu-item);
      font-weight: ruf-theming.ruf-font-weight($config, menu-item);
      line-height: ruf-theming.ruf-line-height($config, menu-item);
    }
  }

  .ruf-sidemenu-popup-backdrop + * .mat-menu-panel {
    @include ruf-dynamic-sidemenu-section-header();
}
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-dynamic-sidemenu-override($config) {
    @if($config) {
      $popup-trigger: ruf-theming.ruf-map-get($config, popup-trigger, ());
      $popup-panel: ruf-theming.ruf-map-get($config, popup-panel, ());
      $trigger-icon-transform-deg: ruf-theming.ruf-map-get($popup-trigger, trigger-icon-transform-deg, none);
      $minimized-icon-size: ruf-theming.ruf-map-get($config, minimized-icon-size, ruf-theming.ruf-to-rem(20px));
      $sticky-item: ruf-theming.ruf-map-get($config, sticky-item, ());
      $ruf-labeled-icon: ruf-theming.ruf-map-get($sticky-item, ruf-labeled-icon, ());
      $margin-left-ltr: ruf-theming.ruf-map-get($ruf-labeled-icon, margin-left-ltr, ());
      $margin-right-rtl: ruf-theming.ruf-map-get($ruf-labeled-icon, margin-right-rtl, ());

      .ruf-dynamic-sidemenu.fis-override {
        .ruf-minimized-sidemenu-icon {
          &.mat-icon {
            font-size: $minimized-icon-size;
          }
        }

        .ruf-inline-sticky-button-container {
          .ruf-labeled-icon .mat-icon {
            font-size: $minimized-icon-size;
          }
        }

        .ruf-sidemenu {
          &:not(.ruf-sidemenu-rtl) {
            &.ruf-dynamic-sidemenu-minimized {
                .ruf-expand-sidemenu-popup-icon {
                  transform: rotate($trigger-icon-transform-deg);
                }
            }
          }

          &.ruf-sidemenu-rtl {
            &.ruf-dynamic-sidemenu-minimized {
                .ruf-expand-sidemenu-popup-icon {
                  transform: rotate(-$trigger-icon-transform-deg);
                }
            }
          }

        }

        .ruf-inline-sticky-button-container {
          button.ruf-sticky-button.mat-button {
            .ruf-labeled-icon:not(.ruf-labeled-icon-rtl) {
              span.ruf-icon-before {
                margin-left:  $margin-left-ltr;
              }
            }
            .ruf-labeled-icon.ruf-labeled-icon-rtl {
              span.ruf-icon-before {
                margin-right: $margin-right-rtl;
              }
            }
          }
        }
      }

      .ruf-sidemenu-popup-backdrop + * .mat-menu-panel.fis-override {
        .mat-menu-content {
          padding-bottom: ruf-theming.ruf-map-get($popup-panel, padding-vertical, ruf-theming.ruf-to-rem(8px));
          padding-top: ruf-theming.ruf-map-get($popup-panel, padding-vertical, ruf-theming.ruf-to-rem(8px));
        }
        border-radius: ruf-theming.ruf-map-get($popup-panel, border-radius, ruf-theming.ruf-to-rem(0px));
        border-style: solid;
        border-width: ruf-theming.ruf-map-get($popup-panel, border-width, ruf-theming.ruf-to-rem(0px));
      }
  }
}
