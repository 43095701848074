@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
// stylelint-disable block-no-empty
@mixin ruf-mat-autocomplete($cls: '[fisStyle]', $config: ()) {
}

@mixin ruf-mat-autocomplete-panel($cls: '.fis-style', $config: ()) {
  .mat-autocomplete-panel#{$cls} {
    // autocomplete panel cannot be styled in a non leaky way since
    // the overlay does not carry the class names put on the element.
    $panel: ruf-theming.ruf-map-get($config, panel, ());

    border-style: ruf-theming.ruf-map-get($panel, border-style, none);
    border-width: ruf-theming.ruf-map-get($panel, border-width, none);
  }
}
