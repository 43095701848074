@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@use '@angular/material' as mat;
@mixin ruf-mat-option-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-option#{$cls} {
    color: ruf-theming.ruf-color($foreground, text);

    &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
      background: ruf-theming.ruf-color($background, hover);
    }

    &.mat-selected.mat-primary, .mat-primary &.mat-selected,
    &.mat-selected.mat-accent, .mat-accent &.mat-selected,
    &.mat-selected.mat-warn, .mat-warn &.mat-selected {
      color: ruf-theming.ruf-color($foreground, text);
    }

    // In multiple mode there is a checkbox to show that the option is selected.
    &.mat-selected:not(.mat-option-multiple) {
      background: ruf-theming.ruf-color($background, hover);
      color: ruf-theming.ruf-color($foreground, text);
    }

    &.mat-active {
      background: ruf-theming.ruf-color($background, hover);
      color: ruf-theming.ruf-color($foreground, text);
    }

    &.mat-option-disabled {
      color: ruf-theming.ruf-color($foreground, hint-text);
    }
  }
}

@mixin ruf-mat-option-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
}
