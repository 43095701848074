@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-input-theme-box($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $error: map.get($theme, error);
  $is-dark: map.get($theme, is-dark);

  $outline-cls: '[boxStyle]'; // selector for boxStyle directive.

  $input-infix: ruf-theming.ruf-map-get($config, input-infix, ());
  $input-hint: ruf-theming.ruf-map-get($config, input-hint, ());
  $input-disabled: ruf-theming.ruf-map-get($config, input-disabled, ());
  $input-error: ruf-theming.ruf-map-get($config, input-error, ());

  $ruf-input-hint-color: ruf-theming.ruf-map-get($input-hint, color, ruf-theming.ruf-color($foreground, text));
  $ruf-label-color: ruf-theming.ruf-map-get($input-error, label-color, ruf-theming.ruf-color($error, default));
  $ruf-error-color: ruf-theming.ruf-map-get($input-error, color, ruf-theming.ruf-color($error, default));

  $ruf-input-keyboard-focused: ruf-theming.ruf-map-get($input-infix, keyboard-focus, ());

  $ruf-input-box: ruf-theming.ruf-map-get($input-infix, input-box, ());

  $ruf-input-text-color: ruf-theming.ruf-map-get($ruf-input-box, color, ruf-theming.ruf-color($foreground, darker-text));
  $ruf-input-password-text-color: ruf-theming.ruf-color($foreground, text);
  $ruf-input-disabled-opacity: ruf-theming.ruf-map-get($input-disabled, opacity, 1);
  $ruf-input-should-float-label-color: ruf-theming.ruf-map-get($input-infix, default-label-color, ruf-theming.ruf-color($foreground, text));
  $ruf-input-error-border-color: ruf-theming.ruf-map-get($input-infix, error-border-color, ruf-theming.ruf-color($error, default));

  $palette-colors: (primary: $primary,
      accent: $accent,
      warn: $warn,
      emphasis: $emphasis);

  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)#{$cls},
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)#{$outline-cls},
  *#{$cls} .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy) {
    &:not(.mat-focused):not(.mat-form-field-invalid):not(.mat-form-field-disabled) {

      .mat-form-field-outline,
      .mat-form-field-outline-thick {
        color: ruf-theming.ruf-map-get($input-infix, default-border-color, ruf-theming.ruf-color($foreground, border));
      }
    }
    // Focus styling when input receive keyboard focus.
    &.cdk-keyboard-focused.mat-focused:not(.mat-form-field-invalid):not(.mat-form-field-disabled) {

      .mat-form-field-outline,
      .mat-form-field-outline-thick {
        color: ruf-theming.ruf-map-get($ruf-input-keyboard-focused, default-border-color, ruf-theming.ruf-color($accent, 500));
      }
    }
    // Removes the blur effect from outline on hover
    &:not(.mat-form-field-disabled) .mat-form-field-flex:hover {
      .mat-form-field-outline {
        transition: none;
      }
    }

    &.mat-focused {

      @each $name, $color in $palette-colors {
        &.mat-#{$name} {
          &:not(.mat-form-field-invalid) .mat-input-element {
            caret-color: ruf-theming.ruf-map-get($input-infix, #{$name}-caret-color, ruf-theming.ruf-color($color));
          }

          .mat-select {
            .mat-select-value {
              color: ruf-theming.ruf-map-get($input-infix, #{$name}-text-color, ruf-theming.ruf-color($color));
            }

            .mat-select-arrow {
              color: ruf-theming.ruf-map-get($input-infix, #{$name}-label-color, ruf-theming.ruf-color($color));
            }
          }

          &:not(.mat-form-field-invalid) {

            .mat-form-field-suffix,
            .mat-form-field-prefix {

              .mat-icon,
              .mat-icon-button::after {
                color: ruf-theming.ruf-map-get($input-infix, #{$name}-text-color, ruf-theming.ruf-color($color));
              }
            }
          }

          &:not(.mat-form-field-invalid) .mat-form-field-label {
            color: ruf-theming.ruf-map-get($input-infix, #{$name}-text-color, ruf-theming.ruf-color($color));
            // Required asterisk color for valid input field
            .mat-placeholder-required {
              color: ruf-theming.ruf-map-get($input-infix, #{$name}-label-color, ruf-theming.ruf-color($color));
            }
          }

          &:not(.mat-form-field-invalid) .mat-form-field-outline-thick {
            color: ruf-theming.ruf-map-get($input-infix, #{$name}-border-color, ruf-theming.ruf-color($color));
          }
        }
      }
    }

    .mat-hint {
      color: $ruf-input-hint-color;
    }

    &.mat-form-field-should-float:not(.mat-focused) {
      .mat-form-field-label-wrapper .mat-form-field-label {
        color: $ruf-input-should-float-label-color;
      }

      &.mat-form-field-disabled:not(.ruf-boxed-form-field-disabled) .mat-form-field-label-wrapper .mat-form-field-label {
        color: ruf-theming.ruf-color($foreground, disabled);
      }
    }

    &:not(.mat-form-field-should-float):not(.mat-focused) {
      .mat-form-field-label-wrapper .mat-form-field-label {
        // label color inside input box
        color: ruf-theming.ruf-map-get($input-infix, can-float-label-color, ruf-theming.ruf-color($foreground, text));
      }
    }


    &.mat-form-field-invalid {

      &.mat-form-field-should-float .mat-form-field-label-wrapper .mat-form-field-label,
      .mat-form-field-label-wrapper .mat-form-field-label,
      .mat-icon {
        color: $ruf-label-color;
      }

      .mat-error {
        color: $ruf-error-color;
      }

      .mat-input-element {
        caret-color: $ruf-error-color;
      }

      .mat-form-field-label .mat-form-field-required-marker,
      .mat-form-field-outline-thick {
        color: $ruf-input-error-border-color;
      }

      &.mat-form-field-can-float:not(.mat-focused) {
        .mat-form-field-label-wrapper .mat-form-field-label {
          color: ruf-theming.ruf-map-get($input-infix, default-text-color, ruf-theming.ruf-color($error, default));
        }
      }
    }

    &.mat-form-field-disabled {
      opacity: $ruf-input-disabled-opacity;
    }



    .mat-input-element {
      color: $ruf-input-text-color;

      &[type=password] {
        color: $ruf-input-password-text-color;
      }
    }


    .mat-icon-button {

      // $foreground link color added on mat-icon-button in _input.theme.scss
      // for hover and focus but for boxed input it is not required.
      .mat-icon:hover,
      .mat-icon:focus {
        color: inherit;
      }
    }

    &.mat-form-field-disabled {

      .mat-form-field-outline,
      .mat-form-field-outline-thick,
      .mat-form-field-label-wrapper .mat-form-field-label,
      .mat-form-field-suffix .mat-icon,
      .mat-form-field-prefix .mat-icon {
        color: ruf-theming.ruf-map-get($input-infix, disabled-border-color, ruf-theming.ruf-color($foreground, input-border));
      }

      .mat-icon-button:hover {
        // disbale halo when input field is disabled.
        background-color: transparent;
      }
    }

    // Readonly input styles
    &.ruf-input-readonly, &[readonly] {
      .mat-form-field-outline,
      .mat-form-field-outline-thick {
        // !important is used to set transparent color for all states e.g. focused, invalid and disabled
        // in readonly mode.
        color: transparent !important;
      }

      // Hide arrow in select box
      .mat-select-arrow {
        border: none;
      }

      .mat-form-field-flex,
      .mat-input-element,
      .mat-select,
      .mat-select-value-text {
        cursor: default;
      }

      // mat-select or html select does not support readonly attribute
      // to show readonly mode for select, user can add `disabled` on mat-select
      // if mat-form-field have readonly and mat-select uses disabled then we should
      // give precedence to readonly mode and change the color of value text and label to text color

      &.mat-form-field.mat-form-field-disabled:not(.mat-form-field-invalid) {
        .mat-select-value-text,
        .mat-form-field-label-wrapper .mat-form-field-label {
          color: ruf-theming.ruf-color($foreground, text);
        }
      }
    }

    // Input with card background
      &.ruf-background-card {
        // because it should not add card background color on entire mat-form-field.
         background-color: inherit;
         &:not(.ruf-boxed-form-field-disabled) {
          .mat-form-field-outline {
            background-color: ruf-theming.ruf-color($background, card);
          }
      }
    }

    // Disabled input with grey background
    &.mat-form-field-disabled {
      &.ruf-boxed-form-field-disabled {
        .mat-form-field-outline {
          background: ruf-theming.ruf-map-get($ruf-input-box, disabled-background-color, map.get(ruf-theming.$ruf-fis-light-neutral-1, 200));
        }
      }
    }
  }

  // Side Label styling
    .ruf-label {
      color: $ruf-input-should-float-label-color;
    }

    .ruf-label-disabled {
      color: ruf-theming.ruf-color($foreground, disabled);
      opacity: $ruf-input-disabled-opacity;
    }

  // Deprecated
  .mat-form-field#{$cls}.ruf-mat-form-field-without-label.mat-form-field-type-mat-input.mat-form-field {
    margin: 0;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin ruf-mat-input-typography-box($config, $cls: '[fisStyle]', $override-config: ()) {
  $outline-cls: '[boxStyle]'; // selector for boxStyle directive.
  $label-config: ruf-theming.ruf-map-get($override-config, label, ());
  $floating-label-config: ruf-theming.ruf-map-get($label-config, floating, ());

  $ruf-input-font-size: ruf-theming.ruf-font-size($config, input);

  .mat-form-field-appearance-outline#{$cls},
  *#{$cls} .mat-form-field-appearance-outline,
  .mat-form-field-appearance-outline#{$outline-cls} {

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      font-size: $ruf-input-font-size;

      .mat-icon {
        font-size: $ruf-input-font-size;
      }
    }

    .mat-hint {
      font-size: ruf-theming.ruf-font-size($config, caption);
    }

    .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
      font-size: ruf-theming.ruf-font-size($config, caption);
    }

    .mat-input-element {
      font-size: $ruf-input-font-size;
      @include ruf-theming.ruf-input-placeholder {
        // display-4 makes the placeholder text lighter
        font-weight: ruf-theming.ruf-font-weight($config, display-4);
      }
    }

    &.mat-form-field-should-float {
      .mat-form-field-label-wrapper .mat-form-field-label {
        font-size: ruf-theming.ruf-map-get($floating-label-config, font-size, ruf-theming.ruf-font-size($config, caption-2));
        font-weight: ruf-theming.ruf-map-get($floating-label-config, font-weight, ruf-theming.ruf-font-size($config, caption-2));
        line-height: ruf-theming.ruf-line-height($config, caption-2);
      }
    }
  }

  .ruf-label {
    font-size: ruf-theming.ruf-font-size($config, caption);
    font-weight: ruf-theming.ruf-font-weight($config, bold);
  }
  // Side Label styling
  .ruf-boxed-input-wrapper {
    .ruf-label {
      font-size: ruf-theming.ruf-font-size($config, input);
    }
  }

  *:not(.mat-form-field-appearance-outline) .mat-hint#{$cls} {
    font-size: ruf-theming.ruf-font-size($config, caption);
  }
}
