@use 'sass:map';
@use '@ruf/theming' as ruf-theming;


@mixin ruf-mat-stepper-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $stepper-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $stepper-icon-selected-config: ruf-theming.ruf-map-get($stepper-icon-config, selected, ());
  $stepper-hover-config: ruf-theming.ruf-map-get($config, hover, ());

  $icon-background-color: ruf-theming.ruf-map-get($stepper-icon-config, background-color, null);
  $icon-border-color: ruf-theming.ruf-map-get($stepper-icon-config, border-color, null);
  $icon-color: ruf-theming.ruf-map-get($stepper-icon-config, color, null);

  $icon-selected-background-color: ruf-theming.ruf-map-get($stepper-icon-selected-config, background-color, null);
  $icon-selected-color: ruf-theming.ruf-map-get($stepper-icon-selected-config, color, null);

  $hover-background-color: ruf-theming.ruf-map-get($stepper-hover-config, background-color, null);
  $line-config: ruf-theming.ruf-map-get($config, line, ());
  $line-border-color: ruf-theming.ruf-map-get($line-config, border-color, ruf-theming.ruf-color($foreground, divider));

  .mat-stepper-horizontal#{$cls}, .mat-stepper-vertical#{$cls} {
    .mat-step-header {
      .mat-step-icon {
        background-color: $icon-background-color;
        border-color: $icon-border-color;
        color: $icon-color;
      }
      .mat-step-icon.mat-step-icon-selected {
        background-color: $icon-selected-background-color;
        color: $icon-selected-color;
      }
      &:hover,
      &.cdk-focused,
      &.cdk-program-focused,
      &.cdk-keyboard-focused {
        background-color: $hover-background-color;
        .mat-step-icon:not(.mat-step-icon-selected) {
          background: $hover-background-color;
        }
      }
      &[aria-disabled='true'] {
        &:hover,
        &.cdk-focused,
        &.cdk-program-focused,
        &.cdk-keyboard-focused {
          background-color: transparent;
          cursor: auto;
        }
      }
      &.cdk-keyboard-focused {
        @extend .ruf-keyboard-focused !optional;
      }
    }

    .mat-stepper-horizontal-line {
      border-top-color: $line-border-color;
    }

    .mat-stepper-vertical-line::before {
      border-left-color: $line-border-color;
    }
  }
}


@mixin ruf-mat-stepper-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  $stepper-icon-config: ruf-theming.ruf-map-get($override-config, icon, ());
  $stepper-label-config: ruf-theming.ruf-map-get($override-config, label, ());

  $label-font-weight: ruf-theming.ruf-map-get($stepper-label-config, font-weight, ruf-theming.ruf-font-weight($config, normal-weight));
  $icon-font-weight: ruf-theming.ruf-map-get($stepper-icon-config, font-weight, ruf-theming.ruf-font-weight($config, normal-weight));

  .mat-stepper-horizontal#{$cls}, .mat-stepper-vertical#{$cls} {
    .mat-step-header {
      .mat-step-label {
        font-size: ruf-theming.ruf-font-size($config, subheading-1);
        font-weight: $label-font-weight;
      }
      .mat-step-icon-content {
        font-family: ruf-theming.ruf-font-family($config, subheading-1);
        font-weight: $icon-font-weight;
      }
    }
  }
}
