@use 'sass:map';
$ruf-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px
) !default;

@function ruf-breakpoint($name) {
  @return map.get($ruf-breakpoints, $name);
}

$ruf-mqs: (
  xs: '(min-width: #{ruf-breakpoint(xs)}) and (max-width: #{ruf-breakpoint(sm) - 1px})',
  gt-xs: '(min-width: #{ruf-breakpoint(sm)})',
  sm: '(min-width: #{ruf-breakpoint(sm)}) and (max-width: #{ruf-breakpoint(md) - 1px})',
  lt-sm: '(max-width: #{ruf-breakpoint(sm) - 1px})',
  gt-sm: '(min-width: #{ruf-breakpoint(md)})',
  md: '(min-width: #{ruf-breakpoint(md)}) and (max-width: #{ruf-breakpoint(lg) - 1px})',
  md-landscape: '(min-width: #{ruf-breakpoint(md)}) and (max-width: #{ruf-breakpoint(lg) - 1px}) and (orientation: landscape)',
  lt-md: '(max-width: #{ruf-breakpoint(md) - 1px})',
  gt-md: '(min-width: #{ruf-breakpoint(lg)})',
  lg: '(min-width: #{ruf-breakpoint(lg)}) and (max-width: #{ruf-breakpoint(xl) - 1px})',
  lt-lg: '(max-width: #{ruf-breakpoint(lg) - 1px})',
  gt-lg: '(min-width: #{ruf-breakpoint(xl)})',
  xl: '(min-width: #{ruf-breakpoint(xl)})',
  lt-xl: '(max-width: #{ruf-breakpoint(xl) - 1px})',
  gt-xl: '(min-width: #{ruf-breakpoint(xl)})',
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
  print: 'print'
);

@mixin if-mq($name) {
  @media #{map.get($ruf-mqs, $name)} { @content; }
}

@mixin ruf-mqs() {
  @each $name, $media in $ruf-mqs {
    @content($name, $media);
  }
}
