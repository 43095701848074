@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-divider($cls: '[fisStyle]', $config: ()) {
  .mat-divider#{$cls} {
    &.mat-divider-horizontal {
      position: relative;
    }
    @include ruf-mat-divider-margin();
  }
}

@mixin ruf-mat-divider-margin() {
  $left-right-margin: 16px;
  $offset: ruf-theming.ruf-to-rem($left-right-margin * 2);
  &.ruf-divider-margin {
    margin: ruf-theming.ruf-to-rem(4px) ruf-theming.ruf-to-rem(16px);
    width: calc(100% - $offset);
  }
  &.ruf-divider-horizontal-margin {
    margin: ruf-theming.ruf-to-rem(4px) ruf-theming.ruf-to-rem(0px);
  }
}
