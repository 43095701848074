@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-menu-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $menu-item-icon: ruf-theming.ruf-map-get($menu-item, icon, ());

  .mat-menu-item#{$cls} {
    &:not([disabled]) {
      .mat-icon {
        color: ruf-theming.ruf-map-get($menu-item-icon, color, ruf-theming.ruf-color($foreground, text));
      }
    }

    &[disabled] {
      .mat-icon, .mat-icon-no-color,
      .mat-menu-item-submenu-trigger::after {
        color: ruf-theming.ruf-color($foreground, disabled);
      }
    }
  }
}

@mixin ruf-mat-menu-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
}

@mixin ruf-mat-menu-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $foreground: map.get($theme, foreground);

  $menu-panel: ruf-theming.ruf-map-get($config, menu-panel, ());
  $menu-item: ruf-theming.ruf-map-get($config, menu-item, ());
  $menu-item-icon: ruf-theming.ruf-map-get($menu-item, icon, ());

  // Gold specific
  $box-shadow: 1px 2px 3px 0px ruf-theming.ruf-color($foreground, shadow);

  .mat-menu-panel#{$cls} {
    border-color: ruf-theming.ruf-map-get($menu-panel, border-color, ruf-theming.ruf-color($foreground, subtle-divider));

    &[class*=mat-elevation-z] {
      box-shadow: ruf-theming.ruf-map-get($menu-panel, box-shadow, $box-shadow);
    }

    .mat-menu-item {
      &:not([disabled]) {
        .mat-icon {
          color: ruf-theming.ruf-map-get($menu-item-icon, color, ruf-theming.ruf-color($foreground, text));
        }
      }

      &[disabled] {
        .mat-icon, .mat-icon-no-color,
        .mat-menu-item-submenu-trigger::after {
          color: ruf-theming.ruf-color($foreground, disabled);
        }
      }
    }
  }
}

@mixin ruf-mat-menu-panel-typography($config, $cls: '.fis-style') {
  .mat-menu-panel#{$cls} {
    .mat-menu-item {
      font-size: ruf-theming.ruf-font-size($config, menu-children);
    }
  }
}
