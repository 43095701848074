@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-tree-bidi($nested-node-padding, $dir1: 'left', $dir2: 'right', $density: 0) {
  $root-node-padding: 18px;

  .ruf-tree-vertical-dotted-line .mat-nested-tree-node:not(:last-child) > .mat-tree-node,
  .ruf-tree-vertical-dotted-line .mat-nested-tree-node:not(:last-child) > .ruf-tree-vertical-dotted-line {
    border-#{$dir1}-width: ruf-theming.ruf-to-rem(1px);
    border-#{$dir1}-style: dotted;
  }

  .ruf-tree-vertical-dotted-line .mat-nested-tree-node:last-child > .mat-tree-node::before {
    background: transparent;
    border-style: dotted;
    border-width: 0 0 0 ruf-theming.ruf-to-rem(1px);
    content: '';
    height: calc(50%);
    position: relative;
    top: -25%;
    width: ruf-theming.ruf-to-rem(0px);
  }

  .ruf-tree-vertical-dotted-line .mat-nested-tree-node:not(:last-child) > .mat-tree-node,
  .ruf-tree-vertical-dotted-line .mat-nested-tree-node:not(:last-child) > .ruf-tree-vertical-dotted-line {
    border-#{$dir2}-width: ruf-theming.ruf-to-rem(0px);
    border-#{$dir2}-style: none;
  }

  .ruf-tree-vertical-dotted-line {
    padding-#{$dir1}: $nested-node-padding;
  }

  > .mat-nested-tree-node > .ruf-tree-vertical-dotted-line {
    border-#{$dir1}: none;
    padding-#{$dir1}: if($density==0, $root-node-padding, $root-node-padding + (2 * ($density - 1)));
  }
}

@mixin ruf-mat-tree-density($cls: '[fisStyle]', $config: (), $density: 0) {
  $tree-node: ruf-theming.ruf-map-get($config, node, ());
  $height: ruf-theming.ruf-map-get($tree-node, height, 48px);
  $density-interval: 4px;
  $ruf-mat-tree-node-side-padding: 40px !default;

  $ruf-mat-tree-nested-node-side-padding: $ruf-mat-tree-node-side-padding + 18px !default;

  $height: if($density==0, $height, $height + ($density-interval + 6px) * $density);

  $ruf-mat-tree-nested-node-side-padding: if($density==0, $ruf-mat-tree-nested-node-side-padding,
      $ruf-mat-tree-nested-node-side-padding + (2 * ($density - 1)));

  .mat-tree#{$cls} {
    .mat-tree-node {
      height: $height;
      min-height: $height;
    }

    @include ruf-mat-tree-bidi($ruf-mat-tree-nested-node-side-padding, $density: $density);

    [dir='rtl'] & {
      @include ruf-mat-tree-bidi($ruf-mat-tree-nested-node-side-padding, $dir1: 'right', $dir2: 'left', $density: $density);
    }

    .ruf-tree-vertical-dotted-line .ruf-tree-horizontal-dotted-line {
      border-bottom-style: dotted;
      border-bottom-width: ruf-theming.ruf-to-rem(1px);
      height: ruf-theming.ruf-to-rem(0px);
      left: ruf-theming.ruf-to-rem(-1px);
      position: relative;
      top: ruf-theming.ruf-to-rem(0px);
      width: $ruf-mat-tree-node-side-padding;
    }

    [dir='rtl'] & {
      .ruf-tree-vertical-dotted-line .ruf-tree-horizontal-dotted-line {
        left: ruf-theming.ruf-to-rem(1px);
      }

      .ruf-tree-vertical-dotted-line .mat-nested-tree-node:last-child > .mat-tree-node::before {
        left: ruf-theming.ruf-to-rem(0.5px);
      }
    }
  }
}



@mixin ruf-mat-tree($cls: '[fisStyle]', $config: ()) {

  // stylelint-disable block-no-empty
  .mat-tree#{$cls} {

  }
}
