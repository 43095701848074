@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
// stylelint-disable block-no-empty
@mixin ruf-mat-autocomplete-theme($theme, $cls: '[fisStyle]', $config: ()) {

}

@mixin ruf-mat-autocomplete-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $panel: ruf-theming.ruf-map-get($config, panel, ());

  // Since panels are overlay elements, they can only have a class not attribute
  .mat-autocomplete-panel#{$cls} {
    background: ruf-theming.ruf-color($background, panel);
    border-color: ruf-theming.ruf-map-get($panel, border-color, none);
    box-shadow: ruf-theming.ruf-map-get($panel, box-shadow, null); // null will apply the box-shadow provided by material
    color: ruf-theming.ruf-color($foreground, text);

    .mat-option {
      // Selected options in autocompletes should not be gray, but we
      // only want to override the background for selected options if
      // they are *not* in hover or focus state. This change has to be
      // made here because base option styles are shared between the
      // autocomplete and the select.
      &.mat-selected:not(.mat-active):not(:hover) {
        background: ruf-theming.ruf-color($background, panel);
        color: ruf-theming.ruf-color($foreground, text);
      }
    }
  }
}

@mixin ruf-mat-autocomplete-typography($config, $cls: '[fisStyle]') {

}

@mixin ruf-mat-autocomplete-panel-typography($config, $cls: '.fis-style') {

}
