@use '@ruf/theming' as ruf-theming;
@mixin ruf-mat-chips-density($cls: '[fisStyle]', $config: (), $density: 0) {
  $chip-icon-button-config: ruf-theming.ruf-map-get($config, icon-button, ());
  $density-interval: 4px;
  $chip-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $height: ruf-theming.ruf-map-get($config, height, 32px);
  $height: $height + $density-interval * $density;
  $icon-remove-font-size: ruf-theming.ruf-map-get($chip-icon-config, font-size, ruf-theming.ruf-to-rem(18px));
  $icon-button-height: ruf-theming.ruf-map-get($chip-icon-button-config, height, ruf-theming.ruf-to-rem(32px));
  $icon-button-width: ruf-theming.ruf-map-get($chip-icon-button-config, width, ruf-theming.ruf-to-rem(32px));

  $icon-button-height: if($density == 0, $icon-button-height, $height);
  $icon-button-width: if($density == 0, $icon-button-width, $height);
  $icon-font-size: $icon-button-height - 2px;

  $icon-vertical-align: ruf-theming.ruf-map-get($chip-icon-button-config, vertical-align, middle);

  .mat-chip-list#{$cls} {
    .mat-chip {
      height: $height;
      max-width: calc(100% - 36px);
      min-height: $height;

      .mat-icon-button#{$cls},
      #{$cls} .mat-icon-button {
        height: $icon-button-height !important;
        line-height: $icon-button-height;
        width: $icon-button-width !important;
        .mat-button-wrapper {
          height: $icon-button-height;
          line-height: $icon-button-height;
          width: $icon-button-width;
        }
        .mat-chip-remove, &.mat-chip-remove .mat-icon {
          font-size: $icon-remove-font-size;
          height: $icon-button-height;
          line-height: $icon-button-height;
          vertical-align: $icon-vertical-align;
          width: $icon-button-width;
          &::before {
            position: relative;
          }
        }
      }
      >.mat-icon#{$cls} {
        font-size: $icon-font-size;
        height: $icon-button-height !important;
        line-height: $icon-button-height;
        width: $icon-button-width !important;
      }
    }
  }

  .mat-form-field-should-float .mat-form-field-infix  .mat-chip-list#{$cls} {
    // Overriding the default margins by Material to center align inside input box
    .mat-chip-list-wrapper {
      margin-top: ruf-theming.ruf-to-rem(0px);
    }
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-chips($cls: '[fisStyle]', $config: ()) {
  // stylelint-disable block-no-empty

  $chip-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $chip-disabled-config: ruf-theming.ruf-map-get($config, disabled, ());
  $chip-icon-button-config: ruf-theming.ruf-map-get($config, icon-button, ());

  $width: ruf-theming.ruf-map-get($config, width, auto);

  $border-width: ruf-theming.ruf-map-get($config, border-width, 0px);
  $border-radius: ruf-theming.ruf-map-get($config, border-radius, null);
  $padding-right: ruf-theming.ruf-map-get($config, padding-right, ruf-theming.ruf-to-rem(0px));

  $icon-margin: ruf-theming.ruf-map-get($chip-icon-config, margin, ruf-theming.ruf-to-rem(2px));
  $icon-margin-right: ruf-theming.ruf-map-get($chip-icon-config, margin, ruf-theming.ruf-to-rem(0px));


  $icon-button-margin-left: ruf-theming.ruf-map-get($chip-icon-button-config, margin-left, ruf-theming.ruf-to-rem(8px));
  $icon-button-margin-right: ruf-theming.ruf-map-get($chip-icon-button-config, margin-right, ruf-theming.ruf-to-rem(0px));
  $icon-button-padding: ruf-theming.ruf-map-get($chip-icon-button-config, padding, ruf-theming.ruf-to-rem(0px));


  $disabled-border-width: ruf-theming.ruf-map-get($chip-disabled-config, border-width, auto);
  $outline-width: ruf-theming.ruf-map-get($config, outline-width, 1px);
  $mat-chip-list: ruf-theming.ruf-map-get($config, mat-chip-list, ());
  $mat-chip-list-padding-left: ruf-theming.ruf-map-get($mat-chip-list, padding-left, 0);
  $mat-chip-list-padding-right: ruf-theming.ruf-map-get($mat-chip-list, padding-right, 0);

  .mat-chip-list#{$cls} {

    .mat-chip-list-wrapper {
      padding-left: $mat-chip-list-padding-left;
      padding-right: $mat-chip-list-padding-right;
    }

    .mat-chip {
      border: solid $border-width;
      border-radius: $border-radius;

      &.cdk-keyboard-focused {
        outline-width: $outline-width;
      }

      .mat-icon-button {
        margin-left: $icon-button-margin-left;
        margin-right: $icon-button-margin-right;
        padding: $icon-button-padding;
      }

      .ruf-overflow-chip {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mat-chip-remove {
        -webkit-appearance: none;
        margin: $icon-margin;
        margin-right: $icon-margin-right;

        &:not([disabled]).cdk-keyboard-focused {
          .mat-icon {
            left: ruf-theming.ruf-map-get($chip-icon-button-config, icon-focus-top, ruf-theming.ruf-to-rem(-1px));
            position: relative;
            top: ruf-theming.ruf-map-get($chip-icon-button-config, icon-focus-top, ruf-theming.ruf-to-rem(-1px));
          }
        }
      }

      &.ruf-tagged-chip {
        border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(1px));
      }

      &.mat-chip-disabled {
        border-width: $disabled-border-width;
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }
    .mat-error {
      padding-left: ruf-theming.ruf-to-rem(10px);
    }
    .mat-standard-chip.mat-chip-with-trailing-icon {
      padding-right: $padding-right;
    }
  }
}
