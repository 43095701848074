@mixin ruf-mat-tooltip-panel($cls: '.fis-style', $config: ()) {
  .mat-tooltip-panel {
    .mat-tooltip#{$cls} {
      overflow: visible;
      text-overflow: inherit;
      text-transform: capitalize;
      word-break: break-all;
    }
  }
}
