@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-select($cls: '[fisStyle]', $config: ()) {
  $chevron-icon: ruf-theming.ruf-map-get($config, chevron-icon, ());
  $chevron-icon-margin-horizontal: ruf-theming.ruf-map-get($chevron-icon, margin-horizontal, ruf-theming.ruf-to-rem(4px));
  $chevron-icon-margin-vertical: ruf-theming.ruf-map-get($chevron-icon, margin-vertical, ruf-theming.ruf-to-rem(0px));
  .mat-select#{$cls} {
    margin-top: ruf-theming.ruf-to-rem(-1px);

    .ruf-boxed-form-field-disabled {
      .mat-select.mat-select-disabled {
       span.mat-select-placeholder {
          opacity: inherit;
        }
      }
    }

    &.mat-select-disabled .mat-select-trigger {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }

    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        border-bottom: ruf-theming.ruf-map-get($chevron-icon, border-bottom, none);
        border-left: ruf-theming.ruf-map-get($chevron-icon, border-left, 5px solid transparent);
        border-right: ruf-theming.ruf-map-get($chevron-icon, border-right, 5px solid transparent);
        border-top: ruf-theming.ruf-map-get($chevron-icon, border-top, 5px solid);
        height: ruf-theming.ruf-map-get($chevron-icon, height, ruf-theming.ruf-to-rem(0px));
        margin: $chevron-icon-margin-vertical $chevron-icon-margin-horizontal;
        transform: ruf-theming.ruf-map-get($chevron-icon, transform, rotate(0deg));
        width: ruf-theming.ruf-map-get($chevron-icon, width, ruf-theming.ruf-to-rem(0px));
      }
		}

    // IE specific issue with material library - https://github.com/angular/components/issues/22980
    // TODO: remove this style once ruf drops support for IE
    // IE only media query
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .mat-select-trigger {
          float: right;
      }
    }
  }

  .mat-form-field-type-mat-native-select#{$cls} {
    .mat-form-field-infix::after {
      border-bottom: ruf-theming.ruf-map-get($chevron-icon, border-bottom, none);
      border-left: ruf-theming.ruf-map-get($chevron-icon, border-left, 5px solid transparent);
      border-right: ruf-theming.ruf-map-get($chevron-icon, border-right, 5px solid transparent);
      border-top: ruf-theming.ruf-map-get($chevron-icon, border-top, 5px solid);
      height: ruf-theming.ruf-map-get($chevron-icon, height, ruf-theming.ruf-to-rem(0px));
      right: 2%;
      top: 42%;
      transform: ruf-theming.ruf-map-get($chevron-icon, transform, rotate(0deg));
      width: ruf-theming.ruf-map-get($chevron-icon, width, ruf-theming.ruf-to-rem(0px));
    }
  }
}

@mixin ruf-mat-select-panel($cls: '.fis-style', $config: ()) {
  $select-option: ruf-theming.ruf-map-get($config, select-option, ());
  $select-chevron: ruf-theming.ruf-map-get($config, chevron-icon, ());
  $option-height: ruf-theming.ruf-map-get($select-option, height, ruf-theming.ruf-to-rem(48px));
  $option-padding-left: ruf-theming.ruf-map-get($select-option, padding-left, ruf-theming.ruf-to-rem(16px));
  $option-padding-right: ruf-theming.ruf-map-get($select-option, padding-left, ruf-theming.ruf-to-rem(16px));
  $select-border-width: ruf-theming.ruf-map-get($select-option, border-width, ruf-theming.ruf-to-rem(0px));
  $select-border-style: ruf-theming.ruf-map-get($select-option, border-style, solid);

   .mat-select-panel-wrap .mat-select-panel#{$cls}, .mat-select-panel-wrap .mat-select-panel {
     border-style: $select-border-style;
     border-width: $select-border-width;
     /* Added this property to fix in IE so that mat-option can take available width */
     display: inline-block;
    .mat-option {
      height: $option-height;
      min-height: $option-height;
      padding-left: $option-padding-left;
      padding-right: $option-padding-right;

      &.mat-option-disabled {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }
    .mat-option.ruf-select-multiline {
      height: auto;
      min-height: $option-height;
      .mat-option-text {
        white-space: normal;
      }
    }
   }
}
