@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ruf-mat-badge-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $success: map.get($theme, success);
  $error: map.get($theme, error);
  $info: map.get($theme, info);

  $theme-colors: ( // primary and accent colors handled by material
    warn: $warn,
    emphasis: $emphasis,
    success: $success,
    error: $error,
    info: $info
  );

    .mat-badge#{$cls} {
      @include  _ruf-mat-badge-background-color($theme-colors);
    }
 }

// stylelint-disable block-no-empty
@mixin ruf-mat-badge-typography($config, $cls: '[fisStyle]') {

}


@mixin _ruf-mat-badge-background-color($theme-colors) {
  @each $name, $color in $theme-colors {
      &.mat-badge-#{$name} {
          .mat-badge-content {
              @if( meta.type-of(map.get($color, $name)) == color ) {
                // success, info and error to be taken from background palette.
                background: ruf-theming.ruf-color($color, $name);
              } @else {
                 // For emphasis and warn
                 background: ruf-theming.ruf-color($color, default);
              }
          }
      }
  }
}
