@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it
// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ruf-mat-checkbox-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $disabled-checkbox: ruf-theming.ruf-map-get($config, disabled, ());

  $disabled-border-color: ruf-theming.ruf-map-get($disabled-checkbox, color, null);
  $disabled-border-opacity: ruf-theming.ruf-map-get($disabled-checkbox, opacity, 0.5);
  $checked-ripple-opacity: ruf-theming.ruf-map-get($config, ripple-opacity, 0.1);
  $unchecked-ripple-opacity: ruf-theming.ruf-map-get($config, ripple-opacity, 0.05);

  $border-color: ruf-theming.ruf-map-get($config, border-color, ruf-theming.ruf-color($foreground, border));

  $unchecked-ripple-color: ruf-theming.ruf-map-get($config, unchecked-ripple-color, ruf-theming.ruf-color($foreground, base));
  $checked-ripple-color-weight: ruf-theming.ruf-map-get($config, checked-ripple-color-weight, 300);

  $theme-colors: (
      primary: $primary,
      accent: $accent,
      warn: $warn
  );

  .mat-checkbox#{$cls} {

    &.cdk-keyboard-focused {  
      .mat-checkbox-ripple {
        border: ruf-theming.ruf-to-rem(2px) solid ruf-theming.ruf-color($emphasis);
        border-radius: 50%;
      }
    }
    .mat-checkbox-inner-container {
      [dir="ltr"] & {
        margin-right: ruf-theming.ruf-to-rem(10px);
      }
      [dir="rtl"] & {
        margin-left: ruf-theming.ruf-to-rem(10px);
      }
    }

    &.mat-checkbox-checked.mat-checkbox-disabled {
      .mat-checkbox-frame {
        border-color: transparent;
      }
    }

    &.mat-checkbox-disabled:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: $disabled-border-color;
        opacity: $disabled-border-opacity;
      }
      .mat-checkbox-label {
        color: ruf-theming.ruf-color($foreground, text);
      }
    }
    &:not(.mat-checkbox-disabled):not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: $border-color;
      }
    }

    &.cdk-focused,
    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    .mat-checkbox-inner-container:hover {
      .mat-checkbox-persistent-ripple {
        opacity: $checked-ripple-opacity;
      }
    }

    &:not(.mat-checkbox-checked):not(.mat-checkbox-disabled):not(.mat-checkbox-indeterminate) {
    &.mat-primary, &.mat-accent, &.mat-warn {
      .mat-ripple-element {
        background: $unchecked-ripple-color;
      }
      .mat-checkbox-inner-container:hover {
        .mat-checkbox-persistent-ripple {
          opacity: $unchecked-ripple-opacity;
        }
      }
    }
  }

  .mat-checkbox-inner-container {
    .mat-checkbox-ripple {
      z-index: 0;
    }
  }

  .mat-checkbox-label {
    z-index: 1;
  }

    @each $name, $color in $theme-colors {
      &.mat-#{$name} {
        &.mat-checkbox-checked.mat-checkbox-disabled, &.mat-checkbox-indeterminate.mat-checkbox-disabled {
          .mat-checkbox-background {
            background-color: ruf-theming.ruf-color($color, default);
            opacity: 0.5;
          }
        }

        &.mat-checkbox-checked .mat-checkbox-inner-container .mat-ripple-element,
        &.mat-checkbox-indeterminate .mat-checkbox-inner-container .mat-ripple-element {
          background: ruf-theming.ruf-color($color, $checked-ripple-color-weight);
        }

        .mat-checkbox-mixedmark {
          background-color: ruf-theming.ruf-color($color, default-contrast);
        }

        .mat-checkbox-checkmark {
          fill: ruf-theming.ruf-color($color, default-contrast);
        }
        .mat-checkbox-checkmark-path {
          // !important is needed here because a stroke must be set as an
          // attribute on the SVG in order for line animation to work properly.
          stroke: ruf-theming.ruf-color($color, default-contrast) !important;
        }
      }
    }
  }
}

@mixin ruf-mat-checkbox-typography($config, $cls: '[fisStyle]') {
  // stylelint-disable block-no-empty
  .mat-checkbox#{$cls} {
    .mat-checkbox-label {
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
    }
  }
}
