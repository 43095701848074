@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-datatable-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $main-colors: primary, accent, warn, emphasis;
  $background-colors: error, success, info;

  $header-config: ruf-theming.ruf-map-get($config, mat-header-row);
  $row-config: ruf-theming.ruf-map-get($config, mat-row);
  $mat-sort-header-arrow-config: ruf-theming.ruf-map-get($header-config, mat-sort-header-arrow, ());
  $mat-sort-header-arrow-color: ruf-theming.ruf-map-get($mat-sort-header-arrow-config, color, ruf-theming.ruf-color($foreground, secondary-text));
  $striped-row-config: ruf-theming.ruf-map-get($row-config, striped-row, ());
  $striped-even-row-background-color: ruf-theming.ruf-map-get(
                                        $striped-row-config,
                                        even-row-background-color,
                                        ruf-theming.ruf-color($background, alternate-row)
                                      );
  $striped-odd-row-background-color: ruf-theming.ruf-map-get($striped-row-config, odd-row-background-color, ruf-theming.ruf-color($background, row));
  $default-row-config: ruf-theming.ruf-map-get($row-config, default-row, ());
  $default-cell-border-bottom-color: ruf-theming.ruf-map-get(
                                      $default-row-config,
                                      cell-border-bottom-color,
                                      ruf-theming.ruf-color($foreground, divider)
                                    );

  .mat-table#{$cls} {

    @include _ruf-mat-table-border-color($theme, $main-colors);
    @include _ruf-mat-table-border-color($background, $background-colors);
    border-bottom-color: ruf-theming.ruf-map-get($config, border-bottom-color, ruf-theming.ruf-color($foreground, border));

    .mat-header-row {
      background: ruf-theming.ruf-map-get($header-config, background-color, ruf-theming.ruf-color($background, header-row));

      .mat-header-cell {
        border-bottom-color: ruf-theming.ruf-map-get($header-config, cell-border-bottom-color, ruf-theming.ruf-color($primary));
        color: ruf-theming.ruf-map-get($header-config, cell-text-color, ruf-theming.ruf-color($primary));

        &:hover {
          &:not(.mat-table-sticky).mat-sort-header {
            background-color: ruf-theming.ruf-map-get($header-config, hover, ruf-theming.ruf-color($background, hover));
          }
        }

        .mat-sort-header-sorted .mat-sort-header-arrow {
          color: $mat-sort-header-arrow-color;
        }

        &.mat-sort-header.mat-sort-header-disabled {
          cursor: ruf-theming.$ruf-disabled-cursor;

          .mat-sort-header-container {
            cursor: ruf-theming.$ruf-disabled-cursor;
          }

          &:hover {
            background-color: transparent;
          }

          .mat-sort-header-arrow {
            color: ruf-theming.ruf-color($foreground, disabled-button);
          }
        }

        &.cdk-keyboard-focused {
          @extend .ruf-keyboard-focused !optional;
        }
      }
    }

    .mat-row {
      @include _ruf-mat-table-active-row($row-config, $theme);
      &.ruf-detail-row:not(.ruf-row-halo):hover {
        background-color: transparent;
        cursor: auto;
      }

      &.ruf-row-halo {
        &:hover {
          cursor: pointer;
          button.mat-icon-button {
            background: ruf-theming.ruf-map-get($row-config, icon-halo-color, rgba(0, 0, 0, 0.21));
          }

          .mat-checkbox .mat-ripple-element {
            // background color is already set by material,
            // they just toggle the opacity to show the halo effect.
            opacity: ruf-theming.ruf-map-get($row-config, checkbox-opacity, 0.21);
          }
        }
        &.ruf-detail-row:hover {
          cursor: auto;
        }
      }

      .mat-cell {
        border-bottom-color: $default-cell-border-bottom-color;
      }
  }

  .mat-row:not(.ruf-row-halo).ruf-hover-interaction {
    &:hover, &:active {
      background-color: ruf-theming.ruf-map-get($row-config, hover, ruf-theming.ruf-color($background, hover));
      cursor: pointer;
    }
    .mat-checkbox {
      &:hover, &:active {
        .mat-checkbox-ripple {
          display: none;
        }
      }
    }
    button {
      &:hover, &:active {
        .mat-button-focus-overlay {
          display: none;
        }
      }
    }
  }

  .mat-row.ruf-row-halo {
    button {
      &:hover, &:active {
        .mat-button-focus-overlay {
          display: none;
        }
      }
    }
  }

    &.ruf-striped-rows,  &.striped-rows {
      &:not(.ruf-expandable-rows) {
        .mat-row:not(.ruf-row-active):not(.ruf-row-active-pale):nth-child(even) {
          background-color: $striped-even-row-background-color;
        }

        .mat-row:not(.ruf-row-active):not(.ruf-row-active-pale):nth-child(odd) {
          background-color: $striped-odd-row-background-color;
        }
      }

      &.ruf-expandable-rows {
        .mat-row:nth-child(3),
        .mat-row:nth-child(4n+3),
        .mat-row:nth-child(4n+4) {
          background-color: $striped-even-row-background-color;
        }
        .mat-row:nth-child(2),
        .mat-row:nth-child(4n+1),
        .mat-row:nth-child(4n+2) {
          background-color: $striped-odd-row-background-color;
        }
      }
    }

    &.ruf-grey-header,  &.grey-header {
      $grey-header: ruf-theming.ruf-map-get($header-config, grey-header);
      .mat-header-row {
        .mat-header-cell {
          border-bottom-color: ruf-theming.ruf-map-get($grey-header, border-bottom-color, ruf-theming.ruf-color($foreground, divider));
          color: ruf-theming.ruf-map-get($grey-header, cell-text-color, ruf-theming.ruf-color($foreground, text));
        }
      }
    }


    &[row-border=true] {
      .mat-row {
        .mat-cell:first-child {
          $row-border-color: ruf-theming.ruf-map-get($row-config, border-color, ruf-theming.ruf-color($primary));
          border-left-color: $row-border-color;

          [dir='rtl'] & {
            border-right-color: $row-border-color;
          }
        }
      }
    }

    &.ruf-card-rows {
      $card-row: ruf-theming.ruf-map-get($row-config, card-row);
      background: ruf-theming.ruf-map-get($card-row, table-background, ruf-theming.ruf-color($background, card));

      .mat-row {
        @include _ruf-mat-table-active-row($row-config, $theme);
        background: ruf-theming.ruf-map-get($card-row, row-background, inherit);
        box-shadow: ruf-theming.ruf-map-get(
          $card-row,
          box-shadow,
         ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(3px) ruf-theming.ruf-to-rem(6px) ruf-theming.ruf-to-rem(0px) rgba(0, 0, 0, 0.25)
        );

        // ruf-detail-row should not change background color on hover to transparent
        &.ruf-detail-row:not(.ruf-row-halo):hover {
          background: ruf-theming.ruf-map-get($card-row, row-background, inherit);
        }
      }

      .mat-header-row {
        background: ruf-theming.ruf-map-get($card-row, header-background, ruf-theming.ruf-color($background, header-row));
      }

      th.mat-header-cell {
        border-bottom: none;
      }

      .mat-row,
      .mat-header-row,
      .mat-footer-row,
      td.mat-footer-cell,
      td.mat-cell {
        border-bottom-color: ruf-theming.ruf-map-get($card-row, row-bottom-border-color, ruf-theming.ruf-color($background, header-row));
      }

      .mat-row:not(.ruf-row-halo).ruf-hover-interaction:hover, .mat-row:not(.ruf-row-halo).ruf-hover-interaction:active {
        background-color: ruf-theming.ruf-map-get($card-row, hover, ruf-theming.ruf-color($background, hover));
        td.mat-cell {
          cursor: pointer;
        }
      }
      .mat-row.ruf-row-halo:not(.ruf-detail-row):hover, .mat-row.ruf-row-halo:not(.ruf-detail-row):active {
        td.mat-cell {
          cursor: pointer;
        }
      }
   }
  }
}


@mixin ruf-mat-table-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  $header-config: ruf-theming.ruf-map-get($override-config, mat-header-row, ());
  $row-config: ruf-theming.ruf-map-get($override-config, mat-row, ());

  .mat-table#{$cls} {
    .mat-header-cell {
      font-family: ruf-theming.ruf-font-family($config, title);
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
      font-weight: ruf-theming.ruf-font-weight($config, bold);
      line-height: ruf-theming.ruf-map-get($header-config, line-height, inherit);
    }

    .mat-row {
      .mat-cell {
        font-size: ruf-theming.ruf-map-get($row-config, font-size, ruf-theming.ruf-font-size($config, caption-2));
        font-weight: ruf-theming.ruf-font-weight($config, body-1);
        line-height: ruf-theming.ruf-map-get($row-config, line-height, ruf-theming.ruf-line-height($config, caption-2));
      }
    }
  }
}

@mixin _ruf-mat-table-border-color($color-map, $colors) {
  @each $color in $colors {
    &[row-border=true][color=#{$color}] {
      .mat-row {
        .mat-cell:first-child {
          @if(meta.type-of(map.get($color-map, $color))=='map') {
            border-left-color: ruf-theming.ruf-color(map.get($color-map, $color));

            [dir='rtl'] & {
              border-right-color: ruf-theming.ruf-color(map.get($color-map, $color));
            }
          } @else {
            // if $color-map does not contains map e.g background
            border-left-color: ruf-theming.ruf-color($color-map, $color);

            [dir='rtl'] & {
              border-right-color: ruf-theming.ruf-color($color-map, $color);
            }
          }
        }
      }
    }
  }
}

// @mixin should be included inside .mat-row only
@mixin _ruf-mat-table-active-row($row-config, $theme) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  &.ruf-row-active {
    // add only when selected is passed
      $active-color: ruf-theming.ruf-map-get($row-config, selected, ruf-theming.ruf-color($background, app-canvas));
      @if($active-color) {
        background-color: $active-color;
      }
    }
    &.ruf-row-active-pale {
      background-color: ruf-theming.ruf-map-get($row-config, selected-row, ruf-theming.ruf-color($accent, 50));
    }
}
