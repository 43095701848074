
@font-face {
  font-family: 'clan-sc-offc-pro';
  src: url('../fonts/ClanScOffcPro-Ultra.ttf');
}
/* Colors */

/* API Dashboard */

$apiLightGray:#f4f4f4;
$apiLighterGray:#f8f8f8;
$apiDarkTeal:#004059;
$apiOffWhite:#f1f1f1;
$apiTxtDisable:#6a7376;
$apiBgBlack:#000000;
$apiDarkestTeal:#01232d;
$apiDarkBlue:#1b1b6f;


/* Main Palette */
$fisDarkTeal: #012834;
$lightTeal: #003a51;
$fisGreen: #4BCD3E;
$fisBlack: #012834;
$white: #ffffff;


/* Secondary Palette */
$GreenVariant1: #009775;
$GreenVariant2: #41b336;
$GreenVariant3: #a5e69f;
$GreenVariant4: #e9f9e8;
$BlueVariant1: #285bc5;
$BlueVariant2: #015b7e;
$BlueVariant3: #3bcff0;
$BlueVariant4: #e7f9fd;
$YellowVariant1: #ffc845;
$YellowVariant2: #f9f5f1;
$OrangeVaraint1: #fd8d62;
$OrangeVariant2: #fff4d9;
$RedVariant1: #b2162b;
$RedVariant2: #ffe4e8;
$RedVariant3: #ff1f3e;
$PurpleVariant1: #c9b8e3;
$PurpleVariant2: #a689d0;
$PurpleVariant3: #8259bd;
$iconGreyColor: #6B6B6B;
$greyColorVariant1: #EEEEEE;
$greyColorVariant2: #4E6971;
$fieldBorderLightCyan: #E0E5E6;




/* Field Colors & Grey Palette */
$fisFieldBorder: 1px solid #dfdfdf;
$fisFieldFocusBorder: 1px solid $fisDarkTeal;
$fieldLabel: #757575;
$InputHintText: #4E6971;
$Error: #b2162b;
$ErrorValidation: #de1b36;
$DisabledText: #B3BFC2;
$DisabledBackground: #4E6971;
$fisLightBorder: 1px solid #D8D8D8;
$formCloseIconGrey: #979797;
$descriptionFontGreyDark: #4E4E4E;

/* Text Styles */
$fontFamily: "Source Sans Pro", Arial, sans-serif;
$font: 300 "Source Sans Pro", Arial, sans-serif;
$fontBold: 700 26px "Source Sans Pro";
$LogoFont: 900 16px "clan-sc-offc-pro", Arial, sans-serif;
$Source-Sans-Pro-LogoFont: 900 26px "Source Sans Pro", Arial, sans-serif;
$HeroFont: 700 26px "Source Sans Pro", Arial, sans-serif; /* Main Headings */
$H1Font: 700 22px "Source Sans Pro", Arial, sans-serif; /* H1 */
$H2Font: 700 18px "Source Sans Pro", Arial, sans-serif; /* H2 */
$H3Font: 700 14px "Source Sans Pro", Arial, sans-serif; /* H3 */
$Button2Font: 700 14px "Source Sans Pro", Arial, sans-serif;
$font18Source: 500 18px "Source Sans Pro", Arial, sans-serif;
$font16Source: 500 16px "Source Sans Pro", Arial, sans-serif;
$font14Source: 500 14px "Source Sans Pro", Arial, sans-serif;
$Fig1Font: 300 12px "Source Sans Pro", Arial, sans-serif; /* Graphs and Charts */
$Fig2Font: 300 12px "Source Sans Pro", Arial, sans-serif; /* Graphs and Charts */
$FormLabelFont: 400 14px "Source Sans Pro", Arial, sans-serif;
$FormLabelFontH3: 600 13px "Source Sans Pro", Arial, sans-serif;
$FormLabelFontH4: 500 12px "Source Sans Pro", Arial, sans-serif;
$FormLabelFont12: 300 12px "Source Sans Pro", Arial, sans-serif;
$FormLabelFont12400: 400 12px "Source Sans Pro", Arial, sans-serif;
$FormLabelFont14400: 400 14px "Source Sans Pro", Arial, sans-serif;
$TableNoDataFont16700: 700 16px "Source Sans Pro", Arial, sans-serif;
$ButtonSearchSectionFont: 700 15px "Source Sans Pro", Arial, sans-serif;
 

