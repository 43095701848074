@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-sitemap-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $ruf-sitemap-list-item-color: ruf-theming.ruf-color($primary);

  .ruf-sitemap {
    color: inherit;

    .ruf-sitemap-children {
      .ruf-title {
        color: inherit;
      }
      li {
        a,
        .mat-icon {
          color: ruf-theming.ruf-color($foreground, link);
        }
      }
    }

    a .ruf-highlight {
      color: ruf-theming.ruf-color($foreground, link);
    }
  }

  .ruf-sitemap {
    &::-webkit-scrollbar-thumb {
      background-color: ruf-theming.ruf-color($primary);
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }


}


@mixin ruf-sitemap-typography($config, $override-config: ()) {
  $sitemap-sub-title: ruf-theming.ruf-map-get($override-config, sub-title, ());
  .ruf-sitemap {
    font-family: ruf-theming.ruf-font-family($config);
    .ruf-sitemap-parent {
      .ruf-title {
        font-family: ruf-theming.ruf-font-family($config, title);
        font-size: ruf-theming.ruf-font-size($config, sitemap-title);
        font-weight: ruf-theming.ruf-font-weight($config, sitemap-title);
      }

      ul {
        .ruf-title, li {
          font-size: ruf-theming.ruf-font-size($config, caption);
        }
      }
    }

    .ruf-sitemap-children:not(.ruf-sitemap-nested-children) > .ruf-sitemap-child {
      > div, > a {
        font-size: ruf-theming.ruf-font-size($config, body-2);
        font-weight: ruf-theming.ruf-font-weight($config, sitemap-title);
        line-height: ruf-theming.ruf-map-get($sitemap-sub-title, line-height, ruf-theming.ruf-line-height($config, body-1));
      }
    }
  }
}


/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-sitemap-override($config) {
  @if($config) {
    $section-config: ruf-theming.ruf-map-get($config, section, ());
    $section-width: ruf-theming.ruf-map-get($section-config, width, null);

    .ruf-sitemap.fis-override {
      @include ruf-theming.ruf-override-padding($config);

      .ruf-sitemap-parent {
        width: $section-width;
      }
    }
  }
}
