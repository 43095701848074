@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-bottom-sheet-panel($cls: 'fis-style', $config: ()) {
  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(18px));
  $border-style: ruf-theming.ruf-map-get($config, border-style, solid);
  $border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(2px));
  $padding: ruf-theming.ruf-map-get($config, padding, ruf-theming.ruf-to-rem(8px) ruf-theming.ruf-to-rem(16px));

  .mat-bottom-sheet-container#{$cls}, .cdk-overlay-pane#{$cls} .mat-bottom-sheet-container {
    border-style: $border-style;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-width: $border-width;
    padding: $padding;
  }
}
