@use '../core.variables';
@use '../bidirectionality/bidi';
/**
Apply padding from structural config
 */
@mixin ruf-override-padding($config: (), $dir: 'ltr') {
  $padding: core.ruf-map-get($config, padding, null);

  $padding-vertical: core.ruf-map-get($config, padding-vertical, $padding);
  $padding-horizontal: core.ruf-map-get($config, padding-horizontal, $padding);

  $padding-left: core.ruf-map-get($config, padding-left, $padding-horizontal);
  $padding-right: core.ruf-map-get($config, padding-right, $padding-horizontal);
  $padding-top: core.ruf-map-get($config, padding-top, $padding-vertical);
  $padding-bottom: core.ruf-map-get($config, padding-bottom, $padding-vertical);

  padding-bottom: $padding-bottom;

  padding-top: $padding-top;

  @if($dir=='rtl') {
    padding-left: $padding-right;
    padding-right: $padding-left;
  } @else {
    padding-left: $padding-left;
    padding-right: $padding-right;
  }
}

/**
Apply margin from structural config
 */
@mixin ruf-override-margin($config: (), $dir: 'ltr') {
  $margin: core.ruf-map-get($config, margin, null);

  $margin-horizontal: core.ruf-map-get($config, margin-horizontal, margin);
  $margin-vertical: core.ruf-map-get($config, margin-vertical, margin);

  $margin-left: core.ruf-map-get($config, margin-left, $margin-horizontal);
  $margin-right: core.ruf-map-get($config, margin-right, $margin-horizontal);
  $margin-top: core.ruf-map-get($config, margin-top, $margin-vertical);
  $margin-bottom: core.ruf-map-get($config, margin-bottom, $margin-vertical);

  margin-bottom: $margin-bottom;

  margin-top: $margin-top;

  @if($dir=='rtl') {
    margin-left: $margin-right;
    margin-right: $margin-left;
  } @else {
    margin-left: $margin-left;
    margin-right: $margin-right;
  }
}

/**
Apply border style form structural config
 */
@mixin ruf-override-border-style($config: ()) {
  $border-radius: core.ruf-map-get($config, border-radius, null);

  $border-width: core.ruf-map-get($config, border-width, null);

  $border-left-width: core.ruf-map-get($config, border-left-width, $border-width);
  $border-right-width: core.ruf-map-get($config, border-right-width, $border-width);
  $border-top-width: core.ruf-map-get($config, border-right-width, $border-width);
  $border-bottom-width: core.ruf-map-get($config, border-right-width, $border-width);

  border-radius: $border-radius;
}

/**
Apply all styles present in structural config
 */
@mixin ruf-override-size-style($config: ()) {
  $min-height: core.ruf-map-get($config, min-height, null);
  $height: core.ruf-map-get($config, height, null);
  $max-height: core.ruf-map-get($config, max-height, null);

  $min-width: core.ruf-map-get($config, min-width, null);
  $width: core.ruf-map-get($config, width, null);
  $max-width: core.ruf-map-get($config, max-width, null);

  $text-tranform: core.ruf-map-get($config, text-tranform, null);

  @include ruf-override-padding($config);
  @include ruf-override-margin($config);
  @include ruf-override-border-style($config);

  height: $height;

  max-height: $max-height;
  max-width: $max-width;

  min-height: $min-height;
  min-width: $min-width;

  text-transform: $text-tranform;
  width: $width;
}

// Add property horizontal
// @param {string} $start
// @param {string} $end
// ----------------------------------------------------------
@mixin property-horizontal($prop, $start, $end: $start) {
  @if $start == $end {
    #{$prop}-left: $start;
    #{$prop}-right: $end;

  } @else {
    #{$prop}-left: $start;
    #{$prop}-right: $end;
    @include bidi.rtl() {
      #{$prop}-left: $end;
      #{$prop}-right: $start;
    }
  }
}

// Add property for all directions
/// @param {string} $prop
/// @param {string} $top
/// @param {string} $end
/// @param {string} $bottom
/// @param {string} $start
/// @param {boolean} $content include content or use default
// ----------------------------------------------------------
@mixin property($prop, $top, $end: $top, $bottom: $top, $start: $end) {
  @include property-horizontal($prop, $start, $end);
  #{$prop}-top: $top;
  #{$prop}-bottom: $bottom;
}

// Add padding horizontal
/// @param {string} $start
/// @param {string} $end
/// ----------------------------------------------------------
@mixin padding-horizontal($start, $end: $start) {
  @include property-horizontal(padding, $start, $end);
}

// Add padding for all directions
/// @param {string} $top
/// @param {string} $end
/// @param {string} $bottom
/// @param {string} $start
// ----------------------------------------------------------
@mixin padding($top, $end: $top, $bottom: $top, $start: $end) {
  @include property(padding, $top, $end, $bottom, $start);
}

// Add margin horizontal
/// @param {string} $start
/// @param {string} $end
// ----------------------------------------------------------
@mixin margin-horizontal($start, $end: $start) {
  @include property-horizontal(margin, $start, $end);
}

// Add margin for all directions
/// @param {string} $top
/// @param {string} $end
/// @param {string} $bottom
/// @param {string} $start
// ----------------------------------------------------------
@mixin margin($top, $end: $top, $bottom: $top, $start: $end) {
  @include property(margin, $top, $end, $bottom, $start);
}
