@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-side-panel-toggle-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $shadow-color: ruf-theming.ruf-map-get($config, shadow-color, ruf-theming.ruf-color($accent));
  $mat-default: ruf-theming.ruf-map-get($config, mat-default);
  $mat-default-background-color: ruf-theming.ruf-map-get($mat-default, background-color, transparent);
  $mat-default-border-color: ruf-theming.ruf-map-get($mat-default, border-color, ruf-theming.ruf-color($primary));
  $mat-default-color: ruf-theming.ruf-map-get($mat-default, color, ruf-theming.ruf-color($primary));
  $hover: ruf-theming.ruf-map-get($mat-default, hover);
  $hover-background-color: ruf-theming.ruf-map-get($hover, background-color, ruf-theming.ruf-color($primary));
  $hover-color: ruf-theming.ruf-map-get($hover, color, ruf-theming.ruf-map-get($foreground, lighter-text));

  .ruf-side-panel-toggle.mat-raised-button.ruf-toggle-chevron {
    &.mat-default:not(.mat-button-disabled) {
      background-color: $mat-default-background-color;
      border-color: $mat-default-border-color;
      color: $mat-default-color;

      &:hover,
      &:focus {
        background-color: $hover-background-color;
        color: $hover-color;
      }
    }

    &.mat-primary:not(.mat-button-disabled) {
      &:hover,
      &:focus {
        background-color: $hover-background-color;
        color: $hover-color;
      }
    }

    &:not(.ruf-side-panel-toggle-direction-left):not(.ruf-side-panel-toggle-rtl).cdk-keyboard-focused,
    &.ruf-side-panel-toggle-rtl.ruf-side-panel-toggle-direction-left.cdk-keyboard-focused,
    &:not(.ruf-side-panel-toggle-direction-left):not(.ruf-side-panel-toggle-rtl).cdk-program-focused,
    &.ruf-side-panel-toggle-rtl.ruf-side-panel-toggle-direction-left.cdk-program-focused {
      box-shadow:
        ruf-theming.ruf-to-rem(-2px) 0 0 $shadow-color,
        0 ruf-theming.ruf-to-rem(2px) 0 $shadow-color,
        0 ruf-theming.ruf-to-rem(-2px) 0 $shadow-color;
    }

    &.ruf-side-panel-toggle-direction-left:not(.ruf-side-panel-toggle-rtl).cdk-keyboard-focused,
    &.ruf-side-panel-toggle-rtl:not(.ruf-side-panel-toggle-direction-left).cdk-keyboard-focused,
    &.ruf-side-panel-toggle-direction-left:not(.ruf-side-panel-toggle-rtl).cdk-program-focused,
    &.ruf-side-panel-toggle-rtl:not(.ruf-side-panel-toggle-direction-left).cdk-program-focused {
      box-shadow:
        ruf-theming.ruf-to-rem(2px) 0px 0 $shadow-color,
        0px ruf-theming.ruf-to-rem(2px) 0 $shadow-color,
        0px ruf-theming.ruf-to-rem(-2px) 0 $shadow-color;
    }
  }
}
