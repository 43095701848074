@use '@ruf/theming' as ruf-theming;
@use '../divider/divider';

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-list($cls: '[fisStyle]', $config: ()) {

  $mat-subheader-config: ruf-theming.ruf-map-get($config, subheader, ());
  $mat-list-item-config: ruf-theming.ruf-map-get($config, list-item, ());
  $mat-list-option-config: ruf-theming.ruf-map-get($config, list-option, ());
  $mat-subheader-padding-top: ruf-theming.ruf-map-get($mat-subheader-config, padding-top, ruf-theming.ruf-to-rem(8px));
  $mat-subheader-padding-bottom: ruf-theming.ruf-map-get($mat-subheader-config, padding-bottom, ruf-theming.ruf-to-rem(8px));
  $mat-list-item-padding-top: ruf-theming.ruf-map-get($mat-list-item-config, padding-top, ruf-theming.ruf-to-rem(8px));
  $mat-list-item-padding-bottom: ruf-theming.ruf-map-get($mat-list-item-config, padding-bottom, ruf-theming.ruf-to-rem(8px));
  $mat-list-option-padding-top: ruf-theming.ruf-map-get($mat-list-option-config, padding-top, ruf-theming.ruf-to-rem(11px));
  $mat-list-option-padding-bottom: ruf-theming.ruf-map-get($mat-list-option-config, padding-bottom, ruf-theming.ruf-to-rem(11px));

  .mat-list-base#{$cls} {

    .mat-subheader {
      height: auto;
      padding-bottom: $mat-subheader-padding-bottom;
      padding-top: $mat-subheader-padding-top;
    }

    &:not([dense]) .mat-list-item:not(.mat-list-option) {
      height: auto;
      padding-bottom: $mat-list-item-padding-bottom;
      padding-top: $mat-list-item-padding-top;
    }

    .mat-list-option,
    .mat-list-option.mat-list-item-with-avatar {
      height: auto;
      padding-bottom: $mat-list-option-padding-bottom;
      padding-top: $mat-list-option-padding-top;
    }

    .mat-list-item {
      .mat-list-item-content .mat-list-icon,
      .mat-list-avatar {
        height: auto;
        width: auto;
      }

      .mat-divider {
        @include divider.ruf-mat-divider-margin();
      }
    }

    .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
    &[dense] .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
      padding-left: ruf-theming.ruf-to-rem(6px);
      padding-right: ruf-theming.ruf-to-rem(0px);
    }
  }

  [dir='rtl'] {
    .mat-list-base#{$cls} {
      .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
      &[dense] .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
        padding-left: 0;
        padding-right: ruf-theming.ruf-to-rem(6px);
      }
    }
  }

  .mat-selection-list#{$cls},
  .mat-nav-list#{$cls} {
    &.ruf-hover-interaction-rounded-border {
      .mat-list-option,
      .mat-list-item {
        // Added 9999px as an workaround to make the right and left side a complete half circle
        border-radius: ruf-theming.ruf-to-rem(9999px);
      }
    }
  }

  .mat-nav-list#{$cls} {
    .ruf-list-item-disable-interaction.mat-list-item {
      cursor: default;
      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }
}
