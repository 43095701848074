@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-tooltip-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $is-dark: map.get($theme, is-dark);

  .mat-tooltip-panel {
    .mat-tooltip#{$cls},
    .mat-tooltip {
      background-color: ruf-theming.ruf-map-get($config, background-color, ruf-theming.ruf-color($background, tooltip));
      color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($foreground, if($is-dark, darker-text, lighter-text)));
   }
  }
}

// stylelint-disable block-no-empty
@mixin ruf-mat-tooltip-panel-typography($config, $cls: 'fis-style') {
  .mat-tooltip-panel {
    .mat-tooltip#{$cls} {
    }
  }
}
