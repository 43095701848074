@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;

@mixin _ruf-toolbar-color($background, $foreground) {
  background: $background;
  color: $foreground;
}

@mixin _ruf-toolbar-color-from-palette($palette) {
  @include _ruf-toolbar-color(ruf-theming.ruf-color($palette), ruf-theming.ruf-color($palette, default-contrast));
}

@mixin _ruf-toolbar-separator-color($color, $opacity: 0.5) {
  .ruf-toolbar-separator {
    opacity: $opacity; // set default value when ruf-toolbar classes like ruf-default, ruf-primary etc. are used.
  }

  &:not(.ruf-toolbar-rtl) .ruf-toolbar-separator {
    border-left-color: $color;
  }

  &.ruf-toolbar-rtl .ruf-toolbar-separator {
    border-right-color: $color;
  }
}

@mixin _ruf-toolbar-separator($color, $opacity: 0.5) {
   @if(meta.type-of($color) == 'map') {
    // For primary, accent, emphasis, warn
    @include _ruf-toolbar-separator-color(ruf-theming.ruf-color($color, default-contrast), $opacity);
   } @else {
    // For ruf-default.
    @include _ruf-toolbar-separator-color($color, $opacity);
   }
}

@mixin ruf-toolbar-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);

  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-toolbar-background: ruf-theming.ruf-map-get($config, default-background-color, ruf-theming.ruf-color($background, app-bar));
  $ruf-toolbar-separator-background: ruf-theming.ruf-color($background, contrast) !default;
  $ruf-banner-color: ruf-theming.ruf-color($foreground, text) !default;

  .ruf-toolbar {

    // default background and foreground colors are inherited

    &.ruf-default {
      @include _ruf-toolbar-color($ruf-toolbar-background, $ruf-banner-color);
      @include _ruf-toolbar-separator($ruf-toolbar-separator-background, 0.3);
    }

    &.ruf-primary {
      @include _ruf-toolbar-color-from-palette($primary);
      @include _ruf-toolbar-separator($primary);
    }

    &.ruf-accent {
      @include _ruf-toolbar-color-from-palette($accent);
      @include _ruf-toolbar-separator($accent);
    }

    &.ruf-emphasis {
      @include _ruf-toolbar-color-from-palette($emphasis);
      @include _ruf-toolbar-separator($emphasis);
    }

    .ruf-toolbar-separator {
      opacity: 0.3;
    }

    &:not(.ruf-toolbar-rtl) .ruf-toolbar-separator {
      border-left-color: inherit; // takes the foreground color of the parent element
    }

    &.ruf-toolbar-rtl .ruf-toolbar-separator {
      border-right-color: inherit; // takes the foreground color of the parent element
    }

    a {
      color: inherit;
    }
  }
}


@mixin ruf-toolbar-typography($config) {
  .ruf-toolbar {
    font-family: ruf-theming.ruf-font-family($config);
  }
}


/* Structural config override*/
@mixin ruf-toolbar-override($config) {
  @if($config) {
    $ruf-toolbar-spacing: ruf-theming.ruf-map-get($config, item-spacing, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 10px));

    .ruf-toolbar.fis-override {
      ruf-toolbar-row {
        @include ruf-toolbar-row-birectionality($ruf-toolbar-spacing, 'left', 'right');
      }
    }

    .ruf-toolbar.ruf-toolbar-rtl.fis-override {
      ruf-toolbar-row {
        @include ruf-toolbar-row-birectionality($ruf-toolbar-spacing, 'right', 'left');
      }
    }
  }
}

@mixin ruf-toolbar-row-birectionality($ruf-toolbar-spacing, $dir1: 'left', $dir2: 'right') {

  > *:not(.ruf-square) {
    margin-#{$dir1}: $ruf-toolbar-spacing;
  }

  > *:nth-last-child(1) {
    margin-#{$dir1}: $ruf-toolbar-spacing;
    margin-#{$dir2}: $ruf-toolbar-spacing;
  }

  > *:nth-last-child(1).ruf-square {
    margin-#{$dir2}: 0;
  }
}

