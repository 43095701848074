@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-sidemenu-item-color($parent-hover-color, $parent-active-color, $children-bg-color, $child-hover-color, $color: null) {
  $class: '.ruf-menu-item';
  #{$class} {
    @if $color {
      color: $color;
    } @else {
      color: inherit;
    }
    &.ruf-active {
      background: $parent-active-color;
    }

    &:hover, &:focus {
      background: $parent-hover-color;
      &.ruf-unminimized-mode, &.ruf-minimized-mode {
        background-color: inherit;
      }
    }
  }
  .ruf-sidemenu-children {
    background: $children-bg-color;
    #{$class} {
       &:hover, &:focus {
         background: $child-hover-color;
      }
    }
  }
}

@mixin ruf-sidemenu-bidrectionality($theme, $default-active, $dir1: 'left', $dir2: 'right') {
  $colors: primary, accent, emphasis;

  @each $color in $colors {
    &.ruf-#{$color} .ruf-menubar-item-active {
      &:not(.ruf-active) {
        border-bottom-color: ruf-theming.ruf-color(map.get($theme, $color));
        border-#{$dir2}-color: ruf-theming.ruf-color(map.get($theme, $color));
      }
    }
  }

  .ruf-menubar-item-active {
    &:not(.ruf-active) {
      border-bottom-color: $default-active;
      border-#{$dir2}-color: $default-active;
    }
  }

  // styles for inkbarPosition = 'start'
  &.ruf-menubar-inkbar-start {
    @each $color in $colors {
      &.ruf-#{$color} .ruf-menubar-item-active {
        &:not(.ruf-active) {
          border-bottom-color: transparent;
          border-top-color: ruf-theming.ruf-color(map.get($theme, $color));
          border-#{$dir1}-color: ruf-theming.ruf-color(map.get($theme, $color));
          border-#{$dir2}-color: transparent;
        }
      }
    }

    .ruf-menubar-item-active {
      &:not(.ruf-active) {
        border-bottom-color: transparent;
        border-top-color: $default-active;
        border-#{$dir1}-color: $default-active;
        border-#{$dir2}-color: transparent;
      }
    }
  }
}

@mixin ruf-sidemenu-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark: map.get($theme, is-dark);

  // Gold specific
  $sidemenu-dark-bg-color: if($is-dark, ruf-theming.ruf-color($background, hover-dark), rgba(map.get(ruf-theming.$ruf-blue, 500), 0.12) );
  $sidemenu-light-bg-color: if($is-dark, rgba(0, 0, 0, 0.1), ruf-theming.ruf-color($background, hover-dark));
  // stylelint-disable max-line-length
  $box-shadow: inset ruf-theming.ruf-to-rem(-7px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(9px) ruf-theming.ruf-to-rem(-7px) ruf-theming.ruf-color($background, contrast, 0.19);
  $box-shadow-rtl: inset ruf-theming.ruf-to-rem(7px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(9px) ruf-theming.ruf-to-rem(-7px) ruf-theming.ruf-color($background, contrast, 0.19);

  // sidemenu item
  $sidemenu-item: ruf-theming.ruf-map-get($config, sidemenu-item, ());
  $sidemenu-item-dark: ruf-theming.ruf-map-get($sidemenu-item, dark-background, ());
  $sidemenu-item-light: ruf-theming.ruf-map-get($sidemenu-item, light-background, ());

  $sidemenu-border-color: ruf-theming.ruf-map-get($config, border-color, none);

  // dark sidemenu item properties
  $dark-parent-item-hover-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, parent-item-hover-bg-color, $sidemenu-dark-bg-color);
  $dark-parent-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, parent-item-active-bg-color, $sidemenu-dark-bg-color);
  $dark-children-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, children-bg-color, $sidemenu-dark-bg-color);
  $dark-child-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-dark, child-item-hover-bg-color, transparent);

  // light sidemenu item properties
  $light-parent-item-hover-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, parent-item-hover-bg-color, $sidemenu-light-bg-color);
  $light-parent-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, parent-item-active-bg-color, $sidemenu-light-bg-color);
  $light-children-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, children-bg-color, $sidemenu-light-bg-color);
  $light-child-item-active-bg-color: ruf-theming.ruf-map-get($sidemenu-item-light, child-item-hover-bg-color, transparent);

  // inkbar, toggle(chevron)
  $ruf-sidemenu-toggle-chevron-color: ruf-theming.ruf-map-get($sidemenu-item, toggle-chevron-color, inherit);
  $default-item-active-color: ruf-theming.ruf-map-get($sidemenu-item, default-active-inkbar-color, ruf-theming.ruf-color($accent));


  .ruf-sidemenu, .ruf-sidemenu-popup-backdrop + * .mat-menu-panel {

    &:not(.ruf-sidemenu-rtl) {
      @include ruf-sidemenu-bidrectionality($theme, $default-item-active-color, 'left', 'right');
    }
    &.ruf-sidemenu-rtl {
      @include ruf-sidemenu-bidrectionality($theme, $default-item-active-color, 'right', 'left');
    }

    .ruf-menu-item {
      &.ruf-toggle-chevron::after,
      &.ruf-toggle-arrow::after {
        color: $ruf-sidemenu-toggle-chevron-color;
      }
    }

  }

  .ruf-sidemenu {
    background: inherit;
    color: inherit;
    &:not(.ruf-sidemenu-rtl) {
      box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $box-shadow);
    }

    &.ruf-sidemenu-rtl {
      box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $box-shadow-rtl);
    }

    &:not([light]) {
      @include ruf-sidemenu-item-color($dark-parent-item-hover-bg-color, $dark-parent-item-active-bg-color,
       $dark-children-bg-color, $dark-child-item-active-bg-color);
    }

    &[light] {
      @include ruf-sidemenu-item-color($light-parent-item-hover-bg-color, $light-parent-item-active-bg-color,
       $light-children-bg-color, $light-child-item-active-bg-color);
    }
  }
}

@mixin ruf-sidemenu-typography($config) {
  .ruf-sidemenu, .ruf-sidemenu-popup-backdrop + * .mat-menu-panel {
    .ruf-menu-item {
      font-family: ruf-theming.ruf-font-family($config, menu-item);
      font-size: ruf-theming.ruf-font-size($config, menu-item);
      font-weight: ruf-theming.ruf-font-weight($config, menu-item);
      line-height: ruf-theming.ruf-line-height($config, menu-item);
    }

    .ruf-sidemenu-children {
     .ruf-menu-item {
       font-family: ruf-theming.ruf-font-family($config, menu-children);
       font-size: ruf-theming.ruf-font-size($config, menu-children);
       font-weight: ruf-theming.ruf-font-weight($config, menu-children);
       line-height: ruf-theming.ruf-line-height($config, menu-children);
     }
    }
  }
}

/* Structural config override*/
@mixin ruf-sidemenu-override($config) {
   @if ($config) {

    $parent-item: ruf-theming.ruf-map-get($config, parent-item, ());
    $child-item: ruf-theming.ruf-map-get($config, child-item, ());
    $icon-size: ruf-theming.ruf-map-get($config, icon-size, ruf-theming.ruf-to-rem(20px));

    // Sidemenu
    $ruf-sidemenu-width: ruf-theming.ruf-map-get($config, width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 300px));
    $ruf-sidemenu-child-item-text-decoration: ruf-theming.ruf-map-get($child-item, text-decoration, underline);
    $ruf-sidemenu-child-item-padding-left: ruf-theming.ruf-map-get($child-item, padding-left, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 46px));
    $ruf-sidemenu-child-item-padding-right: ruf-theming.ruf-map-get($child-item, padding-right, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 15px));

     // structural override
      .ruf-sidemenu.fis-override,
     .ruf-sidemenu-popup-backdrop + * .mat-menu-panel.fis-override {
        width: $ruf-sidemenu-width;

      .ruf-menu-item {
          &.ruf-toggle-chevron::after,
          &.ruf-toggle-arrow::after {
            font-size: $icon-size;
            margin-left: ruf-theming.ruf-to-rem(5px);
            margin-right: ruf-theming.ruf-to-rem(5px);
          }

          .ruf-labeled-icon {
            .mat-icon {
              font-size: $icon-size;
            }
          }
      }

      .ruf-menubar-layout.ruf-layout-column .ruf-sidemenu-children .ruf-menu-item,
      .ruf-sidemenu-children .ruf-menu-item {
        padding-bottom: ruf-theming.ruf-map-get($child-item, padding-vertical, ruf-theming.ruf-to-rem(6px));
        padding-left: $ruf-sidemenu-child-item-padding-left;
        padding-right: $ruf-sidemenu-child-item-padding-right;
        padding-top: ruf-theming.ruf-map-get($child-item, padding-vertical, ruf-theming.ruf-to-rem(6px));
        &:hover, &:active, &:focus {
          text-decoration: $ruf-sidemenu-child-item-text-decoration;
        }
      }

      &.ruf-sidemenu-rtl {
        .ruf-menubar-layout.ruf-layout-column .ruf-sidemenu-children .ruf-menu-item,
        .ruf-sidemenu-children .ruf-menu-item {
          padding-left: $ruf-sidemenu-child-item-padding-right;
          padding-right: $ruf-sidemenu-child-item-padding-left;
        }
      }
    }
  }
}
