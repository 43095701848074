@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@use '@angular/material' as mat;
@mixin ruf-mat-slider-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $slider-config: ruf-theming.ruf-map-get($config, slider, ());
  $slider-disabled-config: ruf-theming.ruf-map-get($config, disabled, ());

  $tracker-background-color: ruf-theming.ruf-map-get($slider-config, background-color, null);
  $tracker-opacity: ruf-theming.ruf-map-get($slider-config, opacity, 1);
  $min-thumb: ruf-theming.ruf-map-get($slider-config, min-thumb, ());
  $min-value-thumb-background-color: ruf-theming.ruf-map-get($min-thumb, background-color, transparent);
  $min-value-thumb-border-color: ruf-theming.ruf-map-get($min-thumb, border-color, ruf-theming.ruf-color($foreground, slider-off));
  $tick-color: ruf-theming.ruf-map-get($slider-config, tick-color, ruf-theming.ruf-color($foreground, base));
  $disabled-opacity: ruf-theming.ruf-map-get($slider-disabled-config, opacity, 1);
  $disabled-tracker-background-color: ruf-theming.ruf-map-get(
    $slider-disabled-config,
    thumb-track-background-color,
    null
  );
  $disabled-thumb-border-color: ruf-theming.ruf-map-get($slider-disabled-config, thumb-border-color, transparent);
  $disabled-background-color: ruf-theming.ruf-map-get(
                                $slider-disabled-config,
                                thumb-track-background-color,
                                ruf-theming.ruf-color($foreground, slider-off)
                              );
  $disabled-min-thumb: ruf-theming.ruf-map-get($slider-disabled-config, min-thumb, ());
  $disabled-min-value-thumb-background-color: ruf-theming.ruf-map-get($disabled-min-thumb, background-color, transparent);
  $disabled-min-value-thumb-border-color: ruf-theming.ruf-map-get($disabled-min-thumb, border-color, ruf-theming.ruf-color($foreground, slider-off));

  .mat-slider#{$cls} {
    &.mat-slider-has-ticks {
      &:not(.mat-slider-disabled) {
        .mat-slider-ticks-container .mat-slider-ticks {
          opacity: 0;
        }

        &:hover, &.cdk-focused {
          .mat-slider-ticks-container .mat-slider-ticks {
            opacity: 1;
          }
        }

        &.mat-slider-horizontal .mat-slider-ticks {
          background-image: repeating-linear-gradient(to right, $tick-color, $tick-color ruf-theming.ruf-to-rem(2px), transparent 0, transparent);
        }

        &.mat-slider-vertical .mat-slider-ticks {
          background-image: repeating-linear-gradient(to bottom, $tick-color, $tick-color ruf-theming.ruf-to-rem(2px), transparent 0, transparent);
        }
      }
    }

    &:not(.mat-slider-hide-last-tick):not(.mat-slider-disabled) .mat-slider-wrapper::after {
      border-color: $tick-color;
    }

    &.mat-slider-disabled {
      .mat-slider-track-background {
        background-color: $disabled-tracker-background-color;
      }
    }

    &:not(.mat-slider-min-value).mat-slider-disabled {
      .mat-slider-track-fill {
        background-color: $disabled-background-color;
        opacity: $disabled-opacity;
      }

      .mat-slider-thumb {
        background-color: $disabled-background-color;
        border-color: $disabled-thumb-border-color;
      }
    }

    &.mat-slider-min-value.mat-slider-disabled {
      .mat-slider-thumb {
        background-color: $disabled-min-value-thumb-background-color;
        border-color: $disabled-min-value-thumb-border-color;
      }
    }

    &.mat-slider-min-value:not(.mat-slider-disabled) {
      .mat-slider-thumb {
        background-color: $min-value-thumb-background-color;
        border-color: $min-value-thumb-border-color;
      }
    }

    &.cdk-keyboard-focused:not(.mat-slider-min-value) .mat-slider-focus-ring,
    &.cdk-program-focused:not(.mat-slider-min-value) .mat-slider-focus-ring {
      opacity: 0.2;
    }

    &.cdk-keyboard-focused, &.cdk-program-focused {
      .mat-slider-focus-ring {
        opacity: 0.12;
      }
    }
  }
  .mat-slider-track-background,
  .mat-slider#{$cls}:hover:not(.mat-slider-disabled) .mat-slider-track-background,
  .mat-slider#{$cls}.cdk-focused:not(.mat-slider-disabled) .mat-slider-track-background {
    background-color: $tracker-background-color;
    opacity: $tracker-opacity;
  }
}

@mixin ruf-mat-slider-typography($config, $cls: '[fisStyle]') {
  .mat-slider {
    .mat-slider-thumb-label-text {
      font-weight: mat.font-weight($config, bold);
    }
  }
}
