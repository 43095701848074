@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-slide-toggle-bidi($dir1: 'left', $dir2: 'right') {
  .mat-slide-toggle-bar {
    margin: ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(0px)
    ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(6px);
  }
}

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-slide-toggle($cls: '[fisStyle]', $config: ()) {
  $slide-toggle-bar: ruf-theming.ruf-map-get($config, slide-toggle-bar, ());
  $slide-toggle-thumb: ruf-theming.ruf-map-get($config, slide-toggle-thumb, ());

  $slide-toggle-bar-margin-default: ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(8px)
   ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(0px);

  $slide-toggle-bar-height: ruf-theming.ruf-map-get(
    $slide-toggle-bar,
    height,
   ruf-theming.ruf-to-rem(14px)
  );
  $slide-toggle-bar-width: ruf-theming.ruf-map-get(
    $slide-toggle-bar,
    width,
   ruf-theming.ruf-to-rem(36px)
  );
  $slide-toggle-bar-width: ruf-theming.ruf-map-get(
    $slide-toggle-bar,
    width,
   ruf-theming.ruf-to-rem(36px)
  );
  $slide-toggle-bar-margin: ruf-theming.ruf-map-get(
    $slide-toggle-bar,
    margin,
    $slide-toggle-bar-margin-default
  );

  $slide-toggle-thumb-border-style: ruf-theming.ruf-map-get(
    $slide-toggle-thumb,
    box-shadow,
    solid
  );
  $slide-toggle-thumb-border-width: ruf-theming.ruf-map-get(
    $slide-toggle-thumb,
    border-color,
    0px
  );

  .mat-slide-toggle#{$cls} {
    .mat-slide-toggle-thumb {
      border-style: $slide-toggle-thumb-border-style;
      border-width: $slide-toggle-thumb-border-width;
    }

    .mat-slide-toggle-bar {
      height: $slide-toggle-bar-height;
      margin: $slide-toggle-bar-margin;
      width: $slide-toggle-bar-width;
    }

    // Disable styles
    &.mat-disabled {
      .mat-slide-toggle-label {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
      .mat-slide-toggle-thumb-container {
        cursor: ruf-theming.$ruf-disabled-cursor;
      }
    }

    &.ruf-toggle-readonly {
      pointer-events: none;

      .mat-slide-toggle-bar {
        display: none;
      }
    }
  }

  [dir='rtl'] {
    .mat-slide-toggle#{$cls} {
      @include ruf-mat-slide-toggle-bidi('right', 'left');
    }
  }
}
