@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin _ruf-mat-select-inner-content-theme($palette) {
  $color: ruf-theming.ruf-color($palette);

  .mat-select-underline {
    background-color: $color;
  }
}

@mixin _ruf-mat-select-placeholder-theme($color) {
  .mat-select-trigger {
    .mat-select-placeholder {
      color: $color;
    }
  }
}

@mixin _ruf-mat-select-text-theme($theme-colors) {

  @each $name, $color in $theme-colors {
    .ruf-#{$name} & {
      color: ruf-theming.ruf-color($color, default-contrast);
    }
  }
}

@mixin ruf-mat-select-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $error: map.get($theme, error);

  $theme-colors: (
      primary: $primary,
      accent: $accent,
      emphasis: $emphasis
  );

  $select-infix: ruf-theming.ruf-map-get($config, select-infix, ());
  $select-option: ruf-theming.ruf-map-get($config, select-option, ());
  $select-error: ruf-theming.ruf-map-get($config, select-error, ());

  $select-disabled: ruf-theming.ruf-map-get($config, disabled, ());
  $ruf-input-disabled-opacity: ruf-theming.ruf-map-get($select-disabled, opacity, 1);
  $ruf-input-disabled-color: ruf-theming.ruf-map-get($select-disabled, color, ruf-theming.ruf-color($foreground, text));

  $ruf-select-value-text: ruf-theming.ruf-map-get($select-infix, text-color, ruf-theming.ruf-color($foreground, base, 0.835));
  $ruf-select-arrow-color: ruf-theming.ruf-map-get($select-infix, arrow-color, ruf-theming.ruf-color($foreground, text));
  $ruf-mat-select-placeholder: ruf-theming.ruf-map-get($select-infix, placeholder-color, ruf-theming.ruf-color($foreground, text));
  $ruf-mat-select-error-label: ruf-theming.ruf-map-get($select-error, placeholder-color, ruf-theming.ruf-color($error, default));
  $ruf-select-text-color-error: ruf-theming.ruf-map-get($select-error, text-color, ruf-theming.ruf-color($error, default));
  $ruf-select-arrow-error-color: ruf-theming.ruf-map-get($select-error, arrow-color, ruf-theming.ruf-color($error, default));

  .mat-select#{$cls} {

    @include _ruf-mat-select-placeholder-theme($ruf-mat-select-placeholder);

    .mat-select-underline {
      background-color: ruf-theming.ruf-color($foreground, divider);
    }

    .mat-select-trigger {
      @include _ruf-mat-select-text-theme($theme-colors);
      color: ruf-theming.ruf-color($foreground, text);
    }

    .mat-select-arrow {
      color: $ruf-select-arrow-color;
    }

    &.mat-select-invalid:not(.mat-select-disabled) {
      .mat-select-placeholder {
        color: $ruf-mat-select-error-label;
      };

      .mat-select-value {
        color: $ruf-select-text-color-error;
      }

      .mat-select-arrow {
        color: $ruf-select-arrow-error-color;
      }
    }

    .mat-select-content,
    .mat-select-panel-done-animating {
      background: ruf-theming.ruf-color($background, panel);
    }

    &:not(.mat-select-disabled) .mat-select-value {
      @include _ruf-mat-select-text-theme($theme-colors);
      color: $ruf-select-value-text;
    }

    &.mat-select-disabled {
      .mat-select-value {
        color: $ruf-input-disabled-color;
        opacity: $ruf-input-disabled-opacity;
      }
    }
  }

  [light] {
    &.mat-form-field#{$cls}.mat-form-field-type-mat-select.mat-form-field-appearance-outline {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-wrapper .mat-form-field-flex {
          .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label,
          .mat-form-field-outline, .mat-select-value, .mat-select-trigger {
            color: inherit;
          }
        }
      }

      &.mat-focused:not(.mat-form-field-invalid) {
        .mat-form-field-wrapper .mat-form-field-flex {
          .mat-select-value, .mat-select-arrow, .mat-form-field-outline {
            color: inherit;
          }
        }
      }
    }
  }
}

@mixin ruf-mat-select-typography($config, $cls: '[fisStyle]') {
  .mat-select#{$cls} {
    .mat-select-value {
      font-size: ruf-theming.ruf-font-size($config, input);
      .mat-select-placeholder {
        font-weight: ruf-theming.ruf-font-weight($config, display-4);
      }
    }
  }
  [light] {
    .mat-select-value {
      color: inherit;
    }
    &:not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        .mat-select-arrow {
          color: inherit;
        }
      }
    }
  }
}

@mixin ruf-mat-select-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $select-infix: ruf-theming.ruf-map-get($config, select-infix, ());
  $select-option: ruf-theming.ruf-map-get($config, select-option, ());
  $ruf-select-value-text: ruf-theming.ruf-map-get($select-infix, text-color, ruf-theming.ruf-color($foreground, base, 0.835));

  $select-disabled: ruf-theming.ruf-map-get($config, disabled, ());
  $select-disabled-option: ruf-theming.ruf-map-get($config, disabled-option, ());
  $ruf-input-disabled-opacity: ruf-theming.ruf-map-get($select-disabled, opacity, 0.5);
  $ruf-input-disabled-option-color: ruf-theming.ruf-map-get($select-disabled-option, color, null);
  $ruf-select-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  $ruf-select-border-color: ruf-theming.ruf-color($foreground, subtle-divider);

  .mat-select-panel-wrap .mat-select-panel#{$cls}, .mat-select-panel-wrap .mat-select-panel {
    box-shadow: none;
    &.mat-primary,
    &.mat-accent,
    &.mat-warn,
    &.mat-emphasis {
      border-color: $ruf-select-border-color;
      box-shadow: ruf-theming.ruf-map-get($select-option, box-shadow, $ruf-select-box-shadow);
      .mat-option:not(.mat-option-disabled):not(.mat-option-multiple) {
        &.mat-active,
        &.mat-selected,
        &.mat-active.mat-selected,
        &:hover,
        &:focus {
          background-color: ruf-theming.ruf-color($background, hover);
          color: $ruf-select-value-text;
        }
      }

      .mat-option-multiple:not(.mat-option-disabled) {
        &.mat-active,
        &:hover,
        &:focus,
        &.mat-active.mat-selected {
          background-color: ruf-theming.ruf-color($background, hover);
          color: $ruf-select-value-text;
        }

        &.mat-selected:not(:hover):not(.mat-active):not(:focus) {
          background-color: transparent;
          color: $ruf-select-value-text;
        }
      }

      .mat-option.mat-option-disabled {
        color: $ruf-input-disabled-option-color;
        opacity: $ruf-input-disabled-opacity;
      }
    }
  }
}

@mixin ruf-mat-select-panel-typography($config, $cls: '.fis-style') {
  .mat-select-panel-wrap .mat-select-panel#{$cls}, .mat-select-panel-wrap .mat-select-panel {
    .mat-option.ruf-select-multiline {
      line-height: ruf-theming.ruf-line-height($config, body-1);
    }
    .mat-option {
        font-size: ruf-theming.ruf-font-size($config, body-2);
      }
  }
  }
