@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-badge($cls: '[fisStyle]', $config: ()) {

  // stylelint-disable block-no-empty
  .mat-badge-hidden.mat-badge-medium#{$cls} {
    .mat-badge-content:empty {
      display: inline-block;
      height: ruf-theming.ruf-to-rem(12px);
      width: ruf-theming.ruf-to-rem(12px);
    }

    &.mat-badge-after .mat-badge-content:empty {
      right: ruf-theming.ruf-to-rem(-3px);

      [dir='rtl'] & {
        left: ruf-theming.ruf-to-rem(-3px);
        right: auto;
      }
    }

    &.mat-badge-before .mat-badge-content:empty {
      left: ruf-theming.ruf-to-rem(-3px);

      [dir='rtl'] & {
        left: auto;
        right: ruf-theming.ruf-to-rem(-3px);
      }
    }

    &.mat-badge-above .mat-badge-content:empty {
      top: ruf-theming.ruf-to-rem(-5px);
    }

    &.mat-badge-below .mat-badge-content:empty {
      bottom: ruf-theming.ruf-to-rem(-2px);
    }
  }

  .ruf-badge-oval {
    &.mat-badge-medium .mat-badge-content:not(:empty) {
      padding-left: ruf-theming.ruf-to-rem(4px);
      padding-right: ruf-theming.ruf-to-rem(4px);
      width: auto;
    }

    &.mat-badge-before .mat-badge-content:not(:empty) {
      border-radius: ruf-theming.ruf-to-rem(16px);
      left: ruf-theming.ruf-to-rem(-20px);
      [dir='rtl'] & {
        left: auto;
        right: ruf-theming.ruf-to-rem(-20px);
      }
    }

    &.mat-badge-after .mat-badge-content:not(:empty) {
      border-radius: ruf-theming.ruf-to-rem(16px);
      right: ruf-theming.ruf-to-rem(-20px);

      [dir='rtl'] & {
        left: ruf-theming.ruf-to-rem(-20px);
        right: auto;
      }
    }
  }
}
