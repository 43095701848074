// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-expansion-panel-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $header: ruf-theming.ruf-map-get($config, header, ());

  .mat-expansion-panel#{$cls} {
    outline-color: ruf-theming.ruf-map-get($config, outline-color, transparent);

    &:not(.mat-expanded) {
      .mat-expansion-panel-header {
        &:hover:not([aria-disabled=true]) {
          background-color: ruf-theming.ruf-map-get($header, background-color, null);
        }
      }
    }

    .mat-expansion-panel-header {
      background-color: ruf-theming.ruf-map-get($header, background-color, ruf-theming.ruf-color($background, background));
      border-color: ruf-theming.ruf-map-get($header, border-color, ruf-theming.ruf-color($foreground, subtle-divider));

      &.mat-expanded:focus,
      &.mat-expanded:hover,
      &.cdk-keyboard-focused:not([aria-disabled=true]),
      &.cdk-program-focused:not([aria-disabled=true]) {
        background-color: ruf-theming.ruf-map-get($header, background-color, ruf-theming.ruf-color($background, background));
      }

      &:hover,
      &:focus,
      &.cdk-keyboard-focused:not([aria-disabled=true]),
      &.cdk-program-focused:not([aria-disabled=true]) {
        .mat-expansion-indicator {
          background-color: ruf-theming.ruf-map-get($header, icon-hover, null);
          border-radius: 50%;
        }
      }

      .mat-expansion-panel-header-title {
        color: ruf-theming.ruf-map-get($header, title-color, ruf-theming.ruf-color($primary));
      }

      &[aria-disabled='true'] {
        .mat-expansion-panel-header-title {
          color: ruf-theming.ruf-map-get($foreground, disabled-text, null);
        }
      }

      .mat-expansion-panel-header-description,
      .mat-expansion-indicator::after {
        color: ruf-theming.ruf-color($foreground, text);
      }
    }

    .mat-expansion-panel-body {
      background: ruf-theming.ruf-color($background, panel);
      border-color: ruf-theming.ruf-color($foreground, subtle-divider);
    }

    &.ruf-amorphous-expansion-panel {
      @include ruf-amorphous-expansion-panel-theme($config);
    }
  }

  .mat-accordion.ruf-amorphous-expansion-panels {
    .mat-expansion-panel#{$cls} {
      @include ruf-amorphous-expansion-panel-theme($config);
    }
  }
}

@mixin ruf-amorphous-expansion-panel-theme($config: ()) {
  background-color: ruf-theming.ruf-map-get($config, amorphous-background, null);

  .mat-expansion-panel-header {
    background-color: ruf-theming.ruf-map-get($config, amorphous-background, null);

    &:not(.mat-expanded):focus,
    &:not(.mat-expanded):hover {
      background-color: ruf-theming.ruf-map-get($config, amorphous-background, null);
    }

    &.mat-expanded:focus,
    &.mat-expanded:hover,
    &.cdk-keyboard-focused:not([aria-disabled=true]),
    &.cdk-program-focused:not([aria-disabled=true]) {
      background-color: ruf-theming.ruf-map-get($config, amorphous-background, null);
    }
  }

  .mat-expansion-panel-body {
    background-color: ruf-theming.ruf-map-get($config, amorphous-background, null);
  }
}

@mixin ruf-mat-expansion-panel-typography($config, $cls: '[fisStyle]', $override-config: ()) {
   .mat-expansion-panel#{$cls} {
    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
            font-size: ruf-theming.ruf-map-get($override-config, font-size, null);
            font-weight: ruf-theming.ruf-map-get($override-config, font-weight, ruf-theming.ruf-font-weight($config, expansion-panel));
            line-height: ruf-theming.ruf-map-get($override-config, line-height, null);
      }
    }
  }
}
