@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-button-density($cls: '[fisStyle]', $config: (), $density: 0) {
  $height: ruf-theming.ruf-map-get($config, height, 34px);
  $density-interval: 4px;
  $height: if($density == 0, $height, $height + $density-interval * ($density - 1));
  $line-height: $height - $density-interval;

  .mat-button#{$cls}, .mat-raised-button#{$cls}, .mat-stroked-button#{$cls}, .mat-flat-button#{$cls} {
    height: $height;
    line-height: $line-height;
  }

  .mat-icon-button#{$cls},
  #{$cls} .mat-icon-button {
    &:not(.fis-icon-button-xs):not(.fis-icon-button-sm):not(.fis-icon-button-md):not(.fis-icon-button-lg):not(.fis-icon-button-xl) {
      height: $height;
      width: $height;
    }

    &.fis-icon-button-xs {
      height: ruf-theming.ruf-to-rem(24px);
      width: ruf-theming.ruf-to-rem(24px);
    }

    &.fis-icon-button-sm {
      height: ruf-theming.ruf-to-rem(34px);
      width: ruf-theming.ruf-to-rem(34px);
    }

    &.fis-icon-button-md {
      height: ruf-theming.ruf-to-rem(36px);
      width: ruf-theming.ruf-to-rem(36px);
    }

    &.fis-icon-button-lg {
      height: ruf-theming.ruf-to-rem(40px);
      width: ruf-theming.ruf-to-rem(40px);
    }

    &.fis-icon-button-xl {
      height: ruf-theming.ruf-to-rem(48px);
      width: ruf-theming.ruf-to-rem(48px);
    }

    &:not(a) {
      .mat-button-wrapper {
        @if($density == -1) {
          display: flex;
          justify-content: center;
        }
        @if($density == -2) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  a.mat-icon-button#{$cls},
  #{$cls} a.mat-icon-button {
    height: $height;
    line-height: $line-height;
  }
}
// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-button($cls: '[fisStyle]', $config: ()) {
  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(3px));
  $button-icon-label-space: ruf-theming.ruf-map-get(
    $config,
    icon-label-space,
   ruf-theming.ruf-to-rem(10px)
  );
  $line-height: ruf-theming.ruf-map-get($config, line-height, ruf-theming.ruf-to-rem(34px));
  $line-height-with-border: ruf-theming.ruf-map-get(
    $config,
    line-height-with-border,
   ruf-theming.ruf-to-rem(32px)
  );
  $fixed-width: ruf-theming.ruf-map-get($config, fixed-width, ruf-theming.ruf-to-rem(164px));
  $padding-top: ruf-theming.ruf-map-get($config, padding-top, ruf-theming.ruf-to-rem(0px));
  $padding-bottom: ruf-theming.ruf-map-get($config, padding-bottom, ruf-theming.ruf-to-rem(0px));
  $padding-left: ruf-theming.ruf-map-get($config, padding-left, ruf-theming.ruf-to-rem(15px));
  $padding-right: ruf-theming.ruf-map-get($config, padding-right, ruf-theming.ruf-to-rem(15px));
  $text-transform: ruf-theming.ruf-map-get($config, text-transform, capitalize);
  $border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(1px));

  $active-border-width: ruf-theming.ruf-map-get($config, active-border-width, ruf-theming.ruf-to-rem(1px));
  $active-border-style: ruf-theming.ruf-map-get($config, active-border-style, solid);

  .mat-button#{$cls}:not(a) {
    &:not([disabled]).cdk-focused.cdk-keyboard-focused, &:not([disabled]).cdk-focused.cdk-program-focused {
      border-width: 0;
    }
  }

  .mat-button#{$cls}, .mat-raised-button#{$cls} {
    border-radius: $border-radius;
    border-width: $border-width;
    padding: $padding-top $padding-right $padding-bottom $padding-left;
    text-transform: $text-transform;
    transition: background-color 0.3s ease-out;

    &[ruf-ghost-button], &.ruf-ghost-button#{$cls} {
       &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
        border-style: solid;
        border-width: ruf-theming.ruf-to-rem(2px);
      }
   }

   &[fixedWidth] {
      overflow: hidden;
      text-overflow: ellipsis;
      width: $fixed-width;
    }

    .mat-ripple {
      display: none;
    }

    &:not([disabled]).cdk-keyboard-focused,
    &:not([disabled]).cdk-program-focused {
      border-style: $active-border-style;
      border-width: $active-border-width;
    }
  }

  .mat-fab#{$cls} {
    &:not([disabled]) {
      border-style: $active-border-style;
      border-width: $active-border-width;
      .mat-button-wrapper {
        padding: ruf-theming.ruf-to-rem(14px) ruf-theming.ruf-to-rem(0px);
      }
    }
  }

  .mat-mini-fab#{$cls} {
    &:not([disabled]) {
      border-style: $active-border-style;
      border-width: $active-border-width;
      .mat-button-wrapper {
        padding: ruf-theming.ruf-to-rem(6px) ruf-theming.ruf-to-rem(0px);
      }
    }
  }

  .mat-raised-button#{$cls} {
    border: $border-width solid;
  }

  a.mat-button#{$cls},
  a.mat-raised-button#{$cls},
  a.mat-flat-button#{$cls} {
    border: $border-width solid;

    &[fixedWidth] {
      overflow: hidden;
      text-overflow: ellipsis;
      width: $fixed-width;
    }
  }

  a.mat-button > .mat-button-focus-overlay {
    display: none;
  }

  .mat-icon-button#{$cls},
  #{$cls} .mat-icon-button {
    line-height: normal;

    .mat-icon {
      height: auto;
      line-height: normal;
      width: auto;
      &.mat-error {
        /*
          material adds "display: block" css property in mat-error class
          and material does not have any error palette for button as of now.
          and we are using error color for icons so, overriding this property over here.
        */
        display: inline-block;
      }
    }

    &.mat-error {
      /*
        material adds "display: block" css property in mat-error class
        and material does not have any error palette for button as of now.
        and we are using error color for icons so, overriding this property over here.
      */
      display: inline-block;
    }

    &:not([disabled]).cdk-keyboard-focused {
      border-style: $active-border-style;
      border-width: $active-border-width;
    }

    &[ruf-ghost-button], &.ruf-ghost-button#{$cls} {
      &:not([disabled]).cdk-keyboard-focused, &:not([disabled]).cdk-program-focused {
       border-style: solid;
       border-width: ruf-theming.ruf-to-rem(2px);;
     }
  }
  }

  a.mat-icon-button#{$cls},
  #{$cls} a.mat-icon-button {
   line-height: ruf-theming.ruf-to-rem(30px);

   &:not([disabled]).cdk-keyboard-focused,
   &:not([disabled]).cdk-program-focused {
     .mat-button-wrapper {
       .mat-icon {
         position: relative;
         top: calc($active-border-width * -1);
       }
     }
   }
  }

  .mat-stroked-button#{$cls}, .mat-flat-button#{$cls} {
    border: $border-width solid;
    border-radius: $border-radius;
    padding: $padding-top $padding-right $padding-bottom $padding-left;
    text-transform: $text-transform;
    &[fixedWidth] {
      overflow: hidden;
      text-overflow: ellipsis;
      width: $fixed-width;
    }
  }

  // ruf-labeled-icon with mat-button
  .mat-button-base#{$cls},
  .mat-button#{$cls} {
    .ruf-labeled-icon.fis-override {
      &:not(.ruf-labeled-icon-rtl) {
        > span.ruf-icon-before {
          margin-left: $button-icon-label-space;
        }

        > span.ruf-icon-after {
          margin-right: $button-icon-label-space;
        }
      }

      &.ruf-labeled-icon-rtl {
        > span.ruf-icon-before {
          margin-right: $button-icon-label-space;
        }

        > span.ruf-icon-after {
          margin-left: $button-icon-label-space;
        }
      }
    }

    // Disable styles
    &.mat-button-disabled {
      cursor: ruf-theming.$ruf-disabled-cursor;
    }
  }
  .ruf-button-wrapper {
    display: flex;
    > button,
    a.mat-button,
    a.mat-raised-button,
    a.mat-flat-button,
    a.mat-stroked-button {
      flex: 1;
    }
  }

  .ruf-stacked-buttons {
    display: flex;
    flex-direction: column;
    > button,
    a.mat-button,
    a.mat-raised-button,
    a.mat-flat-button,
    a.mat-stroked-button {
      margin-bottom: ruf-theming.ruf-to-rem(8px);
      &.mat-button-base + .mat-button-base {
        margin-left: 0px;
        [dir='rtl'] & {
          margin-left: ruf-theming.ruf-to-rem(8px);
        }
      }
    }
  }

  @include _ruf-split-button('right', 'left', $config, $cls);

  [dir='rtl'] {
    @include _ruf-split-button('left', 'right', $config, $cls);
  }
}

@mixin _ruf-split-button($dir1: 'right', $dir2: 'left', $config: (), $cls: '[fisStyle]') {
  $split-config: ruf-theming.ruf-map-get($config, split, ());
  $split-icon-config: ruf-theming.ruf-map-get($split-config, icon, ());
  $split-icon-min-width: ruf-theming.ruf-map-get($split-icon-config, min-width, ruf-theming.ruf-to-rem(36px));
  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(17px));

  .ruf-split-buttons {
    border-radius: $border-radius;
    display: inline-flex;

    .mat-button-base#{$cls} {
      &:not(.mat-stroked-button) {
        border-bottom-width: ruf-theming.ruf-to-rem(0px);
        border-top-width: ruf-theming.ruf-to-rem(0px);
      }

      &:hover, &.cdk-focused.cdk-keyboard-focused {
        border-bottom-width: ruf-theming.ruf-to-rem(2px);
        border-top-width: ruf-theming.ruf-to-rem(2px);
      }

      &:nth-child(1) {
        border-top-#{$dir1}-radius: ruf-theming.ruf-to-rem(0px);
        border-bottom-#{$dir1}-radius: ruf-theming.ruf-to-rem(0px);
        border-top-#{$dir2}-radius: $border-radius;
        border-bottom-#{$dir2}-radius: $border-radius;
        border-#{$dir1}-width: ruf-theming.ruf-to-rem(1px);
        border-#{$dir2}-width: ruf-theming.ruf-to-rem(2px);
      }

      &:nth-child(2) {
        border-top-#{$dir2}-radius: ruf-theming.ruf-to-rem(0px);
        border-bottom-#{$dir2}-radius: ruf-theming.ruf-to-rem(0px);
        border-top-#{$dir1}-radius: $border-radius;
        border-bottom-#{$dir1}-radius: $border-radius;
        border-#{$dir2}-width: ruf-theming.ruf-to-rem(1px);
        border-#{$dir1}-width: ruf-theming.ruf-to-rem(2px);
      }

      &.ruf-split-icon-button {
        min-width: $split-icon-min-width;
        padding: ruf-theming.ruf-to-rem(0px);
      }

      &.mat-button:not(.mat-stroked-button):not(.mat-raised-button) {
        &:nth-child(1) {
          border-#{$dir1}-width: ruf-theming.ruf-to-rem(1px);
          border-#{$dir2}-width: ruf-theming.ruf-to-rem(2px);
        }
        &:nth-child(2) {
          border-#{$dir2}-width: ruf-theming.ruf-to-rem(1px);
          border-#{$dir1}-width: ruf-theming.ruf-to-rem(2px);
        }
      }

      &.cdk-focused.cdk-keyboard-focused:nth-child(1) {
        z-index: 1;
      }
    }
  }
}
