@use 'sass:map';
@use 'sass:meta';
@use '@ruf/theming' as ruf-theming;

// If background color is a css variable then we have to apply opacity separately.
// FIXME: This should be removed once @angular/material fix this issue.
@mixin _ruf-slide-toggle-background-opacity($palette, $thumb-checked-hue, $opacity) {
  $background-color: if(meta.type-of($palette) !='map', $palette, ruf-theming.ruf-color($palette, $thumb-checked-hue, $opacity));

  @if (meta.type-of($background-color) !=color) {
    background-color: transparent; // unset does not work in IE.

    &::before {
      background-color: $background-color;
      border-radius: ruf-theming.ruf-to-rem(8px);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  } @else {
    background-color: $background-color;
  }
}

@mixin ruf-mat-slide-toggle-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $theme-colors: (primary: $primary,
      accent: $accent,
      warn: $warn);

  $slide-toggle-thumb: ruf-theming.ruf-map-get($config, slide-toggle-thumb, ());
  $slide-toggle-bar: ruf-theming.ruf-map-get($config, slide-toggle-bar, ());
  $slide-toggle-disabled: ruf-theming.ruf-map-get($config, disabled, ());
  $ripple: ruf-theming.ruf-map-get($config, ripple, ());
  $ripple-checked: ruf-theming.ruf-map-get($ripple, checked, ());
  $ripple-unchecked: ruf-theming.ruf-map-get($ripple, unchecked, ());

  $slide-toggle-disabled-opacity: ruf-theming.ruf-map-get($slide-toggle-disabled, opacity, 0.5);
  $slide-toggle-thumb-disabled: ruf-theming.ruf-map-get($slide-toggle-disabled, slide-toggle-thumb, ());
  $slide-toggle-label-disabled: ruf-theming.ruf-map-get($slide-toggle-disabled, slide-toggle-label, ());
  $slide-toggle-bar-disabled: ruf-theming.ruf-map-get($slide-toggle-disabled, slide-toggle-bar, ());

  $slide-toggle-thumb-box-shadow: ruf-theming.ruf-map-get($slide-toggle-thumb, box-shadow, none);
  $slide-toggle-thumb-border-color: ruf-theming.ruf-map-get($slide-toggle-thumb, border-color, transparent);


  // stylelint-disable block-no-empty
  .mat-slide-toggle#{$cls} {
    .mat-slide-toggle-thumb {
      border-color: $slide-toggle-thumb-border-color;
      box-shadow: $slide-toggle-thumb-box-shadow;
    }

    &:not(.mat-disabled) {
      &.mat-checked {
        .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
          opacity: ruf-theming.ruf-map-get($ripple-checked, opacity, 0.04);
        }
      }

      .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
        opacity: ruf-theming.ruf-map-get($ripple-unchecked, opacity, 0.04);
      }
    }



    &:not(.mat-checked) {
      .mat-slide-toggle-thumb {
        background-color: ruf-theming.ruf-color($background, contrast);
      }

      .mat-slide-toggle-bar {
        background-color: ruf-theming.ruf-map-get($slide-toggle-bar, background-color, ruf-theming.ruf-color($background, contrast-overlay-2));
      }

      &.mat-disabled {
        opacity: $slide-toggle-disabled-opacity;

        .mat-slide-toggle-thumb {
          background-color: ruf-theming.ruf-map-get($slide-toggle-thumb-disabled, background-color, null);
          opacity: $slide-toggle-disabled-opacity;
        }

        .mat-slide-toggle-bar {
          background-color: ruf-theming.ruf-map-get($slide-toggle-bar-disabled, background-color, null);
          opacity: $slide-toggle-disabled-opacity;
        }

        .mat-slide-toggle-label {
          .mat-slide-toggle-content {
            // text color is used for disabled because we are using opacity 0.5 for disabled label
            color: ruf-theming.ruf-map-get($slide-toggle-label-disabled, color, ruf-theming.ruf-color($foreground, text));
            opacity: ruf-theming.ruf-map-get($slide-toggle-label-disabled, opacity, 0.5);
          }
        }
      }
    }

    @each $name,
    $color in $theme-colors {
      &.mat-#{$name} {
        &.mat-checked {
          &.mat-disabled {
            opacity: $slide-toggle-disabled-opacity;

            .mat-slide-toggle-bar {
              $background-color: ruf-theming.ruf-map-get($slide-toggle-bar-disabled, background-color, $color);
              @include _ruf-slide-toggle-background-opacity($background-color, default, 0.54);
            }

            .mat-slide-toggle-thumb {
              background-color: ruf-theming.ruf-map-get($slide-toggle-thumb-disabled, background-color, null);
            }

            .mat-slide-toggle-label {
              .mat-slide-toggle-content {
                opacity: ruf-theming.ruf-map-get($slide-toggle-label-disabled, opacity, 0.5);
              }
            }
          }

          &:not(.mat-disabled) .mat-slide-toggle-bar {
            @include _ruf-slide-toggle-background-opacity($color, default, 0.54);
          }
        }
      }
    }
  }
}

@mixin ruf-mat-slide-toggle-typography($config, $cls: '[fisStyle]') {
  .mat-slide-toggle#{$cls} {
    .mat-slide-toggle-label {
      .mat-slide-toggle-content {
        @include ruf-theming.ruf-typography-level-to-styles($config, subheading-1);
      }
    }
  }
}
