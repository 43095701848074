@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-table-sort-icon($variant: 'solid-arrow', $config: ()) {
  $header-config: ruf-theming.ruf-map-get($config, mat-header-row);
  $solid-icon-postion: ruf-theming.ruf-map-get($header-config, sorting);
  $icon-top-position: ruf-theming.ruf-map-get($solid-icon-postion, top, ruf-theming.ruf-to-rem(-4.5px));
  // Hide default arrow stem
  .mat-sort-header-stem {
      display: none;
   }
  .mat-sort-header-indicator {
    font-family: 'fisfont';
    @if($variant == 'solid-arrow') {
      transform: translateY(0) !important; // override inline styles
    }
    @if($variant == 'alphabetical') {
      transform: translateY(0) !important; // override inline styles
    }
    &::before {
      position: absolute;
      @if ($variant == 'solid-arrow') {
        content: '\e968';
        top: $icon-top-position;
      }
    }
    &::before {
      // position: alphabetical;
      @if ($variant == 'alphabetical') {
        content: '\e95f';
        top: ruf-theming.ruf-to-rem(-3.2px);
      }
    }
    // Hide default arrow
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
   }

  .mat-header-cell[aria-sort='none'] {
    &:hover {
      .mat-sort-header-indicator {
        font-family: 'fisfont';
        &::before {
          @if ($variant == 'solid-arrow') {
            content: '\e904';
            opacity: 0.54; // Added opacity to lighten the icon on hover
            top: ruf-theming.ruf-to-rem(-10px);
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        font-family: 'fisfont';
        &::before {
          @if($variant == 'solid-arrow') {
            content: '\e904';
            top: ruf-theming.ruf-to-rem(-10px);
            transform: rotate(180deg);
          }

          @if($variant == 'alphabetical') {
            content: '\e95f';
            top: ruf-theming.ruf-to-rem(-3.2px);
          }
        }
      }
    }
  }

  [aria-sort='descending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        font-family: 'fisfont';
          &::before {
            @if($variant == 'solid-arrow') {
              content: '\e904';
              top: ruf-theming.ruf-to-rem(-5px);
            }

            @if($variant == 'alphabetical') {
              content: '\e961';
              top: ruf-theming.ruf-to-rem(-3.2px);
            }
        }
      }
    }
  }
}
// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-datatable($cls: '[fisStyle]', $config: ()) {

  $header-config: ruf-theming.ruf-map-get($config, mat-header-row);
  $row-config: ruf-theming.ruf-map-get($config, mat-row);

  // table
  $table-border-bottom-width: ruf-theming.ruf-map-get($config, border-bottom-width, ruf-theming.ruf-to-rem(1px));
  $table-border-bottom-style: ruf-theming.ruf-map-get($config, border-bottom-style, solid);

  // row density
  $row-density-medium: ruf-theming.ruf-map-deep-get($row-config, density, medium);
  $row-density-large: ruf-theming.ruf-map-deep-get($row-config, density, large);
  $row-density-small: ruf-theming.ruf-map-deep-get($row-config, density, small);

  // header
  $header-height: ruf-theming.ruf-map-get($header-config, height, auto);
  $header-padding-horizontal: ruf-theming.ruf-map-get($header-config, padding-horizontal, ruf-theming.ruf-to-rem(10px));
  $header-padding-vertical: ruf-theming.ruf-map-get($header-config, padding-vertical, ruf-theming.ruf-to-rem(10px));

  $header-text-transform: ruf-theming.ruf-map-get($header-config, text-transform, capitalize);
  $header-margin-horizontal: ruf-theming.ruf-map-get($header-config, margin-horizontal, ruf-theming.ruf-to-rem(5px));
  $header-margin-vertical: ruf-theming.ruf-map-get($header-config, margin-vertical, auto);
  $border-bottom-width: ruf-theming.ruf-map-get($header-config, border-bottom-width, ruf-theming.ruf-to-rem(4px));
  $border-bottom-style: ruf-theming.ruf-map-get($header-config, border-bottom-style, solid);

  // row
  $row-padding-horizontal: ruf-theming.ruf-map-get($row-config, padding-horizontal, ruf-theming.ruf-to-rem(10px));
  $row-padding-vertical: ruf-theming.ruf-map-get($row-config, padding-vertical, ruf-theming.ruf-to-rem(15px));
  $row-height: ruf-theming.ruf-map-get($row-config, height, ruf-theming.ruf-to-rem(50px));
  $row-border-width: ruf-theming.ruf-map-get($config, 'row-border-width', ruf-theming.ruf-to-rem(3px));
  $row-margin-horizontal: ruf-theming.ruf-map-get($row-config, margin-horizontal, ruf-theming.ruf-to-rem(5px));
  $row-margin-vertical: ruf-theming.ruf-map-get($row-config, margin-vertical, auto);

  // detail row
  $detail-row: ruf-theming.ruf-map-deep-get($row-config, detail-row);
  $detail-row-height: ruf-theming.ruf-map-get($detail-row, height, ruf-theming.ruf-to-rem(50px));
  $checkbox-size: ruf-theming.ruf-map-get($row-config, checkbox-size, 20px);
  $ruf-density-small-checkbox-size: ruf-theming.ruf-map-get($row-density-small, checkbox-size, 15px);

  // card row
  $card-row: ruf-theming.ruf-map-deep-get($row-config, card-row);
  $border-horizontal-spacing: ruf-theming.ruf-map-get($card-row, border-horizontal-spacing, 0);
  $border-vertical-spacing: ruf-theming.ruf-map-get($card-row, border-vertical-spacing, ruf-theming.ruf-to-rem(0px));

  .mat-table#{$cls} {
    border-bottom-style: $table-border-bottom-style;
    border-bottom-width: $table-border-bottom-width;

    [mat-sort-header].cdk-program-focused .mat-sort-header-container,
    [mat-sort-header].cdk-keyboard-focused .mat-sort-header-container {
      border-bottom: none;
    }
    mat-header-row {
      border-bottom-style: none;
    }
    .mat-header-row {
      height:  $header-height;
      padding: 0;
      * {
        text-transform: $header-text-transform;
       }
      .mat-header-cell {
        border-bottom-style: $border-bottom-style;
        border-bottom-width: $border-bottom-width;
        padding: $header-padding-vertical $header-padding-horizontal;

        .mat-sort-header-container {

          justify-content: space-between;

          .mat-sort-header-stem {
            display: none;
          }

          // TODO: https://github.com/angular/components/issues/23048
          .mat-sort-header-pointer-right {
            left: ruf-theming.ruf-to-rem(6.4px);
            right: unset;
          }
        }
        .mat-checkbox-inner-container {
          margin: $header-margin-vertical $header-margin-horizontal;
        }
      }
    }

    .mat-row, .mat-footer-row {
      height: $row-height;
      .mat-cell, .mat-footer-cell {
        padding-bottom: $row-padding-vertical;
        padding-left: $row-padding-horizontal;
        padding-right: $row-padding-horizontal;
        padding-top: $row-padding-vertical;
        .mat-checkbox-inner-container {
          margin: $row-margin-vertical $row-margin-horizontal;
        }
      }
      &.ruf-detail-row {
        height: $detail-row-height;
      }
    }
    &.ruf-density-medium, &.ruf-density-small {
      .mat-row, .mat-footer-row {
        height: auto;
      }
    }

      mat-header-row {
        min-height: ruf-theming.ruf-map-get($header-config, min-height, ruf-theming.ruf-to-rem(56px));
      }

      mat-row {
        min-height: ruf-theming.ruf-map-get($row-config, min-height, ruf-theming.ruf-to-rem(48px));
      }

    &.ruf-striped-rows, &.striped-rows {
      .mat-row {
        .mat-cell {
          border-bottom: none;
        }
      }
    }

    &.ruf-rows-without-border, &.rows-without-border {
      .mat-row {
        .mat-cell {
          border-bottom: none;
        }
      }
    }
    &[row-border=true] {
      .mat-row {
        .mat-cell:first-child {
          border-left-style: solid;
          border-left-width: $row-border-width;
          [dir='rtl'] & {
            border-left: none;
            border-right-style: solid;
            border-right-width: $row-border-width;
          }
        }
      }
    }
    &.ruf-density-large {
      .mat-row, .mat-footer-row {
        .mat-cell {
          padding-bottom: ruf-theming.ruf-map-get($row-density-large, padding-bottom, ruf-theming.ruf-to-rem(15px));
          padding-top: ruf-theming.ruf-map-get($row-density-large, padding-top, ruf-theming.ruf-to-rem(15px));
        }
      }
    }
    &.ruf-density-medium {
      .mat-row, .mat-footer-row {
        &.ruf-detail-row .mat-cell {
          height: ruf-theming.ruf-map-get($row-density-medium, height, ruf-theming.ruf-to-rem(40px));
          padding-bottom: ruf-theming.ruf-map-get($row-density-medium, padding-bottom, ruf-theming.ruf-to-rem(10px));
          padding-top: ruf-theming.ruf-map-get($row-density-medium, padding-top, ruf-theming.ruf-to-rem(10px));

        }
        .mat-cell:not(.mat-column-expandedDetail) {
          height: ruf-theming.ruf-map-get($row-density-medium, height, ruf-theming.ruf-to-rem(40px));
          padding-bottom: ruf-theming.ruf-map-get($row-density-medium, padding-bottom, ruf-theming.ruf-to-rem(10px));
          padding-top: ruf-theming.ruf-map-get($row-density-medium, padding-top, ruf-theming.ruf-to-rem(10px));
        }
      }
    }
    &.ruf-density-small {
      .mat-row, .mat-footer-row {
        &.ruf-detail-row .mat-cell {
          height: ruf-theming.ruf-map-get($row-density-small, height, ruf-theming.ruf-to-rem(30px));
          padding-bottom: ruf-theming.ruf-map-get($row-density-small, padding-bottom, ruf-theming.ruf-to-rem(5px));
          padding-top: ruf-theming.ruf-map-get($row-density-small, padding-top, ruf-theming.ruf-to-rem(5px));
        }
        .mat-cell:not(.mat-column-expandedDetail) {
          height: ruf-theming.ruf-map-get($row-density-small, height, ruf-theming.ruf-to-rem(30px));
          padding-bottom: ruf-theming.ruf-map-get($row-density-small, padding-bottom, ruf-theming.ruf-to-rem(5px));
          padding-top: ruf-theming.ruf-map-get($row-density-small, padding-top, ruf-theming.ruf-to-rem(5px));
        }
      }
    }

    &.ruf-card-rows {
      border-collapse: separate;
      padding-left: ruf-theming.ruf-to-rem(10px);
      padding-right: ruf-theming.ruf-to-rem(10px);
      .mat-row,
      .mat-header-row,
      .mat-footer-row,
      th.mat-header-cell,
      td.mat-footer-cell,
      td.mat-cell {
        cursor: auto;
      }

      tr:not(.ruf-expansion-row) {
        td.mat-cell {
          border-bottom-width: $border-vertical-spacing;
        }
      }
    }

    &.ruf-sort-icon-solid-arrow {
      @include ruf-mat-table-sort-icon('solid-arrow', $config);
      .mat-sort-header-arrow {
        // override inline styles
        opacity: 1 !important;
        transform: translateY(25%) !important;
      }
    }

    &.ruf-sort-icon-alphabetical {
      @include ruf-mat-table-sort-icon('alphabetical');
    }

    &.ruf-sort-icon-arrow {
      .mat-header-row
      .mat-header-cell
      .mat-sort-header-container {
        .mat-sort-header-stem {
          display: flex;
        }
      }
    }
  }
}
