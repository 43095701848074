@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-input($cls: '[fisStyle]', $config: ()) {

  .mat-form-field#{$cls} {
    .mat-form-field-label {
      text-transform: capitalize;
    }
  }

  // stylelint-disable block-no-empty
  .mat-form-field.mat-form-field-appearance-legacy#{$cls},
  *#{$cls} .mat-form-field.mat-form-field-appearance-legacy {

  }
}
