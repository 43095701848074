@use '@ruf/theming' as ruf-theming;

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-card($cls: '[fisStyle]', $config: ()) {

  $ruf-card-header: ruf-theming.ruf-map-get($config, card-header, ());
  $ruf-card-image: ruf-theming.ruf-map-get($config, card-image, ());
  $ruf-card: ruf-theming.ruf-map-get($config, card, ());

  $border-radius: ruf-theming.ruf-map-get($ruf-card, border-radius, ruf-theming.ruf-to-rem(2px));
  $padding-vertical: ruf-theming.ruf-map-get($ruf-card, padding-top, ruf-theming.ruf-to-rem(16px));
  $padding-horizontal: ruf-theming.ruf-map-get($ruf-card, padding-left, ruf-theming.ruf-to-rem(16px));
  $ruf-card-header-height: ruf-theming.ruf-map-get($ruf-card-header, height, auto);
  $ruf-card-header-min-height: ruf-theming.ruf-map-get($ruf-card-header, min-height, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 40px));
  $ruf-card-header-left-padding: ruf-theming.ruf-map-get($ruf-card-header, padding-left, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 12px));
  $ruf-card-header-right-padding: ruf-theming.ruf-map-get($ruf-card-header, padding-right, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 12px));
  $ruf-card-border-width: ruf-theming.ruf-map-get($ruf-card, border-width, ruf-theming.ruf-to-rem(ruf-theming.$ruf-vunit * 0px));
  $ruf-card-border-style: ruf-theming.ruf-map-get($ruf-card, border-style, solid);
  $ruf-card-image-margin-left: ruf-theming.ruf-map-get($ruf-card-image, margin-left, -16px);
  $ruf-card-image-margin-right: ruf-theming.ruf-map-get($ruf-card-image, margin-right, -16px);
  $ruf-card-image-margin-top: ruf-theming.ruf-map-get($ruf-card-image, margin-top, 0px);
  $ruf-card-image-margin-bottom: ruf-theming.ruf-map-get($ruf-card-image, margin-bottom, 16px);
  $card-header-text-margin-horizontal: ruf-theming.ruf-map-get($ruf-card-header, margin-left, 16px);
  $card-header-text-margin-vertical: ruf-theming.ruf-map-get($ruf-card-header, margin-top, 0px);

  .mat-card#{$cls} {
    border-radius: $border-radius;
    border-style: $ruf-card-border-style;
    border-width: $ruf-card-border-width;
    padding:  $padding-vertical $padding-horizontal;

    .ruf-card-header {
      height: $ruf-card-header-height;
      min-height: $ruf-card-header-min-height;
      padding-left: $ruf-card-header-left-padding;
      padding-right: $ruf-card-header-right-padding;
    }

    .mat-card-header-text {
      margin: $card-header-text-margin-vertical $card-header-text-margin-horizontal;
    }

    .mat-card-image {
      margin:  $ruf-card-image-margin-top $ruf-card-image-margin-right $ruf-card-image-margin-bottom $ruf-card-image-margin-left;
    }

    .mat-card-title {
      text-transform: capitalize;
    }
  }
}
