@use 'sass:color';
@use 'sass:map';
@use '../core.variables';

// This file provides standard FIS palettes and functions to create your own if you wish.

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);
$default-dark-contrast: #012834;
$default-light-contrast:  #ffffff;

$base-color-weight-config: (50: 12,
  100: 30,
  200: 50,
  300: 70,
  400: 85,
  500: 100,
  600: 87,
  700: 70,
  800: 54,
  900: 35);

$default-dark-color-weight-config: (50: 60,
  100: 70,
  200: 80,
  300: 85,
  400: 90,
  500: 100,
  600: 80,
  700: 60,
  800: 50,
  900: 31);

@function ruf-palette-contrast($color, $dark: $default-dark-contrast, $light: $default-light-contrast) {
  @if (color.lightness($color) > 50) {
    @return if($dark, $dark, $default-dark-contrast); // Lighter background, return dark color
  } @else {
    @return if($light, $light, $default-light-contrast); // Darker background, return light color
  }
}

@function ruf-palette-from-color($color, $lighter: null, $darker: null, $dark-contrast: null,
$light-contrast: null, $color-weight: $base-color-weight-config, $overridden-palette: ()) {
  //from https://stackblitz.com/edit/angular-wtr2vx?file=src%2Fruf.scss
  $base-light: #fff;
  $base-dark: black;

  $base-dark: color.adjust($base-dark, $lightness: -15%);
  $base-triad: hsl(color.hue($color) * 270 % 360, color.saturation($color), color.lightness($color));
  // from https://github.com/bgrins/TinyColor/blob/master/tinycolor.js#L654
  // from https://github.com/mbitson/mcg/blob/master/scripts/controllers/ColorGeneratorCtrl.js#L241

  $a100: color.adjust(color.adjust(color.mix($base-triad, $base-dark, 15), $saturation: 80%), $lightness: 60%);
  $a200: color.adjust(color.adjust(color.mix($base-triad, $base-dark, 15), $saturation: 80%), $lightness: 50%);
  $a400: color.adjust(color.adjust(color.mix($base-triad, $base-dark, 15), $saturation: 100%), $lightness: 35%);
  $a700: color.adjust(color.adjust(color.mix($base-triad, $base-dark, 15), $saturation: 100%), $lightness: 25%);

  $lighter: if($lighter, $lighter, color.mix($color, $base-light, 30));
  $darker: if($darker, $darker, color.mix($color, $base-dark, 70));
  $default: color.mix($color, $base-light, 100);

  @return core.recursive-map-merge((
    50: color.mix($color, $base-light, map.get($color-weight, 50)),
    100: color.mix($color, $base-light, map.get($color-weight, 100)),
    200: color.mix($color, $base-light, map.get($color-weight, 200)),
    300: color.mix($color, $base-light, map.get($color-weight, 300)),
    400: color.mix($color, $base-light, map.get($color-weight, 400)),
    500: $default,
    600: color.mix($color, $base-dark, map.get($color-weight, 600)),
    700: color.mix($color, $base-dark, map.get($color-weight, 700)),
    800: color.mix($color, $base-dark, map.get($color-weight, 800)),
    900: color.mix($color, $base-dark, map.get($color-weight, 900)),
    A100: $a100,
    A200: $a200,
    A400: $a400,
    A700: $a700,

    contrast: (
      50: ruf-palette-contrast(color.mix($color, $base-light, map.get($color-weight, 50)), $dark-contrast, $light-contrast),
      100: ruf-palette-contrast(color.mix($color, $base-light, map.get($color-weight, 100)), $dark-contrast, $light-contrast),
      200: ruf-palette-contrast(color.mix($color, $base-light, map.get($color-weight, 200)), $dark-contrast, $light-contrast),
      300: ruf-palette-contrast(color.mix($color, $base-light, map.get($color-weight, 300)), $dark-contrast, $light-contrast),
      400: ruf-palette-contrast(color.mix($color, $base-light, map.get($color-weight, 400)), $dark-contrast, $light-contrast),
      500: ruf-palette-contrast($default, $dark-contrast, $light-contrast),
      600: ruf-palette-contrast(color.mix($color, $base-dark, map.get($color-weight, 600)), $dark-contrast, $light-contrast),
      700: ruf-palette-contrast(color.mix($color, $base-dark, map.get($color-weight, 700)), $dark-contrast, $light-contrast),
      800: ruf-palette-contrast(color.mix($color, $base-dark, map.get($color-weight, 800)), $dark-contrast, $light-contrast),
      900: ruf-palette-contrast(color.mix($color, $base-dark, map.get($color-weight, 900)), $dark-contrast, $light-contrast),
      A100: ruf-palette-contrast($a100, $dark-contrast, $light-contrast),
      A200: ruf-palette-contrast($a200, $dark-contrast, $light-contrast),
      A400: ruf-palette-contrast($a400, $dark-contrast, $light-contrast),
      A700: ruf-palette-contrast($a700, $dark-contrast, $light-contrast),
    )
  ),
  $overridden-palette);
}


$ruf-grey: ruf-palette-from-color(#666);
$ruf-teal: ruf-palette-from-color(#00565b, #00a4b6);
$ruf-mosque: ruf-palette-from-color(#00565b);
$ruf-new-blue: ruf-palette-from-color(#4dbfcc);


/* accent colors, used sparingly (less than 15%) to bring attention to an element. */
$ruf-green: ruf-palette-from-color(#8dc63f);
$ruf-blue: ruf-palette-from-color(#00a4b6);
$ruf-plum: ruf-palette-from-color(#872b62);
$ruf-gold: ruf-palette-from-color(#f5ad2a);
$ruf-aqua: ruf-palette-from-color(#05e7ff);

// plum color for dark theme
$ruf-light-plum: ruf-palette-from-color(map.get($ruf-plum, 200));

// Validation/Connotative Color
$ruf-orange: ruf-palette-from-color(#ff7512);
$ruf-dark-blue: ruf-palette-from-color(#0e3aa1);
$ruf-success: ruf-palette-from-color(#00b016);
$ruf-error: ruf-palette-from-color(#cc0a00);

// Validation Colors for Dark Theme
$ruf-light-orange: ruf-palette-from-color(#ff9040);
$ruf-light-blue: ruf-palette-from-color(#6f8ac7);
$ruf-light-success: ruf-palette-from-color(#4dc85c);
$ruf-light-error: ruf-palette-from-color(#ff7a73);


$ruf-emphasis: ruf-palette-from-color(#872b62);
$ruf-cyprus: ruf-palette-from-color(#003338);

/* colors, default theme */
$ruf-fis-primary: ruf-palette-from-color(#4bcd3e, $overridden-palette: (700: #308628, 800: #225c1c)); // primary

$ruf-fis-accent1: ruf-palette-from-color(#009775);   // accent1
$ruf-fis-accent2: ruf-palette-from-color(#015b7e); // accent2 fis-dark-green
$ruf-fis-accent-dark: ruf-palette-from-color(#a18cde); // dark-accent

$ruf-fis-emphasis1: ruf-palette-from-color(#ffcd00); // emphasis1
$ruf-fis-emphasis2: ruf-palette-from-color(#4c12a1); // emphasis2

// FIS Black
$ruf-fis-black: ruf-palette-from-color(#012834);
$ruf-fis-black-dark: ruf-palette-from-color(#012834, $color-weight: $default-dark-color-weight-config);

// Neutral colors
$ruf-fis-light-neutral-1: ruf-palette-from-color(#a7a7a7);
$ruf-fis-light-neutral-2: ruf-palette-from-color(#f9f5f1);

// Validation/Connotative Color default theme
$ruf-fis-orange: ruf-palette-from-color(#fd8d62, $overridden-palette: (700:#ad6143, 800: #713f2c));
$ruf-fis-info: ruf-palette-from-color(#3bcff0, $overridden-palette: (700:#247f94, 800: #1a5c6b));
$ruf-fis-success: ruf-palette-from-color(#4bcd3e, $overridden-palette: (700: #308628, 800: #225c1c));
$ruf-fis-error: ruf-palette-from-color(#ff1f3e, $overridden-palette: (800: #720e1c));


/* dark palette base values */
$ruf-dark-foreground-base: #f9f9f9;
$ruf-dark-background-base: #131313;

$ruf-dark-foreground-text: map.get($ruf-fis-light-neutral-1, 200);
$ruf-dark-foreground-secondary-text: map.get($ruf-fis-light-neutral-1, 500);
$ruf-dark-foreground-hint-text: map.get($ruf-fis-light-neutral-1, 500);

// base colors
$ruf-light-background-base: white;
$ruf-light-foreground-base: black;
// $ruf-dark-background-base: black;
// $ruf-dark-foreground-base: white;

// Background palette for light themes.
$ruf-light-theme-background: (
  base: $ruf-light-background-base,
  panel: $ruf-light-background-base, // used as base background color for widgets (and overlays) like expansion panel, date-picker etc.
  side: color.adjust($ruf-light-background-base, $lightness: -13.5%), // #ddd side panels
  app-bar:    $ruf-light-background-base, // material, used for banner and toolbar
  // #e5e5e5 UX site suggests white, RUF uses #f5f5f5. Not used by material
  app-canvas: color.adjust($ruf-light-background-base, $lightness: -10.25%),
  background: color.adjust($ruf-light-background-base, $lightness: -4.5%), // #f4f4f4 // FIS light-background
  hover:      rgba(black, 0.1),
  hover-dark: rgba($ruf-dark-foreground-base, 0.16), // hover color to be used in case of dark background
  halo:      rgba(black, 0.21),
  halo-dark: map.get($ruf-fis-black-dark, 200),
  selected-state: color.adjust($ruf-light-background-base, $lightness: -10.25%), // material breaking change in beta-10
  dialog:     $ruf-light-background-base, // material
  contrast-overlay-1: rgba(black, 0.9),
  contrast-overlay-2: rgba(black, 0.3),
  backdrop-overlay: rgba(black, 0.3),
  card:       $ruf-light-background-base,
  card-canvas: color.adjust($ruf-light-background-base, $lightness: -6.5%), // #eee // RUF addition deprecated
  disabled: map.get($ruf-grey, 200),
  footer:     color.adjust($ruf-light-background-base, $lightness: -6.5%), // #eee RUF addition
  nav-bar: $ruf-light-background-base,
  row: $ruf-light-background-base,
  alternate-row: color.adjust($ruf-light-background-base, $lightness: -4.5%), // #f4f4f4
  header-row: $ruf-light-background-base,

  // this section is deprecated. Do not use these values
  side-megamenu: color.adjust($ruf-light-background-base, $lightness: -13.5%), // #ddd, // RUF addition // deprecated
  icon: rgba(black, 0.3), // deprecated, use opacity-dark
  page-header: color.adjust($ruf-light-background-base, $lightness: -4%), //#f5f5f5 // deperecated, not used
  sidemenu-focus: map.get($ruf-fis-light-neutral-1, 100),
  // end deprecated sectaion

  // material
  status-bar: color.adjust($ruf-light-background-base, $lightness: -13.5%), // #ddd, // material
  disabled-button:   rgba(black, 0.155), // material
  raised-button: $ruf-light-background-base, // material
  focused-button: $black-6-opacity, // material
  selected-button: rgba(black, 0.53), // material (deprecated?)
  selected-disabled-button: map.get($ruf-grey, 400), // material
  selected-button-toggle: rgba(black, 0.21), // material
  disabled-button-toggle: map.get($ruf-grey, 200), // material
  unselected-chip: map.get($ruf-grey, 300), // material breaking change in beta-10
  disabled-list-option: map.get($ruf-grey, 200), // material breaking change in beta-10
  tooltip: color.adjust($ruf-light-foreground-base, $lightness: 30.5%), //#4e4e4e // Introduced in material 9
  // material end
  secondary: map.get($ruf-grey, 200),

  error: map.get($ruf-error, 500),
  success: map.get($ruf-success, 500),
  info: map.get($ruf-dark-blue, 500),
  contrast: $ruf-dark-background-base, // not used

  scrollbar-track: color.adjust($ruf-light-background-base, $lightness: -4.5%), // #f4f4f4
  scrollbar-thumb: map.get($ruf-fis-light-neutral-1, 500)
);

// Background palette for dark themes.
$ruf-dark-theme-background: (
  base: black,
  panel: color.adjust(black, $lightness: 14%), // #242424, can't use rgba. Panels need a solid color
  side: $ruf-dark-background-base,
  app-bar:    color.adjust(black, $lightness: 14%),
  app-canvas: color.adjust(black, $lightness: 14%), // Not used by material
  background: color.adjust($ruf-dark-background-base, $lightness: 11.5%), // #303030
  hover:      rgba($ruf-dark-foreground-base, 0.16),
  hover-dark: rgba(white, 0.1), // hover color to be used in case of dark background
  halo:      rgba($ruf-dark-foreground-base, 0.21),
  halo-dark: map.get($ruf-fis-black-dark, 200),
  selected-state: $ruf-dark-background-base, // material breaking change in beta-10
  dialog:     color.adjust($ruf-dark-background-base, $lightness: 11.5%), // #303030 material
  contrast-overlay-1: rgba($ruf-dark-foreground-base, 0.1),
  contrast-overlay-2: rgba($ruf-dark-foreground-base, 0.3),
  backdrop-overlay: rgba($ruf-dark-foreground-base, 0.1),

  card:       color.adjust($ruf-dark-background-base, $lightness: 11.5%), // #303030
  card-canvas: color.adjust($ruf-light-background-base, $lightness: -6.5%), // #eee // RUF addition deprecated
  disabled: map.get($ruf-grey, 900),
  footer:     color.adjust($ruf-dark-background-base, $lightness: 20%), // RUF addition
  nav-bar: color.adjust($ruf-dark-background-base, $lightness: 5%),
  row: color.adjust(black, $lightness: 14%),
  alternate-row: color.adjust($ruf-dark-background-base, $lightness: 11.5%), // #303030
  header-row: color.adjust(black, $lightness: 14%),

  // this section is deprecated. Do not use these values
  side-megamenu: $ruf-dark-background-base, // RUF addition // deprecated
  icon: rgba($ruf-dark-foreground-base, 0.3), // deprecated, use opacity-dark
  page-header: color.adjust($ruf-dark-background-base, $lightness: 8%), // deperecated, not used
  sidemenu-focus: map.get($ruf-fis-light-neutral-1, 100),
  // end deprecated sectaion

  // material
  status-bar: $ruf-dark-background-base, // not used // material
  disabled-button: rgba($ruf-dark-foreground-base, 0.12),
  raised-button: map.get($ruf-grey, 800),
  focused-button: $white-6-opacity,
  selected-button: map.get($ruf-grey, 900),
  selected-disabled-button: map.get($ruf-grey, 800),
  disabled-button-toggle: map.get($ruf-grey, 900),
  selected-button-toggle: rgba($ruf-dark-foreground-base, 0.21), // material
  unselected-chip: map.get($ruf-grey, 700), // material breaking change in beta-10
  disabled-list-option: $ruf-dark-background-base, // material breaking change in beta-10
  tooltip: color.adjust($ruf-light-foreground-base, $lightness: 30.5%), //#4e4e4e // Introduced in material 9
  // material end

  secondary: map.get($ruf-grey, 900),

  error: map.get($ruf-light-error, 500),
  success: map.get($ruf-light-success, 500),
  info: map.get($ruf-light-blue, 500),
  contrast: $ruf-dark-foreground-base,

  scrollbar-track: map.get($ruf-fis-light-neutral-1, 500),
  scrollbar-thumb: color.adjust($ruf-light-background-base, $lightness: -4.5%), // #f4f4f4
);
$ruf-disabled-color: #777363;

// Foreground palette for light themes.
$ruf-light-theme-foreground: (
  base:            $ruf-light-foreground-base,
  divider:         rgba($ruf-light-foreground-base, 0.12), // material
  dividers:        rgba($ruf-light-foreground-base, 0.12), // material
  disabled:        $ruf-disabled-color, //rgba(black, 0.38),
  disabled-button: $ruf-disabled-color, //rgba(black, 0.38),
  disabled-text:   rgba($ruf-disabled-color, 0.5), //rgba(black, 0.38),
  hint-text:       color.adjust($ruf-light-foreground-base, $lightness: 41%), // dimgray #696969 rgba(105, 105, 105, 0.38),
  secondary-text:  color.adjust($ruf-light-foreground-base, $lightness: 46.25%), //#767676
  icon:            rgba($ruf-light-foreground-base, 0.54), // mateiral
  icons:           rgba($ruf-light-foreground-base, 0.54), // material
  text:            color.adjust($ruf-light-foreground-base, $lightness: 30.5%), // #4e4e4e material: rgba(black, 0.87),
  lighter-text:    $ruf-dark-foreground-base, // #f9f9f9
  darker-text:     color.adjust($ruf-light-foreground-base, $lightness: 16.5%), // #2a2a2a
  slider-min:        rgba($ruf-light-foreground-base, 0.87), // material breaking change in beta-10
  slider-off:        rgba($ruf-light-foreground-base, 0.38), // material breaking change in beta6
  slider-off-active: rgba($ruf-light-foreground-base, 0.38), // material breaking change in beta6
  link:            #2a5db0, // RUF addition
  subtle-divider:  map.get($ruf-fis-light-neutral-1, 400), //#b4b4b4, // RUF addition: footer top border
  shadow:          rgba($ruf-light-foreground-base, 0.25), // RUF addition: banner box shadow
  input-value:     color.adjust($ruf-light-foreground-base, $lightness: 16.5%), // #2a2a2a
  border:     color.adjust($ruf-light-foreground-base, $lightness: 46.25%), //#767676 used for box-inputs colors

  //deprecated
  link-visited:    #3888bc, // RUF addition
  soft-link:       color.adjust($ruf-light-foreground-base, $lightness: 41%),  // #696969 RUF addition
  input-border:     color.adjust($ruf-light-foreground-base, $lightness: 46.25%), //#767676 used for box-inputs colors
  chart-label: color.adjust(white, $lightness: -28.5%) // #b6b6b6, // Ruf Addition for ngx-charts
);

// Foreground palette for dark themes.
$ruf-dark-theme-foreground: (
  base:            $ruf-dark-foreground-base,
  divider:         rgba($ruf-dark-foreground-base, 0.12),
  dividers:        rgba($ruf-dark-foreground-base, 0.12),
  disabled:        rgba($ruf-dark-foreground-base, 0.3),
  disabled-button: rgba($ruf-dark-foreground-base, 0.3),
  disabled-text:   rgba($ruf-dark-foreground-base, 0.3),
  hint-text:       $ruf-dark-foreground-hint-text,
  secondary-text:  $ruf-dark-foreground-secondary-text,
  icon:            $ruf-dark-foreground-base,
  icons:           $ruf-dark-foreground-base,
  text:            $ruf-dark-foreground-text, // #d3d3d3
  lighter-text:     $ruf-dark-foreground-base,
  darker-text:     color.adjust(white, $lightness: -4.5%), // #f4f4f4,
  slider-min:        $ruf-dark-foreground-base, // material breaking change in beta-10
  slider-off:        rgba($ruf-dark-foreground-base, 0.3), // material breaking change in beta6
  slider-off-active: rgba($ruf-dark-foreground-base, 0.3), // material breaking change in beta6
  link:            color.adjust(#2a5db0, $lightness: 50%),
  subtle-divider:  rgba($ruf-dark-foreground-base, 0.8), // RUF addition: footer top border
  shadow:          rgba($ruf-dark-foreground-base, 0.8),
  input-value:     color.adjust(white, $lightness: -4.5%), // #f4f4f4,
  // #a7a7a7 used for box-inputs colors
  border:     map.get($ruf-fis-light-neutral-1, 500),

  // deprecated
  link-visited:    color.adjust(#3888bc, $lightness: 50%),
  soft-link:       color.adjust(#696969, $lightness: 50%),
  input-border:     map.get($ruf-fis-light-neutral-1, 500),
  chart-label: color.adjust(white, $lightness: -28.5%) // #b6b6b6, // Ruf Addition for ngx-charts
);
