@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-mat-list-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-list-base#{$cls} {
    .mat-subheader {
      color: ruf-theming.ruf-color($foreground, text);
    }
  }

  .mat-selection-list#{$cls} {
    .mat-list-option {
      &.mat-list-item-disabled {
        background-color: transparent;
      }
    }
  }

}

@mixin ruf-mat-list-typography($config, $cls: '[fisStyle]') {

  .mat-list#{$cls}, .mat-nav-list#{$cls} {
    .mat-list-item {
      .mat-list-text {
        .mat-line:first-child {
          font-size: ruf-theming.ruf-font-size($config, subheading-1);
        }
      }
    }
  }

  .mat-selection-list#{$cls} {
    .mat-list-option {
      .mat-list-text {
        font-size: ruf-theming.ruf-font-size($config, subheading-1);
      }
    }
  }

  .mat-list-base#{$cls} {
    &.mat-list, &.mat-nav-list {
      .mat-list-item .mat-list-text {
        .ruf-mat-line-primary-text {
          font-size: ruf-theming.ruf-font-size($config, subheading-1);
        }

        .ruf-mat-line-secondary-text {
          font-size: ruf-theming.ruf-font-size($config, caption);
        }
      }
    }
  }
}
