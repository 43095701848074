@use '@ruf/theming' as ruf-theming;


@mixin _ruf-form-field-no-label-hint($config: (), $density: 0) {
  $boxed-input-without-label-hint: ruf-theming.ruf-map-get($config, boxed-input-without-label-hint, ());
  $boxed-input-without-label-hint-input-infix: ruf-theming.ruf-map-get($boxed-input-without-label-hint, input-infix, ());
  $boxed-input-without-label-hint-input-infix-padding-top: ruf-theming.ruf-map-get($boxed-input-without-label-hint-input-infix, padding-top, 9px);
  $boxed-input-without-label-hint-input-infix-padding-bottom: ruf-theming.ruf-map-get(
    $boxed-input-without-label-hint-input-infix, padding-bottom, 9px);

  margin: 0;

  .mat-form-field-flex {
    margin-top: 0;
  }

  .mat-form-field-outline {
    top: 0;
  }

  .mat-form-field-infix {
    padding-bottom: ruf-theming.ruf-to-rem($boxed-input-without-label-hint-input-infix-padding-bottom + (3 * $density));
    padding-top: ruf-theming.ruf-to-rem($boxed-input-without-label-hint-input-infix-padding-top + (3 * $density));
  }

  .mat-form-field-subscript-wrapper {
    margin-top: ruf-theming.ruf-to-rem(12px);
  }
}

@mixin ruf-mat-input-box-density($cls: '[fisStyle]', $config: (), $density: 0) {
  $height: ruf-theming.ruf-map-get($config, height, 36px);
  $height: if($density == 0, $height, $height +ruf-theming.$density-interval * ($density - 1));

  $top-default: ruf-theming.ruf-map-get($config, top-default, 7px);
  $top-factor: ruf-theming.ruf-map-get($config, top-factor, 9px);
  $outline-top: if($density == 0, $top-default, $top-factor - 2 * $density);

  $icon-size: $height - 2px;

  $outline-cls: '[boxStyle]'; // selector for boxStyle directive.
  $padding-vertical: if($density == 0, ruf-theming.ruf-to-rem(16px), ruf-theming.ruf-to-rem(8px));

  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(3px));

  $input-chips-density: ruf-theming.ruf-map-get($config, input-chips-density, ());
  $chip-margin-density: ruf-theming.ruf-map-get($input-chips-density, chip-margin, ());
  $infix-padding-density: ruf-theming.ruf-map-get($input-chips-density, infix-padding, ());
  $chip-margin: ruf-theming.ruf-map-get($chip-margin-density, default,
  ruf-theming.ruf-to-rem(4px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(4px) ruf-theming.ruf-to-rem(4px));
  $infix: ruf-theming.ruf-map-get($infix-padding-density, default,
  ruf-theming.ruf-to-rem(7px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(5px) ruf-theming.ruf-to-rem(0px));

  @if($density == -1) { // configuration for comfortable density
    $chip-margin: ruf-theming.ruf-map-get($chip-margin-density, comfortable,
    ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(4px));
    $infix: ruf-theming.ruf-map-get($infix-padding-density,
    comfortable, ruf-theming.ruf-to-rem(12px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(4.5px) ruf-theming.ruf-to-rem(0px));
  }

  @if($density == -2) { // configuration for compact density
    $chip-margin: ruf-theming.ruf-map-get($chip-margin-density, compact,
    ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(4px));
    $infix: ruf-theming.ruf-map-get($infix-padding-density,
    compact, ruf-theming.ruf-to-rem(14px) ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(4.5px) ruf-theming.ruf-to-rem(0px));
  }

  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)#{$outline-cls},
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)#{$cls},
  *#{$cls} .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea) {
    .mat-form-field-outline {
      height: ruf-theming.ruf-to-rem($height);
      min-height: ruf-theming.ruf-to-rem($height);
      top: $outline-top;
    }
    &.ruf-boxed-input-auto-height {
      .mat-form-field-outline {
        height: auto;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: ruf-theming.ruf-to-rem(12px +ruf-theming.$density-interval * $density);
      padding-top: ruf-theming.ruf-to-rem(14px +ruf-theming.$density-interval * $density);
    }

    &.mat-form-field-should-float .mat-form-field-label-wrapper {
      top: -12px - ruf-theming.$density-interval * $density;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      .mat-icon-button {
        height: ruf-theming.ruf-to-rem($icon-size) !important;
        width: ruf-theming.ruf-to-rem($icon-size) !important;
        .mat-icon {
          font-size: 18px + 2px * $density !important;
        }
      }
    }

    // Input with card background
    &:not(.ruf-boxed-form-field-disabled) {
      &.ruf-background-card {
        .mat-form-field-outline {
          border-radius: $border-radius; // radius for corresponding theme color of outline from boxed input theme file
        }
      }
    }

    // Boxed input with chips inside
    &.ruf-boxed-input-with-chips {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          padding: $infix;
        }

        .mat-chip-list {
          .mat-chip-list-wrapper {
            .mat-standard-chip {
              margin: $chip-margin;
            }
          }
        }
      }
    }

    &.ruf-form-field-no-label {
      @include _ruf-form-field-no-label-hint($config, $density);

      .mat-form-field-wrapper {
        margin-top: 0;
        padding-bottom: ruf-theming.ruf-to-rem(12px);
        padding-top: 0;
      }
    }

    &.ruf-form-field-no-hint {
      @include _ruf-form-field-no-label-hint($config, $density);

      .mat-form-field-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .mat-form-field-label-wrapper {
        top: ruf-theming.ruf-to-rem(-14px);
      }
    }
  }

  .ruf-prefix-out,
  .ruf-suffix-out {
    &.mat-icon#{$cls} {
      font-size: 16px + 2px * $density;
    }
    &.mat-icon-button#{$cls},
    #{$cls} &.mat-icon-button {
      height: ruf-theming.ruf-to-rem($icon-size) !important;
      top: -2px;
      width: ruf-theming.ruf-to-rem($icon-size) !important;
      .mat-icon#{$cls} {
        font-size: 18px + 2px * $density;
      }
    }
  }

  .ruf-boxed-input-wrapper {
    .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)#{$outline-cls},
    .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)#{$cls},
    *#{$cls} .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea) {
      &.ruf-form-field-no-label:not(.ruf-form-field-no-hint) {
        .mat-form-field-wrapper {
          margin-top: ruf-theming.ruf-to-rem(3.5px);
          padding-top: ruf-theming.ruf-to-rem(12px);
        }
      }
    }
  }

  *:not(.mat-form-field-appearance-outline) .mat-hint#{$cls} {
    display: block;
    margin-top: ruf-theming.ruf-to-rem(2px);
  }
}

@mixin ruf-mat-input-box($config: (), $cls: '[fisStyle]') {

  $outline-cls: '[boxStyle]'; // selector for boxStyle directive.

  $input-infix: ruf-theming.ruf-map-get($config, input-infix, ());
  $input-value: ruf-theming.ruf-map-get($input-infix, value, ());
  $input-prefix: ruf-theming.ruf-map-get($config, input-prefix, ());
  $input-hint: ruf-theming.ruf-map-get($config, input-hint, ());

  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(3px));
  $ruf-icon-button-dimension: ruf-theming.ruf-map-get($config, icon-size, ruf-theming.ruf-to-rem(32px));
  $ruf-outline-start-border-radius: $border-radius 0 0 $border-radius;
  $ruf-outline-end-border-radius: 0 $border-radius $border-radius 0;
  $padding-vertical: ruf-theming.ruf-map-get($config, padding-vertical, ruf-theming.ruf-to-rem(8px) );
  $padding-horizontal: ruf-theming.ruf-map-get($config, padding-horizontal, ruf-theming.ruf-to-rem(6px) );
  $padding-left: ruf-theming.ruf-map-get($config, padding-left, ruf-theming.ruf-to-rem(6px));
  $padding-right: ruf-theming.ruf-map-get($config, padding-right, ruf-theming.ruf-to-rem(6px));
  $input-text-padding-right: ruf-theming.ruf-map-get($input-value, padding-right, 0px);
  $input-prefix-padding-right: ruf-theming.ruf-map-get($input-prefix, padding-right, ruf-theming.ruf-to-rem(0px));
  $label-config: ruf-theming.ruf-map-get($config, label, ());
  $outline-appearance-label-offset: ruf-theming.ruf-to-rem(4px);
  $hint-text-padding-left: ruf-theming.ruf-map-get($input-hint, padding-left, ruf-theming.ruf-to-rem(5px));
  $hint-text-padding-right: ruf-theming.ruf-map-get($input-hint, padding-right, ruf-theming.ruf-to-rem(5px));
  $width: ruf-theming.ruf-map-get($config, width, ruf-theming.ruf-to-rem(90px));

  // stylelint-disable block-no-empty
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)#{$outline-cls},
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)#{$cls},
  *#{$cls} .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy) {

    .mat-form-field-label {
      top: ruf-theming.ruf-map-get($label-config, top, ruf-theming.ruf-to-rem(33px));
    }

    .mat-form-field-label-wrapper {
      padding-top: 0px;//ruf-theming.ruf-to-rem(22px);
    }

    &.ruf-boxed-input-with-chips {
      .mat-form-field-label-wrapper {
        padding-top: ruf-theming.ruf-to-rem(22px);
      }
    }

    .mat-form-field-wrapper .mat-form-field-flex {
      padding: ruf-theming.ruf-to-rem(0px) $padding-right ruf-theming.ruf-to-rem(0px) $padding-left;
      .mat-form-field-infix {
        border-top-width: ruf-theming.ruf-to-rem(0px);
        position: static;
      }
    }

		.mat-form-field-wrapper .mat-form-field-subscript-wrapper {
      padding: 0 $hint-text-padding-left;
    }

    // padding is applicable only if label is inside the input box
    &.mat-form-field-can-float:not(.mat-form-field-should-float) {
      .mat-form-field-label {
        padding: ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(10px);
      }
    }

		&.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-outline, .mat-form-field-outline-thick {
        .mat-form-field-outline-gap {
          border-top-color: inherit;
        }
		 }
		}
		.mat-form-field-prefix,
		.mat-form-field-suffix {
      align-self: center;
      top: ruf-theming.ruf-to-rem(0px);
      .mat-icon {
        font-size: 14px;
      }

		}

		.mat-form-field-outline-thick {
			.mat-form-field-outline-end,
			.mat-form-field-outline-start,
			.mat-form-field-outline-gap {
				border-width: ruf-theming.ruf-to-rem(1px);
				transition: none;
			}
		}

    &.cdk-keyboard-focused.mat-focused:not(.mat-form-field-invalid):not(.mat-form-field-disabled) {
      .mat-form-field-outline,
      .mat-form-field-outline-thick {
       .mat-form-field-outline-end,
		 	 .mat-form-field-outline-start,
			 .mat-form-field-outline-gap {
				border-width: ruf-theming.ruf-to-rem(2px);
			 }
      }
    }

		 .mat-form-field-outline-start {
			border-radius: $ruf-outline-start-border-radius;
      min-width: $border-radius;
		}

		 .mat-form-field-outline-end {
			border-radius: $ruf-outline-end-border-radius;
		}

		.mat-select-arrow-wrapper {
			transform: translateY(0px);
    }

    .mat-input-element {
      // Position relative required to make foreground text visible on any background color.
      position: relative;
    }

    // Readonly input styles
    &.ruf-input-readonly, &[readonly] {
      .mat-form-field-wrapper .mat-form-field-flex {
        // Remove left padding to vertically align label with input value.
        padding-left: ruf-theming.ruf-to-rem(0px);
      }

      .mat-form-field-required-marker {
        display: none;
      }
      textarea {
        resize: none;
      }
    }

    // Boxed input with chips inside
    &.ruf-boxed-input-with-chips {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          padding: 0 ruf-theming.ruf-to-rem(4px);
        }

        .mat-form-field-infix {
          margin-right: ruf-theming.ruf-to-rem(4px);
        }

        .mat-chip-list {
          .mat-chip-list-wrapper {
            .mat-standard-chip {
            }
          }
        }
      }
    }

    [dir='rtl'] & {
      .mat-form-field-outline-start {
        border-radius: $ruf-outline-end-border-radius;
      }

      .mat-form-field-outline-end {
        border-radius: $ruf-outline-start-border-radius;
      }
    }
  }

  // Side Label styling
  .ruf-boxed-input-wrapper {
    align-items: center;
    display: flex;
    .ruf-label {
      min-width: ruf-theming.ruf-to-rem(70px);
      padding-right: ruf-theming.ruf-to-rem(20px);
      text-align: right;
      &-align-right {
        text-align: right;
      }
      &-align-left {
        text-align: left;
      }

      &-padding-small {
        padding-right: ruf-theming.ruf-to-rem(5px);
      }

      &-padding-large {
        padding-right: ruf-theming.ruf-to-rem(20px);
      }
    }

   .ruf-overflow-ellipses {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    [dir='rtl'] & {
      .ruf-label {
        padding-left: ruf-theming.ruf-to-rem(20px);
        text-align: left;
        &-align-right {
          text-align: left;
        }
        &-align-left {
          text-align: right;
        }

        &-padding-small {
          padding-left: ruf-theming.ruf-to-rem(5px);
        }

        &-padding-large {
          padding-left: ruf-theming.ruf-to-rem(20px);
        }
      }
    }
  }

  .ruf-boxed-input-wrapper-row {
    .ruf-label {
      flex: 0 0 30%;
    }

    .mat-form-field {
      flex: 0 0 70%;
    }
  }

  .ruf-text-area-suffix {
    display: flex;
    .ruf-suffix-out {
      margin-left: ruf-theming.ruf-to-rem(2px);
      margin-top: ruf-theming.ruf-to-rem(2px);
    }

  }
}
