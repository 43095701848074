@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ruf-mat-button-toggle-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $group-config: ruf-theming.ruf-map-get($config, group, ());

  .mat-button-toggle-group#{$cls} {
    background-color: ruf-theming.ruf-map-get($group-config, background-color, null);
    border-color: ruf-theming.ruf-map-get($group-config, border-color, null);
    box-shadow: none;

    .mat-button-toggle,
    .mat-button-toggle#{$cls} {
      @include _ruf-button-toggle($theme, $config);
    }
  }

}

@mixin _ruf-button-toggle($theme, $config) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);


  $button-config: ruf-theming.ruf-map-get($config, button, ());
  $button-selected-config: ruf-theming.ruf-map-get($button-config, selected, ());
  $button-disabled-config: ruf-theming.ruf-map-get($button-config, disabled, ());
  $button-hover-config: ruf-theming.ruf-map-get($button-config, hover, ());
  $button-focus-config: ruf-theming.ruf-map-get($button-config, focus, ());

  background-color: ruf-theming.ruf-map-get($button-config, background-color, inherit);
  border-color: ruf-theming.ruf-map-get($button-config, border-color, ruf-theming.ruf-color($foreground, base, 0.38));
  color: ruf-theming.ruf-map-get($button-config, color, ruf-theming.ruf-color($foreground, text));

  &.mat-button-toggle-disabled {
    color: ruf-theming.ruf-map-get($button-disabled-config, color, null);
    opacity: ruf-theming.ruf-map-get($button-disabled-config, opacity, 0.5);
  }

  &:not(.mat-button-toggle-disabled).cdk-keyboard-focused,
  &:not(.mat-button-toggle-disabled):hover {
      background-color: ruf-theming.ruf-map-get($button-hover-config, background-color, null);
    .mat-button-toggle-focus-overlay {
      opacity: 0;
    }
  }

  &:not(.mat-button-toggle-checked):not(.mat-button-toggle-disabled):hover {
    background-color: ruf-theming.ruf-map-get($button-hover-config, background-color, ruf-theming.ruf-color($background, hover));
   .mat-button-toggle-focus-overlay {
     opacity: 0;
  }
}

  &:not(.mat-button-toggle-disabled).cdk-keyboard-focused {
    border-color: ruf-theming.ruf-map-get( $button-focus-config, border-color, ruf-theming.ruf-color($accent));
    .mat-button-toggle-focus-overlay {
      opacity: 0;
    }
  }

  &.mat-button-toggle-checked {
    background-color: ruf-theming.ruf-map-get($button-selected-config, background-color, ruf-theming.ruf-color($primary));
    border-color: ruf-theming.ruf-map-get($button-selected-config, border-color, ruf-theming.ruf-color($primary));
    color: ruf-theming.ruf-map-get($button-selected-config, color, ruf-theming.ruf-color($primary, default-contrast));

    &.mat-button-toggle-disabled {
      background-color: ruf-theming.ruf-map-get($button-disabled-config, selected-background-color, null);
      border-color: ruf-theming.ruf-map-get($button-disabled-config, selected-background-color, null);
      color: ruf-theming.ruf-map-get($button-disabled-config, color, null);
      opacity: ruf-theming.ruf-map-get($button-disabled-config, opacity, 0.5);
    }
  }
  &.cdk-mouse-focused {
    outline: 0;
  }
}

// stylelint-disable block-no-empty
@mixin ruf-mat-button-toggle-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  $button-config: ruf-theming.ruf-map-get($override-config, button, ());
  $button-selected-config: ruf-theming.ruf-map-get($button-config, selected, ());
  $button-hover-config: ruf-theming.ruf-map-get($button-config, hover, ());

  .mat-button-toggle,
  .mat-button-toggle#{$cls} {
    .mat-button-toggle-label-content {
      font-weight: ruf-theming.ruf-map-get($button-config, font-weight, 400);
      line-height: ruf-theming.ruf-map-get($button-config, line-height, ruf-theming.ruf-to-rem(20px));
    }

    &.mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        font-weight: ruf-theming.ruf-map-get($button-selected-config, font-weight, 400);
      }
    }


    &:hover {
      .mat-button-toggle-label-content {
        font-weight: ruf-theming.ruf-map-get($button-hover-config, font-weight, 400);
      }
    }
  }
}
