@use '@angular/material' as mat;
@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// stylelint-disable block-no-empty
@mixin ruf-mat-datepicker-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-datepicker#{$cls} {

  }

.ruf-boxed-form-field-disabled {
  .mat-datepicker-toggle[fisStyle] .mat-icon-button[disabled] {
      color: inherit;
    }
}

  .mat-form-field#{$cls} {
    .mat-datepicker-toggle .mat-icon-button {
      font: inherit;
      font-family: 'fisfont';

     .mat-button-focus-overlay {
        background-color: transparent;
     }

     &:not([disabled]):hover, &:not([disabled]):focus {
       background-color:  ruf-theming.ruf-color($background, halo);
     }

      &::after {
        color: ruf-theming.ruf-color($foreground, text);
        content: '\e90b';
      }

      .mat-button-wrapper {
        display: none;
      }
    }
  }

  .mat-datepicker-toggle#{$cls} {
    .mat-icon-button {
      font: inherit;
      font-family: 'fisfont';

     .mat-button-focus-overlay {
        background-color: transparent;
     }

     &:not([disabled]):hover, &:not([disabled]):focus {
       background-color:  ruf-theming.ruf-color($background, halo);
     }

      &::after {
        color: ruf-theming.ruf-color($foreground, text);
        content: '\e90b';
      }
      .mat-button-wrapper {
        display: none;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

}

@mixin ruf-mat-datepicker-typography($config, $cls: '[fisStyle]', $override-config: ()) {

}

// Utility mixin to target cells that aren't selected. Used to make selector easier to follow.
@mixin ruf-unselected-datepicker-cell {
  &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    @content;
  }
}

@mixin ruf-mat-datepicker-panel-theme($theme, $cls: '.fis-style', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $header-config: ruf-theming.ruf-map-get($config, header, ());
  $body-config: ruf-theming.ruf-map-get($config, body, ());

  $today-cell: ruf-theming.ruf-map-get($body-config, today-cell, ());
  $active-cell: ruf-theming.ruf-map-get($body-config, selected-cell, ());
  $disabled-cell: ruf-theming.ruf-map-get($body-config, disabled-cell, ());
  $focused-cell: ruf-theming.ruf-map-get($body-config, focused-cell, ());
  $content: ruf-theming.ruf-map-get($config, content, ());
  $label-color: ruf-theming.ruf-map-get($body-config, label-color, ruf-theming.ruf-color($foreground, hint-text));

  $icon-color: ruf-theming.ruf-map-get($header-config, icon-color, ruf-theming.ruf-color($foreground, icon));

  $period-button: ruf-theming.ruf-map-get($header-config, period-button, ());

  $period-button-hover: ruf-theming.ruf-map-get($period-button, hover, ());

  $active-cell-bg-color: ruf-theming.ruf-map-get($active-cell, background-color, ruf-theming.ruf-color($emphasis));
  $active-cell-color: ruf-theming.ruf-map-get($active-cell, color, ruf-theming.ruf-contrast($emphasis, 500));
  $today-cell-bg-color: ruf-theming.ruf-map-get($today-cell, background-color, map.get($background, panel));
  $today-cell-color: ruf-theming.ruf-map-get($today-cell, color, ruf-theming.ruf-color($primary));
  $today-cell-border-color: ruf-theming.ruf-map-get($today-cell, border-color, ruf-theming.ruf-color($primary));
  $actve-cell-range-bg-color: ruf-theming.ruf-map-get($active-cell, range-background-color, ruf-theming.ruf-color($emphasis, 50));

 .mat-datepicker-content {
    border-color: ruf-theming.ruf-map-get($content, border-color, initial);
    box-shadow: ruf-theming.ruf-map-get($content, box-shadow, null);
  }

  .mat-calendar#{$cls}, .mat-calendar {
    .mat-calendar-body-label {
      color: $label-color;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
    .cdk-keyboard-focused .mat-calendar-body-active,
    .cdk-program-focused .mat-calendar-body-active {
      > .mat-calendar-body-cell-content {
        @include ruf-unselected-datepicker-cell {
          background-color: ruf-theming.ruf-map-get($focused-cell, background-color, ruf-theming.ruf-color($primary, default));
          color: ruf-theming.ruf-map-get($focused-cell, color, ruf-theming.ruf-color($primary, default-contrast));
        }
      }
    }
    .mat-calendar-body-cell.mat-calendar-body-disabled {
      .mat-calendar-body-cell-content {
        @include ruf-unselected-datepicker-cell {
          color: ruf-theming.ruf-map-get($disabled-cell, color, ruf-theming.ruf-color($foreground, disabled-text));
        }
      }
    }
    .mat-calendar-body-cell-content {
      &.mat-calendar-body-today {
        background-color: $today-cell-bg-color;
        border-color: $today-cell-border-color;
        color: $today-cell-color;
      }
      &.mat-calendar-body-selected {
        background: $active-cell-bg-color;
        color: $active-cell-color;
      }
      &.mat-calendar-body-today.mat-calendar-body-selected {
        box-shadow: none;
      }
    }
    .mat-calendar-header {
      .mat-calendar-controls {
        .mat-icon-button {
          color: $icon-color;
          &.mat-button-disabled {
            color: ruf-theming.ruf-color($foreground, disabled);
          }
        }

        .mat-icon-button:not(.mat-button-disabled):hover,
        .mat-icon-button:not(.mat-button-disabled).cdk-keyboard-focused,
        .mat-icon-button:not(.mat-button-disabled).cdk-program-focused {
          .mat-button-focus-overlay {
            background-color: $icon-color;
            opacity: 0.21;
          }
        }
      }

      button.mat-calendar-period-button:hover,
      button.mat-calendar-period-button.cdk-keyboard-focused,
      button.mat-calendar-period-button.cdk-program-focused {
        background-color: ruf-theming.ruf-map-get($period-button-hover, background-color, ruf-theming.ruf-color($background, hover));
        .mat-button-focus-overlay {
          opacity: 0;
        }
      }
    }

    .mat-calendar-body-in-range {
      &::before {
        background: $actve-cell-range-bg-color;
      }
      .mat-calendar-body-cell-content {
        &.mat-calendar-body-today:not(.mat-calendar-body-selected) {
          background-color: transparent;
        }
      }
    }
  }
}

@mixin ruf-mat-datepicker-panel-typography($config, $cls: '.fis-style', $override-config: ()) {
  $header-config: ruf-theming.ruf-map-get($override-config, header, ());
  $header-controls: ruf-theming.ruf-map-get($header-config, controls, ());
  $header-table: ruf-theming.ruf-map-get($header-config, table, ());
  $body-config: ruf-theming.ruf-map-get($override-config, body, ());
  $cell-config: ruf-theming.ruf-map-get($body-config, cell, ());
  $selected-cell-config: ruf-theming.ruf-map-get($body-config, selected-cell, ());

  .mat-calendar#{$cls} {
    .mat-calendar-header {
      .mat-calendar-controls {
        .mat-button-wrapper { // Weekday and month/year readout
          font-family: ruf-theming.ruf-font-family($config, title);
          font-size: ruf-theming.ruf-font-size($config, subheading-1);
          font-weight: ruf-theming.ruf-map-get($header-controls, font-weight, null);
          line-height: ruf-theming.ruf-map-get($header-controls, line-height, null);
        }
      }
    }

    .mat-calendar-table-header {
      font-family: ruf-theming.ruf-font-family($config, title);
      th { // week days table header S M T W T F S
        font-size: ruf-theming.ruf-font-size($config, caption);
        font-weight: ruf-theming.ruf-map-get($header-table, font-weight, null);
        line-height: ruf-theming.ruf-map-get($header-table, line-height, null);
      }
    }

    .mat-calendar-body {
      .mat-calendar-body-label {
        font-family: ruf-theming.ruf-font-family($config, title);
        font-size: ruf-theming.ruf-font-size($config, subheading-1);
        font-weight: ruf-theming.ruf-font-weight($config, normal-weight);
      }
      .mat-calendar-body-cell-content { // date (cell content)
        font-size: ruf-theming.ruf-font-size($config, body-1);
        line-height: ruf-theming.ruf-map-get($cell-config, line-height, null);
        &.mat-calendar-body-today, &.mat-calendar-body-selected { // today and selected date
          font-weight: ruf-theming.ruf-map-get($selected-cell-config, font-weight, null);
          line-height: ruf-theming.ruf-map-get($selected-cell-config, line-height, null);
        }
      }
    }
  }
}
