@use '@ruf/theming' as ruf-theming;

@mixin ruf-scrollbar-strcuture($config: ()) {
  @if($config) {
    $scrollbar-width: ruf-theming.ruf-map-get($config, width, ruf-theming.ruf-to-rem(10px));
    $scrollbar-height: ruf-theming.ruf-map-get($config, height, ruf-theming.ruf-to-rem(10px));

    .ruf-scrollbar {

      // chrome
      &::-webkit-scrollbar {
        height: $scrollbar-height;
        width: $scrollbar-width;
      }

      ::-webkit-scrollbar {
        height: $scrollbar-height;
        width: $scrollbar-width;
      }

      *,
      & {
        // firefox;
        scrollbar-width: thin;
      }
      &.ruf-scrollbar-auto-hide {
        overflow: hidden;
      }
      &.ruf-scrollbar-auto-hide:hover {
        overflow: auto;
      }
    }
  }
}
