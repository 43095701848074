@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-tree-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  // stylelint-disable block-no-empty
  .mat-tree#{$cls} {
    .ruf-tree-vertical-dotted-line {
      border-color: ruf-theming.ruf-color($foreground, dividers);

      .mat-nested-tree-node:last-child > .mat-tree-node::before {
        border-color: ruf-theming.ruf-color($foreground, dividers);
      }

      .ruf-tree-horizontal-dotted-line,
      .mat-tree-node {
        border-color: ruf-theming.ruf-color($foreground, dividers);
      }
    }
  }
}

@mixin ruf-mat-tree-typography($config, $cls: '[fisStyle]') {
  .mat-tree#{$cls} {
    .mat-tree-node {
      font-size: ruf-theming.ruf-font-size($config, subheading-1);
    }
  }
}
