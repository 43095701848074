@use 'sass:map';
@use '@ruf/theming' as ruf-theming;
@use './side-panel-toggle/side-panel-toggle.theme' as side-panel-toggle;

@mixin ruf-side-panel-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .ruf-side-panel {
    .ruf-side-panel-container {
        background-color: ruf-theming.ruf-color($background, panel);
        border-color: ruf-theming.ruf-color($foreground, border);
    }
  }
  @include side-panel-toggle.ruf-side-panel-toggle-theme($theme, ruf-theming.ruf-map-get($config, ruf-side-panel-toggle, ()));
}

// Deprecated
@mixin ruf-slide-panel-theme($theme, $config: ()) {
  @include ruf-side-panel-theme($theme, $config);
}

// stylelint-disable block-no-empty
@mixin ruf-slide-panel-typography($config) {

}

/* Structural config override*/
@mixin ruf-side-panel-override($config) {
  @if ($config) {
    .ruf-side-panel.fis-override {
      $max-width: ruf-theming.ruf-map-get($config, max-width, null);
      $min-width: ruf-theming.ruf-map-get($config, min-width, null);

      .ruf-side-panel-container {
        max-width: $max-width;
        min-width: $min-width;
      }
    }
  }
}
