@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ruf-mat-chips-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $info: map.get($theme, info);
  $error: map.get($theme, error);
  $success: map.get($theme, success);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $unselected-foreground: ruf-theming.ruf-color($foreground, text);
  $is-dark: map.get($theme, is-dark);

  $chip-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $chip-disabled-config: ruf-theming.ruf-map-get($config, disabled, ());
  $hover-config: ruf-theming.ruf-map-get($config, hover-color, ());
  $border-config: ruf-theming.ruf-map-get($config, border-color, ());
  $icon-hover-config: ruf-theming.ruf-map-get($config, icon-hover-color, ());

  $border-color-config: ruf-theming.ruf-map-get($border-config, color-config, ());
  $hover-color-config: ruf-theming.ruf-map-get($hover-config, color-config, ());
  $icon-hover-color-config: ruf-theming.ruf-map-get($icon-hover-config, color-config, ());
  $outline-color: ruf-theming.ruf-map-get($config, outline-color, ruf-theming.ruf-color($accent));

  $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    emphasis: $emphasis,
    info: $info,
    error: $error,
    success: $success
  );

  .mat-chip-list#{$cls} {
    mat-chip {
      $icon-opacity: ruf-theming.ruf-map-get($chip-icon-config, opacity, 1);
      $disabled-background-color: ruf-theming.ruf-map-get($chip-disabled-config, background-color, null);
      $disabled-border-color: ruf-theming.ruf-map-get($chip-disabled-config, border-color, null);
      $disabled-opacity: ruf-theming.ruf-map-get($chip-disabled-config, opacity, 0.5);
      $disabled-icon-color: ruf-theming.ruf-map-get($chip-disabled-config, icon-color, null);
      $disabled-color: ruf-theming.ruf-map-get($chip-disabled-config, color, null);

      &.ruf-tagged-chip {
        &.ruf-tagged-outlined-chip {
          &.mat-standard-chip {
            background-color: transparent;

            &::after {
              background-color: transparent;
            }
          }
        }
      }

      @each $name, $color in $theme-colors {

        $background-color: ruf-theming.ruf-map-get($config, background-color, ruf-theming.ruf-color($color));
        $border-color: ruf-theming.ruf-map-get($border-color-config, #{$name}-border-color, ruf-theming.ruf-color($color, default-contrast));
        $hover-color: ruf-theming.ruf-map-get($hover-color-config, #{$name}-background-color, ruf-theming.ruf-color($color));
        $icon-hover-color: ruf-theming.ruf-map-get($icon-hover-color-config, #{$name}-background-color,
          ruf-theming.ruf-color($color, if($is-dark, 700, 300)));
        $text-color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($color, default-contrast));
        $text-color-tag: ruf-theming.ruf-map-get($config, color, $unselected-foreground);
        $icon-color: ruf-theming.ruf-map-get($config, color, ruf-theming.ruf-color($color, default-contrast));
        $hover-text-color: ruf-theming.ruf-map-get($hover-config, color, ruf-theming.ruf-color($color, default-contrast));

        &.mat-#{$name} {
          background-color: $background-color;
          border-color: $border-color;
          color: $text-color;

          &.cdk-keyboard-focused {
            box-shadow: ruf-theming.ruf-map-get($config, box-shadow, 0px 0px 5px 0px $outline-color);
            outline-color: $outline-color;
            outline-style: solid;
          }

          &.mat-chip-selected .mat-chip-remove {
            opacity: $icon-opacity;

            .mat-icon, .mat-icon:hover {
              color: $hover-text-color;
            }
          }

          .mat-chip-remove, .mat-chip-remove:hover,
          .mat-chip-remove .mat-icon, .mat-chip-remove .mat-icon:hover,
          .mat-icon.mat-chip-avatar {
            color: $icon-color;
            opacity: $icon-opacity;
          }

          .mat-icon-button:hover {
            background-color: $icon-hover-color;
          }

          &.mat-chip:not([disabled]):not(.ruf-static-chip):hover,
          &.mat-chip:not(.ruf-static-chip):focus {
            background-color: $hover-color;
            box-shadow: none;
            color: $hover-text-color;

            .mat-icon {
              color: $hover-text-color;
            }
          }
          &.mat-chip:not([disabled]):not(.ruf-static-chip):focus {
            box-shadow: ruf-theming.ruf-map-get($config, box-shadow, 0px 0px 5px 0px $outline-color);
          }
          &.mat-chip::after {
            background-color: $hover-color;
          }
          &.mat-chip.ruf-static-chip::after {
            background-color: transparent;
          }

          &.ruf-tagged-chip {
            $background-hue: if($is-dark, 900, 100);
            background-color: ruf-theming.ruf-color($color, $background-hue);
            border-color: ruf-theming.ruf-color($color);
            color: $text-color-tag;

            &.mat-chip:not([disabled]):not(.ruf-static-chip):hover,
            &.mat-chip:not([disabled]):not(.ruf-static-chip):focus {
              background-color: ruf-theming.ruf-color($color, $background-hue);
              color: $text-color-tag;
            }
            &.mat-chip::after {
              background-color: ruf-theming.ruf-color($color, $background-hue);
            }

            &.mat-chip-disabled {
              background-color: $disabled-background-color;
              border-color: $disabled-border-color;
              color: $disabled-color;
            }

            &.ruf-tagged-outlined-chip {
              background-color: transparent;

              &::after {
                background-color: transparent;
              }

              &.mat-chip:not([disabled]):not(.ruf-static-chip):hover,
              &.mat-chip:not([disabled]):not(.ruf-static-chip):focus {
                background-color: transparent;
              }
            }
          }
        }
      }
      &.mat-chip-disabled {
        background-color: $disabled-background-color;
        border-color: $disabled-border-color;
        color: $disabled-color;
        opacity: $disabled-opacity;
        &:not(.mat-chip-selected) .mat-chip-remove.mat-icon-button,
        &:not(.mat-chip-selected) .mat-chip-remove.mat-icon-button:hover {
          .mat-icon {
            color: $disabled-icon-color;
          }
        }
        .mat-chip-remove:hover {
          cursor: not-allowed;
        }
        >.mat-icon-button:hover {
          background: none;
        }
      }
    }
  }
}

@mixin  ruf-mat-chips-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  .mat-chip-list#{$cls} {
    .mat-chip {
      font-weight: ruf-theming.ruf-map-get($override-config, bold, normal);
    }
  }
}
