@use 'sass:map';
@use '@ruf/theming' as ruf-theming;


@mixin ruf-overlay-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  // We can not add fisStyle attribute in selector because we can not add fisStyle attribute
  // on overlay contianer
  .cdk-overlay-container {
    .cdk-overlay-dark-backdrop {
        background: ruf-theming.ruf-color($background, backdrop-overlay);
    }
  }
}
