@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-radio-theme($theme, $cls: '[fisStyle]', $config: ()) {

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $theme-colors: (
      primary: $primary,
      accent: $accent,
      warn: $warn
  );

  $disabled-radio: ruf-theming.ruf-map-get($config, disabled, ());

  $disabled-color: ruf-theming.ruf-map-get($disabled-radio, color, null);
  $disabled-radio-label-opacity: ruf-theming.ruf-map-get($disabled-radio, opacity, 0.5);
  $disabled-radio-opacity: 0.5;
  $checked-ripple-opacity: ruf-theming.ruf-map-get($config, ripple-opacity, 0.1);
  $unchecked-ripple-opacity: ruf-theming.ruf-map-get($config, ripple-opacity, 0.05);
  $unchecked-ripple-color: ruf-theming.ruf-map-get($config, unchecked-ripple-color, ruf-theming.ruf-color($foreground, base));
  $border-color: ruf-theming.ruf-map-get($config, border-color, ruf-theming.ruf-color($foreground, border));
  $checked-ripple-color-weight: ruf-theming.ruf-map-get($config, checked-ripple-color-weight, 300);

  // stylelint-disable block-no-empty
  .mat-radio-button#{$cls} {

    &.cdk-keyboard-focused {  
      .mat-radio-ripple {
        border: ruf-theming.ruf-to-rem(2px) solid ruf-theming.ruf-color($emphasis);
        border-radius: 50%;
      }
    }

    &:not(.mat-radio-disabled):not(.mat-radio-checked) {
      .mat-radio-outer-circle {
        border-color: $border-color;
      }
      .mat-radio-inner-circle {
        background-color: transparent;
      }
    }

    &.mat-radio-disabled {
      .mat-radio-label {
        color: $disabled-color;
        opacity: $disabled-radio-label-opacity;
      }
    }

    &:not(.mat-radio-disabled).mat-radio-checked {
      &.cdk-focused,
      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      .mat-radio-container:hover {
        .mat-ripple .mat-radio-persistent-ripple {
          opacity: $checked-ripple-opacity;
        }
      }
    }

    &:not(.mat-radio-disabled):not(.mat-radio-checked) {
      &.cdk-focused,
      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      .mat-radio-container:hover {
          .mat-ripple {
            .mat-radio-persistent-ripple {
              background: $unchecked-ripple-color;
              opacity: $unchecked-ripple-opacity;
            }
          }
      }
    }

    .mat-radio-container {
      .mat-radio-outer-circle,
      .mat-radio-inner-circle {
        z-index: 1;
      }
      .mat-ripple {
        z-index: 0;
      }
    }

    @each $name, $color in $theme-colors {
      &.mat-#{$name} {
        &.mat-radio-checked.mat-radio-disabled {
          .mat-radio-inner-circle {
            background-color: ruf-theming.ruf-color($color, default);
            opacity: $disabled-radio-opacity;
          }
          .mat-radio-outer-circle {
            border-color: ruf-theming.ruf-color($color, default);
            opacity: $disabled-radio-opacity;
          }
        }

        &.mat-radio-checked .mat-radio-container .mat-ripple-element {
          background: ruf-theming.ruf-color($color, $checked-ripple-color-weight);
        }
      }
    }
  }
}

@mixin ruf-mat-radio-typography($config, $cls: '[fisStyle]') {
   .mat-radio-button#{$cls} {
     .mat-radio-label-content {
        font-size: ruf-theming.ruf-font-size($config, subheading-1);
      }
   }
}
