// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-card-theme($theme, $cls: '[fisStyle]', $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-card-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  $ruf-card-border-color: ruf-theming.ruf-color($foreground, border);
  $ruf-card-title-color: ruf-theming.ruf-map-get($config, title-color, ruf-theming.ruf-color($primary)) !default;
  $ruf-card-subtitle-color: ruf-theming.ruf-map-get($config, subtitle-color, ruf-theming.ruf-color($foreground, secondary-text));

  .mat-card#{$cls} {
    background: ruf-theming.ruf-color($background, card);
    border-color: $ruf-card-border-color;
    box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $ruf-card-box-shadow);
    .mat-card-title {
      color: $ruf-card-title-color;
    }

    .mat-card-subtitle {
      color: $ruf-card-subtitle-color;
    }
  }
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: ruf-theming.ruf-map-get($config, box-shadow, $ruf-card-box-shadow);
  }
}

@mixin ruf-mat-card-typography($config, $cls: '[fisStyle]', $override-config: ()) {
  $mat-title-config: ruf-theming.ruf-map-get($override-config, title, ());
   .mat-card#{$cls} {
    .mat-card-title {
      font-family: ruf-theming.ruf-font-family($config, caption-2);
      font-size: ruf-theming.ruf-map-get($mat-title-config, font-size, ruf-theming.ruf-font-size($config, title));
      font-weight: ruf-theming.ruf-font-weight($config, caption-2);
    }
  }
}

