@use '@ruf/theming' as ruf-theming;

@mixin ruf-mat-snackbar-panel($cls: '.fis-style', $config: ()) {
  $border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(0px));
  $margin: ruf-theming.ruf-map-get($config, margin, ruf-theming.ruf-to-rem(0px));
  $max-width: ruf-theming.ruf-map-get($config, max-width, ruf-theming.ruf-to-rem(477px));
  $min-width: ruf-theming.ruf-map-get($config, min-width, ruf-theming.ruf-to-rem(310px));
  $min-height: ruf-theming.ruf-map-get($config, min-height, ruf-theming.ruf-to-rem(48px));
  $padding-horizontal: ruf-theming.ruf-map-get($config, padding-horizontal, ruf-theming.ruf-to-rem(17px));
  $padding-vertical: ruf-theming.ruf-map-get($config, padding-vertical, ruf-theming.ruf-to-rem(17px));
  $inkbar-width: ruf-theming.ruf-map-get($config, inkbar-width, ruf-theming.ruf-to-rem(6px));

  $snackbar-border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(0px));
  $snackbar-icon-config: ruf-theming.ruf-map-get($config, icon, ());
  $snackbar-icon-font-size: ruf-theming.ruf-map-get($snackbar-icon-config, font-size, ruf-theming.ruf-to-rem(22px));
  $snackbar-icon-height: ruf-theming.ruf-map-get($snackbar-icon-config, height, ruf-theming.ruf-to-rem(22px));
  $snackbar-icon-line-height: ruf-theming.ruf-map-get($snackbar-icon-config, line-height, ruf-theming.ruf-to-rem(20px));
  $snackbar-icon-width: ruf-theming.ruf-map-get($snackbar-icon-config, width, ruf-theming.ruf-to-rem(22px));

    .mat-snack-bar-container#{$cls} {
      align-items: center;
      border: $snackbar-border-width solid;
      border-radius: $border-radius;
      display: flex;
      margin: $margin;
      max-width: $max-width;
      min-height: $min-height;
      min-width:  $min-width;
      padding: $padding-horizontal $padding-vertical;

       &.ruf-inkbar-top {
         border-top-style: solid;
         border-top-width: $inkbar-width;
       }

       &.ruf-inkbar-bottom {
         border-bottom-style: solid;
         border-bottom-width: $inkbar-width;
       }

       .mat-icon {
        font-size: $snackbar-icon-font-size;
        height: 100%;
        line-height: $snackbar-icon-line-height;
        width: $snackbar-icon-width;
       }
    }
  }
