@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-megamenu-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-megamenu-color: ruf-theming.ruf-color($foreground, text) !default;
  $ruf-sitemap-container: ruf-theming.ruf-color($background, panel);
  $ruf-side-megamenu-background: ruf-theming.ruf-color($background, side);
  $ruf-menubar-highlight-color: ruf-theming.ruf-color($background, hover);
  $search: ruf-theming.ruf-map-get($config, search, ());
  $default-item-active-color: ruf-theming.ruf-map-get($search, default-active-inkbar-color, ruf-theming.ruf-color($accent));

  .ruf-megamenu {
    background: $ruf-sitemap-container;
    color: $ruf-megamenu-color;

    .ruf-sidemenu {
      .ruf-sidemenu-item.ruf-menu-item {
        &:hover {
          background-color: $ruf-menubar-highlight-color;
        }
      }
      .ruf-menubar-item-active {
        background-color: $ruf-menubar-highlight-color;
        &:focus {
          background-color: $ruf-menubar-highlight-color;
        }
      }
    }

    .ruf-search-divider {
      border-top-color: ruf-theming.ruf-color($background, secondary);
    }
  }

  .ruf-content-heading {
    color: $ruf-megamenu-color;
  }

  .ruf-filter-box {
    .ruf-focus {
      background-color: $ruf-menubar-highlight-color;
    }

    &:not(.ruf-filter-box-rtl) {
      .ruf-filter-box-group:not(.ruf-focus) {
        border-left-color: transparent;
      }
      .ruf-focus {
        border-left-color: $default-item-active-color;
      }
    }
    &.ruf-filter-box-rtl {
      .ruf-filter-box-group:not(.ruf-focus) {
        border-right-color: transparent;
      }
      .ruf-focus {
        border-right-color: $default-item-active-color;
      }
    }
  }

  .ruf-sitemap-container {
    background-color: $ruf-sitemap-container;
    border-color: $ruf-sitemap-container;
  }
}

@mixin ruf-megamenu-typography($config) {
  $megamenu-config: ruf-theming.ruf-map-get($config, ruf-megamenu, ());
  $icon-size: ruf-theming.ruf-map-get($megamenu-config, icon, ruf-theming.ruf-to-rem(50px));
  $label-size: ruf-theming.ruf-map-get($megamenu-config, label, ruf-theming.ruf-to-rem(22px));
  .ruf-megamenu {
    font-family: ruf-theming.ruf-font-family($config);
  }
  .ruf-filter-box {
    input {
      font-family: ruf-theming.ruf-font-family($config);
    }
  }
  .ruf-sitemap-search-field,
  .ruf-sitemap-search-result {
    font-size: ruf-theming.ruf-font-size($config, subheading-2);
    font-weight: ruf-theming.ruf-font-weight($config, bold);
  }
  .ruf-megamenu-container {
    .ruf-megamenu-empty-result {
      .mat-icon {
        font-size: $icon-size;
      }
      .ruf-megamenu-empty-search {
        font-family: ruf-theming.ruf-font-family($config, title);
        font-size: $label-size;
        font-weight: ruf-theming.ruf-font-weight($config, bold);
      }
      .ruf-megamenu-revise-search {
        font-size: ruf-theming.ruf-font-size($config, dialog-title);
      }
    }
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-megamenu-override($config) {
  @if ($config) {
    .ruf-megamenu.fis-override {
      $width: ruf-theming.ruf-map-get($config, sidemenu-width, null);
      @include ruf-theming.ruf-override-padding($config);
      .ruf-sidemenu-container {
        max-width: $width;
        min-width: $width;
        .ruf-sidemenu.fis-override {
          min-width: $width;
        }
      }
    }
  }
}
