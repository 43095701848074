@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
       url('../fonts/titillium-web/titillium-web-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-regular.woff') format('woff'); /* Modern Browsers */
}

/* titillium-web-semi-bold */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
       url('../fonts/titillium-web/titillium-web-semibold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-semibold.woff') format('woff'); /* Modern Browsers */
}

/* titillium-web-bold */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
       url('../fonts/titillium-web/titillium-web-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/titillium-web/titillium-web-bold.woff') format('woff'); /* Modern Browsers */
}
