@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-dropdown-panel-theme-bidiretionality($dir1: 'left', $dir2: 'right', $config: (), $theme: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $dropdown-panel-container-focus: ruf-theming.ruf-map-get($config, focus, ());
  &.ruf-panel-left-arrow-center::after {
    border-#{$dir1}-color: ruf-theming.ruf-color($background, panel);
  }
  &.ruf-panel-left-arrow-center {
    &::before {
      border-#{$dir1}-color: ruf-theming.ruf-map-get($config, border-color, ruf-theming.ruf-color($primary));
    }

    &.cdk-keyboard-focused::before {
      border-#{$dir1}-color: ruf-theming.ruf-map-get($dropdown-panel-container-focus, border-color, ruf-theming.ruf-color($accent));
    }
  }

  &.ruf-panel-right-arrow-center::after {
    border-#{$dir2}-color: ruf-theming.ruf-color($background, panel);
  }
  &.ruf-panel-right-arrow-center {
    &::before {
      border-#{$dir2}-color: ruf-theming.ruf-map-get($config, border-color, ruf-theming.ruf-color($primary));
    }

    &.cdk-keyboard-focused::before {
      border-#{$dir2}-color: ruf-theming.ruf-map-get($dropdown-panel-container-focus, border-color, ruf-theming.ruf-color($accent));
    }
  }
}

@mixin ruf-dropdown-panel-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $emphasis: map.get($theme, emphasis);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $ruf-dropdown-panel-background-color: ruf-theming.ruf-color($background, panel) !default;
  $dropdown-panel-container: ruf-theming.ruf-map-get($config, dropdown-panel-container, ());
  $dropdown-panel-container-focus: ruf-theming.ruf-map-get($dropdown-panel-container, focus, ());

   // Gold specific
  $box-shadow: ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(3px) ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(-2px) rgba(0, 0, 0, 0.2),
 ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(2px) ruf-theming.ruf-to-rem(0px) rgba(0, 0, 0, 0.14),
 ruf-theming.ruf-to-rem(0px) ruf-theming.ruf-to-rem(1px) ruf-theming.ruf-to-rem(5px) ruf-theming.ruf-to-rem(0px) rgba(0, 0, 0, 0.12);

  .ruf-dropdown-panel-container {
    background: $ruf-dropdown-panel-background-color;
    border-color: ruf-theming.ruf-map-get($dropdown-panel-container, border-color, transparent);
    box-shadow: ruf-theming.ruf-map-get($dropdown-panel-container, box-shadow, $box-shadow);
    color: ruf-theming.ruf-color($foreground, text);
    outline: none;

    &.cdk-keyboard-focused {
      border-color: ruf-theming.ruf-map-get($dropdown-panel-container-focus, border-color, ruf-theming.ruf-color($accent));
    }
  }

  .ruf-popover-menu {
    border-color: ruf-theming.ruf-map-get($dropdown-panel-container, border-color, ruf-theming.ruf-color($primary));
  }

  .ruf-dropdown-panel-wrapper {
    background: ruf-theming.ruf-color($background, backdrop-overlay);
  }

  .ruf-panel-center-arrow-up::after, .ruf-panel-center-arrow-up::before,
  .ruf-panel-center-arrow-down::after, .ruf-panel-center-arrow-down::before,
  .ruf-panel-left-arrow-up::after, .ruf-panel-left-arrow-up::before,
  .ruf-panel-left-arrow-center::after, .ruf-panel-left-arrow-center::before,
  .ruf-panel-left-arrow-down::after, .ruf-panel-left-arrow-down::before,
  .ruf-panel-right-arrow-up::after, .ruf-panel-right-arrow-up::before,
  .ruf-panel-right-arrow-center::after, .ruf-panel-right-arrow-center::before,
  .ruf-panel-right-arrow-down::after, .ruf-panel-right-arrow-down::before {
    border-color: transparent;
  }

  .ruf-panel-center-arrow-up::after, .ruf-panel-left-arrow-up::after, .ruf-panel-right-arrow-up::after {
    border-bottom-color: $ruf-dropdown-panel-background-color;
  }
  .ruf-panel-center-arrow-up, .ruf-panel-left-arrow-up, .ruf-panel-right-arrow-up {
    &::before {
      border-bottom-color: ruf-theming.ruf-map-get($dropdown-panel-container, border-color, ruf-theming.ruf-color($primary));
    }

    &.cdk-keyboard-focused::before {
      border-bottom-color: ruf-theming.ruf-map-get($dropdown-panel-container-focus, border-color, ruf-theming.ruf-color($accent));
    }

  }

  .ruf-panel-center-arrow-down::after, .ruf-panel-left-arrow-down::after, .ruf-panel-right-arrow-down::after {
    border-top-color: $ruf-dropdown-panel-background-color;
  }
  .ruf-panel-center-arrow-down, .ruf-panel-left-arrow-down, .ruf-panel-right-arrow-down {
    &::before {
      border-top-color: ruf-theming.ruf-map-get($dropdown-panel-container, border-color, ruf-theming.ruf-color($primary));
    }
    &.cdk-keyboard-focused::before {
      border-top-color: ruf-theming.ruf-map-get($dropdown-panel-container-focus, border-color, ruf-theming.ruf-color($accent));
    }
  }

  .ruf-dropdown-panel-container {
    &:not(.ruf-dropdown-panel-rtl) {
      @include ruf-dropdown-panel-theme-bidiretionality($config: $dropdown-panel-container, $theme: $theme);
    }

    &.ruf-dropdown-panel-rtl {
      @include ruf-dropdown-panel-theme-bidiretionality('right', 'left', $dropdown-panel-container, $theme: $theme);
    }
  }
}

@mixin ruf-dropdown-panel-typography($config) {
  .ruf-dropdown-panel-container {
    font-family: ruf-theming.ruf-font-family($config);
    font-size: ruf-theming.ruf-font-size($config, subheading-1);
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-dropdown-panel-override($config) {
  @if ($config) {
    $dropdown-panel-container: ruf-theming.ruf-map-get($config, dropdown-panel-container, ());
    .ruf-dropdown-panel-container.fis-override {
       border-radius: ruf-theming.ruf-map-get($dropdown-panel-container, border-radius, ruf-theming.ruf-to-rem(0px));
       border-width: ruf-theming.ruf-map-get($dropdown-panel-container, border-width, ruf-theming.ruf-to-rem(1px));
    }
  }
}
