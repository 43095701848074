@use 'sass:map';
@use '@ruf/theming' as ruf-theming;
@mixin ruf-scrollbar-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  .ruf-scrollbar {

      &::-webkit-scrollbar-track {
        background-color: ruf-theming.ruf-color($background, scrollbar-track);
      }

      ::-webkit-scrollbar-track {
        background-color: ruf-theming.ruf-color($background, scrollbar-track);
      }

      &::-webkit-scrollbar-thumb {
        background-color: ruf-theming.ruf-color($background, scrollbar-thumb);
      }

      ::-webkit-scrollbar-thumb {
        background-color: ruf-theming.ruf-color($background, scrollbar-thumb);
      }

      *, & {
        scrollbar-color: ruf-theming.ruf-color($background, scrollbar-thumb) ruf-theming.ruf-color($background, scrollbar-track);
      }

  }
}
