@use '@ruf/theming' as ruf-theming;

$ruf-expansion-panel-body-padding: ruf-theming.ruf-to-rem(20px);
$ruf-expansion-indicator-margin: ruf-theming.ruf-to-rem(10px);
$expansion-panel-icon-font-family: 'fisfont';
$expansion-panel-icon-font-size: ruf-theming.ruf-to-rem(20px);

// Define a mixin that outputs the structural styles for the component.
@mixin ruf-mat-expansion-panel-set-bidi($dir1: 'left', $dir2: 'right') {
  .mat-expansion-panel-header {
    .mat-content {
      margin-#{$dir1}: $ruf-expansion-indicator-margin;
    }
    .mat-expansion-indicator {
      &::after {
        padding-#{$dir1}: ruf-theming.ruf-to-rem(5px);
        padding-#{$dir2}: ruf-theming.ruf-to-rem(5px);
      }
    }
    &.mat-expanded .mat-expansion-indicator {
      &::after {
        padding-#{$dir1}: ruf-theming.ruf-to-rem(5px);
        padding-#{$dir2}: ruf-theming.ruf-to-rem(5px);
      }
    }
  }
}

@mixin ruf-mat-expansion-panel($cls: '[fisStyle]', $config: ()) {
  .mat-accordion#{$cls} {
    .mat-expansion-panel {
      &:last-of-type {
        border-bottom-left-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
        border-bottom-right-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
      }
      &:first-of-type {
        border-top-left-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
        border-top-right-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
      }
    }
  }

  .mat-expansion-panel#{$cls} {
    $action-row : ruf-theming.ruf-map-get($config, action-row, ());
    border-radius: ruf-theming.ruf-map-get($config, border-radius, ruf-theming.ruf-to-rem(4px));
    outline-style: ruf-theming.ruf-map-get($config, outline-style, null);
    outline-width: ruf-theming.ruf-map-get($config, outline-width, ruf-theming.ruf-to-rem(0px));

    &:not([class*=mat-elevation-z]) {
        box-shadow: ruf-theming.ruf-map-get($config, box-shadow, null);
    }

    .mat-expansion-panel-header {
      // As per FIS Style guide, padding of 10px is needed here.
      // But that makes the header look too tall.
      // The height cannot be adjusted here since it is manipulated in component animations.

      border-style: ruf-theming.ruf-map-get($config, border-style, solid);
      border-width: ruf-theming.ruf-map-get($config, border-width, ruf-theming.ruf-to-rem(1px));

      .mat-content {
        order: 1;
      }
      .mat-expansion-indicator {
        font-family: $expansion-panel-icon-font-family;
        font-size: $expansion-panel-icon-font-size;
        margin: ruf-theming.ruf-to-rem(-6px);
        margin-top: ruf-theming.ruf-to-rem(-5px);

        &::after {
          border: none;
          content: '\e910'; // content value for fisfont=chevron icon
          transform: none;
        }
      }

      &.mat-expanded {
        height: ruf-theming.ruf-to-rem(48px);

        .mat-expansion-indicator {
          margin-top: ruf-theming.ruf-to-rem(-6px);
        }
      }

      .mat-expansion-panel-header-title {
          font: ruf-theming.ruf-map-get($config, font, null);
      }
      @if map-has-key($config, outline-width) {
        .mat-expansion-panel-header-description {
            margin: auto;
        }
      }
    }
    .mat-expansion-panel-body {
      padding: $ruf-expansion-panel-body-padding;
    }
    .mat-action-row {
      border-top-style: ruf-theming.ruf-map-get($action-row, border-top-style, null);
      border-top-width: ruf-theming.ruf-map-get($action-row, border-top-width, null);
    }
    @include ruf-mat-expansion-panel-set-bidi();
    &.ruf-amorphous-expansion-panel {
      @include ruf-amorphous-expansion-panel-structural($config);
    }
  }

  .mat-accordion.ruf-amorphous-expansion-panels {
    .mat-expansion-panel#{$cls} {
      @include ruf-amorphous-expansion-panel-structural($config);
    }
  }

  [dir='rtl'] {
    .mat-expansion-panel#{$cls} {
      @include ruf-mat-expansion-panel-set-bidi('right', 'left');
    }
  }
}

@mixin ruf-amorphous-expansion-panel-structural($config: ()) {
  outline-style: none;
}
