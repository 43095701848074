@use 'sass:map';
@use 'sass:string';
@use '../core.variables';


// Utility for fetching a nested value from a typography config.
@function _ruf-get-type-value($config, $level: null, $name: null) {
  $font-property: map.get($config, $name);
  @if($level != null) {
    $font-property: map.get(map.get($config, $level), $name);
  }

  // font-family
  @if($name == 'font-family' and $font-property != null) {
    $font-property: string.unquote($font-property);
  }
  @return $font-property;
}

// Gets the font size for a level inside a typography config.
@function ruf-font-size($config, $level) {
    @return _ruf-get-type-value($config, $level, font-size);
}

// Gets the line height for a level inside a typography config.
@function ruf-line-height($config, $level) {
    @return _ruf-get-type-value($config, $level, line-height);
}

// Gets the font weight for a level inside a typography config.
@function ruf-font-weight($config, $level) {
    @return _ruf-get-type-value($config, $level, font-weight);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ruf-font-family($config, $level: null) {
    @return _ruf-get-type-value($config, $level, font-family);
}

// Converts a typography level into CSS styles.
@mixin ruf-typography-level-to-styles($config, $level) {
  $font-size: ruf-font-size($config, $level);
  $font-weight: ruf-font-weight($config, $level);
  $line-height: ruf-line-height($config, $level);
  $font-family: ruf-font-family($config, $level);

  // If any of the values are set to `inherit`, we can't use the shorthand
  // so we fall back to passing in the individual properties.
  @if ($font-size == inherit or $font-weight == inherit or $line-height == inherit or $font-family == inherit or
  $font-size == null or $font-weight == null or $line-height == null or $font-family == null) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
  } @else {
    // Otherwise use the shorthand `font` to represent a typography level, because it's the the
    // least amount of bytes. Note that we need to use interpolation for `font-size/line-height`
    // in order to prevent SASS from dividing the two values.
    font: $font-weight #{$font-size}/#{$line-height} $font-family;
  }
}

@function ruf-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null) {

  @return (
    font-family: if($font-family != null, string.unquote($font-family), $font-family),
    font-size: $font-size,
    font-weight: $font-weight,
    line-height: $line-height

  );
}

// create classes for typography levels just like material classes for mat-caption, mat-display etc.
@mixin ruf-typography-classes($config) {
  .ruf-caption-2 {
    @include ruf-typography-level-to-styles($config, caption-2);
  }

  .ruf-bold-text {
    font-weight: bold !important;
  }

  .ruf-uppercase-text {
    text-transform: uppercase !important;
  }

  .ruf-italics-text {
    font-style: italic !important;
  }
}

//Provide typography config for the ruf-theming
@function ruf-typography-config( $config : null) {

  $ruf-default-typography-config : (
    font-family:        string.unquote('Roboto, Arial, sans-serif'),
    display-4:          ruf-typography-level(112px, 112px, 300),
    display-3:          ruf-typography-level(56px, 56px, 400),
    display-2:          ruf-typography-level(45px, 48px, 400),
    display-1:          ruf-typography-level(34px, 40px, 400),
    headline:           ruf-typography-level(24px, 32px, 400),
    title:              ruf-typography-level(20px, 32px, 500),
    subheading-2:       ruf-typography-level(16px, 28px, 400),
    subheading-1:       ruf-typography-level(14px, 24px, 400),
    body-2:             ruf-typography-level(14px, 24px, 500),
    body-1:             ruf-typography-level(14px, 20px, 400),
    caption:            ruf-typography-level(12px, 20px, 400),

    button:             ruf-typography-level(14px, 34px, 500),
    input:              ruf-typography-level(16px, 1.125, 400), // Material specific, Line-height must be unit-less.
    caption-2:          ruf-typography-level( 16px, 18px, 500),
    icon:               ruf-typography-level(20px), // @deprecated
    bold:               ruf-typography-level($font-size: null, $font-weight: 700),
    normal:      ruf-typography-level($font-size: null, $font-weight: 500),
    normal-weight:      ruf-typography-level($font-size: null, $font-weight: 500), // to be deprecated

    // Ruf specific
    dialog-title:       ruf-typography-level( 18px, 1.125, 400),
    expansion-panel :   ruf-typography-level(15px, 20px, 700),
    tab-label :         ruf-typography-level($font-size: 0.875rem),
    banner-brand-title: ruf-typography-level( core.ruf-to-rem(core.$ruf-vunit * 23px)),
    banner-brand-title-2: ruf-typography-level(core.ruf-to-rem(core.$ruf-vunit * 16px), 1.19, bold, 'RisingSun, Roboto, Arial, sans-serif'),
    ellipsis :          ruf-typography-level(inherit, 16px),
    sitemap-title: ruf-typography-level($font-size: 18px, $font-weight: bold),
    dynamic-menubar: ruf-typography-level($font-size: null, $line-height: 44px, $font-weight: 500),
    menu-item : ruf-typography-level(16px, 1, 400),
    menu-children : ruf-typography-level(14px, 1, 400),
    //Ruf Ngx Charts Specific
    chart: ruf-typography-level(11px, 32px, 500),
    chart-label: ruf-typography-level($font-size: 14px, $font-weight: bold)
  );


  //merge the base and custom typography configuration
  $complete-config:  if( $config == null, $ruf-default-typography-config, map.merge( $ruf-default-typography-config, $config ) );
  //extract font-family from the config
  $font-family: map.get($complete-config, font-family);

  $typography-config: map.remove($complete-config, font-family);

  // Add font family to each level if font family is not define for that level.
  @each $key, $level in $typography-config {
   @if map.get($level, font-family) == null {
     $new-level: map.merge($level, (font-family: $font-family));
     $typography-config: map.merge($typography-config, ($key: $new-level));
    }
  }

  // Add the base font family to the config.
  @return map.merge($typography-config, (font-family: $font-family));
}
