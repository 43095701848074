@use 'sass:map';
@use '@ruf/theming' as ruf-theming;
// stylelint-disable block-no-empty
@mixin ruf-navbar-theme($theme, $config: ()) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  // ruf-navbar can be used in ruf-banner, ruf-toolbar, ruf-page-header
  // Its background & text color should be dependent on parent component
  $ruf-navbar-background: inherit !default;
  $ruf-navbar-color: inherit !default;

  .ruf-navbar {
    background: $ruf-navbar-background;
    color: $ruf-navbar-color;
  }
}

@mixin ruf-navbar-typography($config) {

}


/* Structural config override*/
@mixin ruf-navbar-override($config) {
  .ruf-navbar.fis-override {
  }
}
