@use 'sass:map';
@use '@ruf/theming' as ruf-theming;

@mixin ruf-ellipsis-theme($theme, $config: ()) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .ruf-ellipsis {
    background-color: inherit;
    div, span {
      background-color: inherit;
    }
  }
}


@mixin ruf-ellipsis-typography($config) {
  .ruf-ellipsis {
    font-family: ruf-theming.ruf-font-family($config);
    line-height: ruf-theming.ruf-line-height($config, 'ellipsis');
  }
}

/* Structural config override*/
// stylelint-disable block-no-empty
@mixin ruf-ellipsis-override($config) {
  @if ($config) {
    .ruf-ellipsis.fis-override {
    }
  }
}
